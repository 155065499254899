<template>
  <div class="c-MessageTranslationTextSection">
    <Card title="Translation Text">
      <div
        slot="header-actions"
        class="c-MessageTranslationTextSection__cardActions">
        <Button
          v-if="isArch1"
          button-type="is-outlined"
          data-test-id="msg-core-link"
          :disabled="isMTinProgress"
          icon="external-hyperlink"
          label="view in core"
          @click="linkToTpAdmin">
        </Button>
        <Button
          v-else
          button-type="is-outlined"
          data-test-id="msg-nuggets-btn"
          :disabled="nuggetsLoading || isMTinProgress"
          :label="nuggetsBtnLabel"
          @click="toggleNuggetsInfo">
        </Button>
      </div>
      <div
        slot="body"
        class="c-MessageTranslationTextSection__cardBody">
        <div class="c-MessageTranslationTextSection__translation">
          <div class="c-MessageTranslationTextSection__languageHeader">
            <div class="c-MessageTranslationTextSection__language">
              <img
                data-test-id="msg-source-lang-flag"
                :src="`/flags-light/${messageDetails.translation_profile && messageDetails.translation_profile.source_language}.svg`">
              <p>
                <span data-test-id="msg-source-lang-label">{{ sourceLanguage.label || sourceLanguage }}</span>
                <span v-if="author">{{ author }}</span>
              </p>
            </div>
            <span
              class="c-MessageTranslationTextSection__numWords"
              data-test-id="msg-source-num-words">{{ nuggetsData.source_number_of_words || '0' }} words</span>
          </div>
          <p
            v-if="messageDetails.original_content_details && messageDetails.original_content_details.text"
            class="c-MessageTranslationTextSection__text"
            data-test-id="msg-source-text">{{ messageDetails.original_content_details.text_stripped || messageDetails.original_content_details.text || '---' }}</p>
          <button
            id="download-source"
            data-test-id="msg-source-download"
            @click="downloadText('source')">
            <Icon
              class="download-icon"
              icon="download-thick-bottom"
              icon-style="is-dark-gray" />
            <Tooltip
              anchorName="download-source"
              message="Download text"
              position="bottom"></Tooltip>
          </button>
        </div>
        <div class="c-MessageTranslationTextSection__translation">
          <div class="c-MessageTranslationTextSection__languageHeader">
            <div class="c-MessageTranslationTextSection__language">
              <img
                data-test-id="msg-target-lang-flag"
                :src="`/flags-light/${messageDetails.translation_profile && messageDetails.translation_profile.target_language}.svg`">
              <p>
                <span data-test-id="msg-target-lang-label">{{ targetLanguage.label || targetLanguage }}</span>
                <span v-if="recipient">{{ recipient }}</span>
              </p>
            </div>
            <span
              class="c-MessageTranslationTextSection__numWords"
              data-test-id="msg-target-num-words">{{ nuggetsData.target_number_of_words || '0' }} words</span>
          </div>
          <p
            v-if="messageDetails.delivery_details && messageDetails.delivery_details.text"
            class="c-MessageTranslationTextSection__text"
            data-test-id="msg-target-text">{{ messageDetails.delivery_details.text_stripped || messageDetails.delivery_details.text }}</p>
          <p
            v-if="specialTicketsConditions"
            class="special">Note: This translation is in Xliff format, the original text will be replicated in the translation. This is expected behaviour, the client will only see the translated text.</p>
          <p
            v-if="!messageDetails.delivery_details || !messageDetails.delivery_details.text"
            class="special">Go to “View nuggets” for more details, until the target translation appears here…</p>
          <button
            v-if="messageDetails.delivery_details && messageDetails.delivery_details.text"
            id="download-target"
            data-test-id="msg-target-download"
            @click="downloadText('target')">
            <Icon
              class="download-icon"
              icon="download-thick-bottom"
              icon-style="is-dark-gray" />
            <Tooltip
              anchorName="download-target"
              message="Download text"
              position="bottom"></Tooltip>
          </button>
        </div>
      </div>
    </Card>
    <Modal
      :is-visible="nuggetsVisibility"
      title="Nuggets"
      @toggleVisibility="toggleNuggetsInfo">
      <NuggetsLayout
        :data="nuggetsData"
        :messageUid="messageDetails && messageDetails.uid"
        :engines="usedMTs" />
      <template v-slot:legend>
        <NuggetsLegend />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  GLOBAL_GETTERS,
  MIXPANEL_EVENTS,
  ARCH_STATUS,
  OVERALL_STATUS
} from '@/data/enum'
import {
  trackEvent,
  generateLinkToCore
} from '@/utils/generalUtils'
import { languageOptions } from '@/data/languages'
import { Card, Button, Icon, Tooltip } from '@unbabel/ui'
import Modal from '@/components/Modal'
import NuggetsLegend from '@/components/NuggetsLegend'
import NuggetsLayout from '@/components/NuggetsLayout'

export default {
  name: 'MessageTranslationTextSection',
  components: {
    Card,
    Button,
    Icon,
    Tooltip,
    Modal,
    NuggetsLayout,
    NuggetsLegend
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      nuggetsVisibility: false
    }
  },
  computed: {
    ...mapGetters({
      nuggetsLoading: GLOBAL_GETTERS.NUGGETS_LOADING
    }),
    usedMTs () {
      return this.messageDetails?.machine_translation_details?.map(detail => detail?.job_engine)
    },
    specialTicketsConditions () {
      return this.messageDetails?.translation_profile?.text_format === 'xliff' &&
      this.messageDetails?.delivery_details?.text
    },
    isMTinProgress () {
      return this.messageDetails?.status === OVERALL_STATUS.MT_START_STATUS
    },
    nuggetsBtnLabel () {
      return this.isMTinProgress ? 'mt in progress' : this.nuggetsLoading ? 'nuggets loading' : 'nuggets'
    },
    isArch1 () {
      return this.messageDetails?.translation_profile?.architecture === ARCH_STATUS.CORE || this.messageDetails?.translation_profile?.architecture === ARCH_STATUS.CORE_FALLBACK
    },
    glossaryId () {
      // this ID is what links the Translation Profile to the glossary termbase (all glossary terms on nuggets)
      return this.messageDetails?.translation_profile?.glossary_id || this.messageDetails?.translation_profile?.id || 'MISSING_GLOSSARY_ID'
    },
    sourceLanguage () {
      return this.fetchLangObject('source_language')
    },
    targetLanguage () {
      return this.fetchLangObject('target_language')
    },
    author () {
      if (this.messageDetails?.translation_direction === 'inbound') {
        return 'by customer'
      } else if (this.messageDetails?.translation_direction === 'outbound') {
        return `by ${this.messageDetails?.agent?.author_external_id || 'agent'}`
      }

      return 'author'
    },
    recipient () {
      if (this.messageDetails?.translation_direction === 'outbound') {
        return 'to customer'
      } else if (this.messageDetails?.translation_direction === 'inbound') {
        return `to ${this.messageDetails?.agent?.author_external_id || 'agent'}`
      }

      return 'recipient'
    },
    nuggetsData () {
      return this.messageDetails.parsed_nuggets || {}
    }
  },
  methods: {
    downloadText (type) {
      let text
      if (type === 'source') {
        text = this.messageDetails.original_content_details.text
      } else if (type === 'target') {
        text = this.messageDetails.delivery_details.text
      } else {
        return console.error('source or target not found for download text in MessageTranslationTextSection')
      }

      const blob = new Blob([text], {
        type: 'text/plain'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${type}.txt`
      a.click()

      trackEvent(MIXPANEL_EVENTS.DOWNLOAD_RAW_TEXT, {
        type,
        area: this.$route.name
      })
    },
    linkToTpAdmin () {
      const area = this.$route.name
      trackEvent(MIXPANEL_EVENTS.DETAILS_VIEW_CORE, {
        area
      })

      window.open(`${generateLinkToCore(this.messageDetails?.uid, this.messageDetails?.translation_profile?.is_human_flow)}`, '_blank', 'noopener,noreferrer')
    },
    toggleNuggetsInfo () {
      this.nuggetsVisibility = !this.nuggetsVisibility

      const area = this.$route.name
      trackEvent(MIXPANEL_EVENTS.DETAILS_VIEW_NUGGETS, {
        area
      })
    },
    fetchLangObject (key) {
      if (this.messageDetails.translation_profile && this.messageDetails.translation_profile[`${key}`]) {
        return languageOptions[`${this.messageDetails.translation_profile[`${key}`]}`] || this.messageDetails.translation_profile[`${key}`]
      }
      return '---'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-MessageTranslationTextSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__translation {
    width: 50%;
    padding: calc(#{$base-padding} / 2) 0 calc(#{$base-padding} * 2) 0;
    position: relative;

    &:first-child {
      padding-right: calc(#{$base-padding} * 1.5);
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -16px;
        right: 0;
        width: 1px;
        height: calc(100% + 16px + 16px);
        background-color: $un-n200;
      }

      // button {
      //   right: calc(#{$base-padding} * 1.5);
      // }
    }

    &:last-child {
      padding-left: calc(#{$base-padding} * 1.5);
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
      border: none;
      margin: $base-margin 0 0 0;
      padding: 0;
      background-color: transparent;
      float: right;
      cursor: pointer;
    }
  }

  &__languageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }

  &__language {
    display: flex;

    img {
      height: 40px;
      object-fit: contain;
      margin-right: $base-margin;
    }

    p span {
      &:first-child {
        font-family: $secondary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $un-n900;
      }
      &:last-child {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $un-n700;
        display: block;
      }
    }
  }

  &__numWords {
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n700;
  }

  &__text {
    font-family: $primary-font;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: $un-n900;
    overflow-wrap: break-word;
  }

  .special {
    margin-top: calc(#{$base-margin} * 2);
    font-family: $primary-font;
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $un-n700;
  }
}
</style>
