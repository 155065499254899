<template>
  <div
    v-if="item"
    class="c-DiagramItem__container">
    <span class="c-DiagramItem__label">{{ item.label }}
      <div
        :id="randomElemId"
        class="c-DiagramItem__changelogCopyLink"
        @click="copyLink(item.link)">
        <Icon
          class="icon"
          icon="share"
          icon-style="is-primary" />
        <Tooltip
          :message="tooltipText"
          :anchorName="randomElemId" />
      </div>
    </span>
    <div class="c-DiagramItem__content">
      <ul class="c-DiagramItem__notes">
        <li
          v-for="(note, index) in item.notes"
          :key="index">
          <span>{{ note }}</span>
        </li>
      </ul>
      <ul
        v-if="item.media"
        class="c-DiagramItem__media">
        <li
          v-for="(mediaItem, index) in item.media"
          :key="index">
          <div v-if="mediaItem.type === 'loomVideo'">
            <iframe
              width="490"
              height="320"
              :src="`https://www.loom.com/embed/${mediaItem.code}`"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen></iframe>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Icon, Tooltip } from '@unbabel/ui'
import {
  copyToClipboard
} from '@/utils/generalUtils'

export default {
  name: 'DiagramItem',
  components: {
    Icon,
    Tooltip
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      isLinkCopied: false,
      randomElemId: `changeloglink${Math.floor(Math.random() * 100000000000000000000)}`
    }
  },
  computed: {
    tooltipText (i) {
      return this.isLinkCopied ? 'Link copied!' : 'Copy link to version notes'
    }
  },
  methods: {
    copyLink (link) {
      this.isLinkCopied = true
      copyToClipboard(`${process.env.VUE_APP_E2E_APPLICATION_BASE_URL}/changelog#${link}`)

      setTimeout(() => {
        this.isLinkCopied = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-DiagramItem {
  &__container {
    margin-top: -24px;

    &:hover .c-DiagramItem__changelogCopyLink .icon {
      display: block;
    }
  }

  &__label {
    font-size: 14px;
    font-family: $primary-font;
    letter-spacing: 0.25px;
    line-height: 21px;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
    line-height: 24px;

  }

  &__changelogCopyLink {
    margin-left: $base-margin;
    cursor: pointer;
    width: 24px;
    height: 24px;

    .icon {
      display: none;
    }
  }

  &__content {
    margin: $base-margin 0 0 $base-margin;

  }

  &__notes {
    margin: 0;
    padding: 0;
    list-style: disc;

    li {
      margin: 0 0 calc(#{$base-margin} / 2) 0;
      padding: 0;

      span {
        font-size: 14px;
        font-family: $primary-font;
        letter-spacing: 0.25px;
        line-height: 21px;
        color: $un-n900;
      }
    }
  }

  &__media {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
</style>
