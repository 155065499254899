import Search from '@/views/Search.vue'
import Changelog from '@/views/Changelog.vue'
import MessageDetails from '@/views/MessageDetails.vue'
import TaskDetails from '@/views/TaskDetails.vue'
import TranslationRecordDetails from '@/views/TranslationRecordDetails.vue'

export default [
  {
    path: '/',
    name: 'Search',
    component: Search,
    meta: {
      bc__routeParsing: true,
      bc__visibleRouteParams: null,
      bc__label: {
        default: 'Search'
      }
    }
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog
  },
  {
    path: '/conversation/:conversationId/message/:messageId',
    name: 'MessageDetails',
    component: MessageDetails,
    meta: {
      bc__routeParsing: true,
      bc__visibleRouteParams: 'messageId',
      bc__label: {
        faqs: 'Translation',
        faqs_review: 'Translation',
        default: 'Message'
      }
    }
  },
  {
    path: '/conversation/:conversationId/message/:messageId/task/:taskId',
    name: 'TaskDetails',
    component: TaskDetails,
    meta: {
      bc__routeParsing: true,
      bc__visibleRouteParams: 'taskId',
      bc__label: {
        faqs_review: 'Review',
        default: 'Task'
      }
    }
  },
  {
    path: '/conversation/:conversationId/message/:messageId/task/:taskId/record/:translationRecordId',
    name: 'TranslationRecordDetails',
    component: TranslationRecordDetails,
    meta: {
      bc__routeParsing: true,
      bc__visibleRouteParams: 'translationRecordId',
      bc__label: {
        default: 'Translation Record'
      }
    }
  }
]
