<template>
  <div
    v-if="messageDetails"
    class="c-TitleSection">
    <div class="c-TitleSection__leftBlock">
      <div class="c-TitleSection__title">
        <div>
          <h5>
            <span data-test-id="msg-title">{{ title }}</span>
            <Popover
              v-if="area === 'messageDetails'"
              :data="popoverData.title"
              class="c-TitleSection__leftPopover"
              h-position="left"
              data-test-id="title-popover" />
            <StatusChip
              v-else-if="area === 'taskDetails'"
              data-test-id="msg-task-status"
              class="c-TitleSection__status"
              :status="taskDetails.task_status" />
            <StatusChip
              v-else-if="area === 'translationRecordDetails'"
              data-test-id="msg-tr-status"
              class="c-TitleSection__status"
              :status="trDetails.action" />
          </h5>
          <ul>
            <li
              v-if="authorConditions"
              id="msg-author"
              data-test-id="msg-author"
              class="text--ellipse">by {{ authorData }}
              <Tooltip
                v-if="messageDetails.agent.author_external_id.length > 13"
                :message="messageDetails.agent.author_external_id"
                anchorName="msg-author"></Tooltip>
            </li>
            <li
              v-if="originConditions"
              data-test-id="msg-origin"
              class="text--ellipse">from {{ messageDetails.translation_profile.origin }}</li>
            <li
              v-if="usecaseLabel"
              data-test-id="msg-usecase"
              class="text--ellipse">{{ usecaseLabel }}</li>
            <li
              v-if="isProject"
              id="msg-project"
              data-test-id="msg-project"
              class="text--ellipse">
                Project:
                <a
                  :href="projectLink"
                  target="_blank"
                  rel="noopener noreferrer">
                  {{ projectName }}
                  <Tooltip :message="projectName" anchorName="msg-project" />
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-TitleSection__rightBlock">
      <div v-if="area === 'messageDetails'">
        <template v-if="isProject">
          <IdCopyBlock
            :id="messageDetails.project_id"
            icon="projectId"
            label="Project ID"
            :is-new-version="true"
            data-test-id="msg-project-uid" />
          <IdCopyBlock
            :id="messageDetails.order_id"
            icon="projectId"
            label="Order ID"
            :is-new-version="true"
            data-test-id="msg-order-uid" />
          <IdCopyBlock
            :id="messageDetails.job_id"
            icon="projectId"
            label="Job ID"
            :is-new-version="true"
            data-test-id="msg-job-id" />
        </template>

        <template v-else>
          <IdCopyBlock
            :id="messageDetails.uid"
            icon="uid"
            label="Message UID"
            :is-new-version="true"
            data-test-id="msg-uid" />
          <IdCopyBlock
            :id="messageDetails.thread_id"
            icon="chatId"
            label="Thread ID"
            :is-new-version="true"
            data-test-id="msg-thread-id" />
        </template>
      </div>
      <div v-else-if="area === 'taskDetails'">
        <IdCopyBlock
          :id="taskDetails.id"
          icon="uid"
          label="Task ID"
          data-test-id="msg-task-id"></IdCopyBlock>
      </div>
      <div v-else-if="area === 'translationRecordDetails'">
        <IdCopyBlock
          :id="trDetails.id"
          icon="uid"
          label="Translation Record ID"
          data-test-id="msg-tr-id"></IdCopyBlock>
      </div>
      <StatusChip
        v-if="fileInformation"
        class="c-TitleSection__status-label"
        :status="fileInformation.status"
        :status-config="{
          labelStyle: 'is-info'
        }"
      />
      <Popover
        v-if="area === 'messageDetails'"
        :data="popoverData.ids"
        :app-smith-link="getFileProcessingInfo"
        data-test-id="ids-popover" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IdCopyBlock from '@/components/IdCopyBlock.vue'
import Popover from '@/components/Popover.vue'
import StatusChip from '@/components/StatusChip'
import { Tooltip } from '@unbabel/ui'
import {
  FILTERS_DICT, CONTENT_TYPE, TASK_TYPE,
  GLOBAL_GETTERS, FILE_PROCESSING_LABELS, ELEMENT_TYPE
} from '@/data/enum'
import { generateCPLinks } from '@/utils/generalUtils'

export default {
  name: 'TitleSection',
  components: {
    IdCopyBlock,
    Popover,
    StatusChip,
    Tooltip
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    },
    fileInformation: {
      type: Object,
      default: () => {}
    },
    taskDetails: {
      type: Object,
      required: false,
      default: () => ({})
    },
    trDetails: {
      type: Object,
      required: false,
      default: () => ({})
    },
    area: {
      type: String,
      required: true,
      validator (area) {
        return [
          'messageDetails',
          'taskDetails',
          'translationRecordDetails'
        ].includes(area)
      }
    }
  },
  data () {
    return {
      popoverVisibility: {
        title: false,
        ids: false
      }
    }
  },
  computed: {
    ...mapGetters({
      usecaseSelectItems: GLOBAL_GETTERS.USECASE_SELECT_ITEMS
    }),
    isProject () {
      return !!this.messageDetails.project_id
    },
    projectName () {
      return this.messageDetails.project_name ?? '---'
    },
    projectLink () {
      return generateCPLinks(this.messageDetails, 'project')
    },
    title () {
      if (!this.messageDetails) return 'Loading...'

      // eslint-disable-next-line camelcase
      const profile = this.messageDetails.translation_profile
      const direction = this.messageDetails.translation_direction

      const contentType = profile?.content_type === CONTENT_TYPE.FAQ ? 'FAQ' : profile?.content_type
      const isFaq = profile?.content_type === CONTENT_TYPE.FAQ

      if (this.area === 'messageDetails') {
        let msg
        isFaq ? msg = 'Translation' : msg = 'Message'

        if (profile?.content_type) {
          msg = `${contentType} ${msg}`
        }
        if (!isFaq && direction && direction !== 'unknown') {
          msg = `${direction} ${msg}`
        }
        return msg
      } else if (this.area === 'taskDetails') {
        const isSeniorReview = this.taskDetails && this.taskDetails.type === TASK_TYPE.REVIEW

        return isSeniorReview ? `${contentType} Review` : `${contentType} Task`
      } else if (this.area === 'translationRecordDetails') {
        return `${contentType} Translation Record`
      }

      return 'Unidentified Message'
    },
    authorConditions () {
      return this.messageDetails?.agent?.author_external_id && this.area === 'messageDetails'
    },
    authorData () {
      if (this.authorConditions) {
        return this.messageDetails?.agent?.author_external_id.length > 13 ? `${this.messageDetails?.agent?.author_external_id.substring(0, 13)}…` : this.messageDetails?.agent?.author_external_id
      }

      return ''
    },
    originConditions () {
      return this.messageDetails?.translation_profile?.origin
    },
    usecaseLabel () {
      if (this.messageDetails?.translation_profile?.usecase) {
        const usecase = this.usecaseSelectItems.find(item => item.value === this.messageDetails?.translation_profile?.usecase)
        return (usecase && usecase.label) || this.messageDetails?.translation_profile?.usecase
      }

      return false
    },
    popoverData () {
      const out = {
        title: [
          {
            label: 'Content-type',
            value: this.messageDetails?.translation_profile?.content_type || '---'
          },
          {
            label: 'Direction',
            value: this.messageDetails?.translation_direction || '---'
          },
          {
            label: 'Author ID',
            value: this.messageDetails?.agent?.author_external_id || '---',
            type: 'author_id',
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Agent ID',
            value: this.messageDetails?.agent?.agent_external_id || '---',
            type: 'agent_id',
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Origin',
            value: this.messageDetails?.translation_profile?.origin || '---'
          },
          {
            label: 'Use case',
            value: this.usecaseLabel || '---'
          },
          {
            label: 'Document type',
            value: this.messageDetails?.translation_profile?.text_format || '---'
          }
        ],
        ids: [
          {
            label: 'Message UID',
            value: this.messageDetails?.uid || '---',
            type: FILTERS_DICT.UID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Thread ID',
            value: this.messageDetails?.thread_id || '---',
            type: FILTERS_DICT.THREAD_ID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Ext. Message ID',
            value: this.messageDetails?.external_id || '---',
            type: FILTERS_DICT.EXTERNAL_ID,
            canCopy: true,
            isIdCopied: false
          },
          {
            label: 'Ext. Thread ID',
            value: this.messageDetails?.thread_external_id || '---',
            type: FILTERS_DICT.THREAD_EXTERNAL_ID,
            canCopy: true,
            isIdCopied: false
          }
        ]
      }

      if (this.fileInformation) {
        out.ids.push(
          {
            label: '',
            value: ELEMENT_TYPE.SEPARATOR
          },
          {
            label: FILE_PROCESSING_LABELS.STATUS,
            value: this.fileInformation?.status || '---',
            type: FILTERS_DICT.UID
          },
          {
            label: FILE_PROCESSING_LABELS.JOB_ID,
            value: this.fileInformation?.file_processing_job_id || '---',
            type: FILTERS_DICT.UID,
            canCopy: true
          },
          {
            label: FILE_PROCESSING_LABELS.CONFIG_ID,
            value: this.fileInformation?.file_processing_config_id || '---',
            type: FILTERS_DICT.UID,
            canCopy: true
          })
      }

      if (this.isProject) {
        out.title.push({
          label: 'Project',
          value: this.messageDetails.project_id
        })

        out.ids = [{
          label: 'Project ID',
          value: this.messageDetails?.project_id || '---',
          type: FILTERS_DICT.THREAD_EXTERNAL_ID,
          canCopy: true,
          isIdCopied: false
        },
        {
          label: 'Order ID',
          value: this.messageDetails?.order_id || '---',
          type: FILTERS_DICT.THREAD_EXTERNAL_ID,
          canCopy: true,
          isIdCopied: false
        },
        {
          label: 'Job ID',
          value: this.messageDetails?.job_id || '---',
          type: FILTERS_DICT.THREAD_EXTERNAL_ID,
          canCopy: true,
          isIdCopied: false
        },
        {
          label: 'Message UID',
          value: this.messageDetails?.uid || '---',
          type: FILTERS_DICT.UID,
          canCopy: true,
          isIdCopied: false
        },
        {
          label: 'Ext. Message ID',
          value: this.messageDetails?.external_id || '---',
          type: FILTERS_DICT.EXTERNAL_ID,
          canCopy: true,
          isIdCopied: false
        },
        {
          label: 'Ext. Thread ID',
          value: this.messageDetails?.thread_external_id || '---',
          type: FILTERS_DICT.THREAD_EXTERNAL_ID,
          canCopy: true,
          isIdCopied: false
        }
        ]
      }

      return out
    },
    getFileProcessingInfo () {
      return {
        show: !!this.fileInformation?.file_processing_job_id,
        link: `${process.env.VUE_APP_SMITH_LINK}&job_id=${this.fileInformation?.file_processing_job_id}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
$list-style-padding: 18px;

.c-TitleSection {
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  position: relative;

  &__title {
    // this is to align the information underneath the title with the center alignment for the ID's on the right
    margin-bottom: calc(#{$base-margin} / 2);

    h5 {
      display: flex;
      align-items: center;

      .c-TitleSection__status {
        margin-left: calc(#{$base-margin} / 2);
      }

      span {
        font-family: $secondary-font;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: 600;
      }
    }

    ul {
      display: flex;
      margin: 4px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        max-width: 12rem;
        color: $un-n700;

        &:not(:first-child)::before {
          content: '\2022';
          font-size: 24px;
          vertical-align: top;
          margin: 0 calc(#{$base-margin} / 2);
        }

        &:first-child {
          list-style: none;
        }
      }
    }
  }

  &__rightBlock {
    display: flex;
    align-items: center;
    margin-top: 28px;

    & > div:nth-child(1) {
      display: flex;
    }
  }

  &__status-label {
    margin-right: $size--2-half;

    ::v-deep .c-Label {
      span {
        text-transform: none;
        border-radius: $size--2-half;
      }

      &__text {
        max-width: none;
      }
    }
  }
}
</style>
