<template>
  <div
    class="c-FlowPhase"
    :class="FlowPhaseClassObj">
    <div :class="{ clickable: canDownloadStep }" class="c-FlowPhase__container" @click="downloadStepOutput">
      <div
        :id="`phaseDate${randomId}${data.name}`"
        class="c-FlowPhase__textBox">

        <p class="text--body-2">{{ data.parsedName }}</p>
        <p v-if="taskType" class="text--caption"> ({{ taskType }})</p>

        <Tooltip
          :message="tooltipMessage"
          :position="'bottom'"
          :anchorName="`phaseDate${randomId}${data.name}`"/>
      </div>
    </div>
    <i class="c-FlowPhase__arrowIcon"></i>
  </div>
</template>

<script>
import { Tooltip } from '@unbabel/ui'

import { STEP_STATUS, TASK_TYPE_LABEL } from '@/data/enum'
import { randomizeId, downloadFile } from '@/utils/generalUtils'
import { parseSnakeToHuman } from '@/utils/parsingUtils'

export default {
  name: 'FlowPhase',
  components: {
    Tooltip
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    flowphaseAsArrow: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data () {
    return {
      STEP_STATUS,
      randomId: randomizeId()
    }
  },
  computed: {
    canDownloadStep () {
      return !!this.data.outputFile
    },
    FlowPhaseClassObj () {
      return {
        flowphaseAsArrow: this.flowphaseAsArrow,
        notStarted: this.data.status === STEP_STATUS.INIT,
        ongoing: this.data.status === STEP_STATUS.PENDING,
        completed: this.data.status === STEP_STATUS.COMPLETED,
        failed: this.data.status === STEP_STATUS.FAILED,
        canceled: this.data.status === STEP_STATUS.CANCELLED,
        skipped: this.data.status === STEP_STATUS.SKIPPED
      }
    },
    tooltipMessage () {
      const addHtml = (title, content) => {
        return `<p class="text--caption">
                    <span class="text--caption--demi">${title}</span>:
                    ${content}
                </p>`
      }

      let msg = `<p style="text-transform: capitalize" class="text-center text--caption--demi mb-2">${this.data.parsedName}</p>`

      if (this.taskType) {
        msg += addHtml('Task Type', this.taskType)
      }

      if (this.data.info) {
        msg += addHtml('Info', this.data.info)
      }

      if (this.data.utc_parsed_completed_at) {
        msg += addHtml('Completed at', this.data.utc_parsed_completed_at)
      }

      if (this.canDownloadStep) {
        msg += '<p style="font-style: italic" class="text-center my-2">(click to download step output)</p>'
      }

      return msg
    },
    taskType () {
      if (this.data.task_type) {
        return TASK_TYPE_LABEL[this.data.task_type] ?? parseSnakeToHuman(this.data.task_type)
      }
      return null
    }
  },
  methods: {
    downloadStepOutput () {
      if (this.canDownloadStep) {
        downloadFile(`${this.data.parsedName}.xlf`, this.data.outputFile)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
$arrow-width: 40px;
$boxes-padding: 15px;

.c-FlowPhase {
  display: flex;
  align-items: center;

  &__arrowIcon {
    width: 7px;
    height: 13px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.756 6.494c0 .35-.15.7-.4.95l-4.8 4.75c-.35.35-.9.35-1.25 0a.875.875 0 010-1.25l4.35-4.35c.05-.05.05-.15 0-.2l-4.35-4.35a.875.875 0 010-1.25c.35-.35.9-.35 1.25 0l4.75 4.75c.3.25.45.6.45.95z' fill='%23DEE3ED'/%3E%3C/svg%3E");
  }

  &:first-child {
    padding-left: 0;

    .c-FlowPhase__container::before {
      display: none;
    }

    .c-FlowPhase__textBox {
      padding-left: 0;
    }
  }

  &:last-child {
    padding-right: 0;

    .c-FlowPhase__container::after,
    .c-FlowPhase__arrowIcon {
      display: none;
    }

    .c-FlowPhase__textBox {
      width: 100%;
    }
  }

  &.completed {
    .c-FlowPhase__textBox {
      background-color: $un-green-lightest;
      border-color: $un-green-dark;
    }

    .c-FlowPhase__container::after {
      border-color: transparent transparent transparent $un-green-lightest;
    }

    .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
      color: $un-green-dark;
    }
  }

  &.ongoing {
    .c-FlowPhase__textBox {
      background-color: $un-blue;
      border-color: $un-blue-dark;
    }

    .c-FlowPhase__container::after {
      border-color: transparent transparent transparent $un-blue;
    }

    .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
      color: $un-white;
    }
  }

  &.notStarted {
    .c-FlowPhase__textBox {
      background-color: $un-white;
      border: 1px solid $un-gray-light;
    }

    .c-FlowPhase__container::after {
      border-color: transparent transparent transparent $un-white;
    }

    .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
      color: $un-n700;
    }
  }

  &.skipped {
    .c-FlowPhase__textBox {
      background-color: $un-n50;
      border-color: $un-n700;
    }

    .c-FlowPhase__container::after {
      border-color: transparent transparent transparent $un-n50;
    }

    .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
      color: $un-n700;
    }
  }

  &.failed,
  &.canceled {
    .c-FlowPhase__textBox {
      background-color: $un-red;
      border-color: $un-red-darker;
    }

    .c-FlowPhase__container::after {
      border-color: transparent transparent transparent $un-red;
    }

    .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
      color: $un-white;
    }
  }

  &.flowphaseAsArrow {
    padding: 0;

    .c-FlowPhase__textBox {
      width: calc(100% - #{$boxes-padding});
      padding: 0 0 0 $arrow-width;
      border-radius: none;
    }

    .c-FlowPhase__container {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 28.5px 0 28.5px $arrow-width;
        border-color: transparent transparent transparent $un-white;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: calc(-#{$arrow-width} + #{$boxes-padding} + 3px);
        z-index: 2;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 28.5px 0 28.5px $arrow-width;
      }
    }

    &:first-child {
      .c-FlowPhase__container::before {
        display: none;
      }

      .c-FlowPhase__textBox {
        padding-left: 0;
      }
    }

    &:last-child {
      .c-FlowPhase__container::after {
        display: none;
      }

      .c-FlowPhase__textBox {
        width: 100%;
      }
    }

    &.notStarted {
      .c-FlowPhase__textBox {
        background-color: $un-n200;
        border: none;
      }

      .c-FlowPhase__container::after {
        border-color: transparent transparent transparent $un-n200;
      }

      .c-FlowPhase__textBox p, .c-FlowPhase__textBox span {
        color: $un-n700;
      }
    }
  }

  &__container {
    height: 67px;
    width: 100%;
    padding: 0 $base-padding;
    position: relative;

    &.clickable {
      &:hover > .c-FlowPhase__textBox {
        cursor: pointer;
        border-width: 2px;
        border-style: solid;
      }
    }
  }

  &__textBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;

    > p {
      padding: 4px;
      line-height: 15px;
      text-transform: capitalize;
      text-align: center;
    }
  }
}
</style>
