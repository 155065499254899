<template>
  <div>
    <Card
      class="c-TranslationRecordListSection"
      :title="`Translation Records (${trCount})`">
      <div
        v-if="isSeniorReview"
        slot="header-actions"
        class="c-TranslationRecordListSection__cardActions">
        <Button
          :label="isEditorUrlCopied ? 'copied!' : 'editor url'"
          button-type="is-outlined"
          :icon="isEditorUrlCopied ? '' : 'copy-duplicate'"
          :disabled="isEditorUrlCopied ? true : false"
          data-test-id="msg-tr-list-editor-url"
          @click="copyUrlToClipboard">
        </Button>
      </div>
      <div
        slot="body"
        class="c-TranslationRecordListSection__cardBody">
        <p
          v-if="translationRecordsLoading"
          class="c-TranslationRecordListSection__emptyState">
          <span>Loading translation records from Tarkin... Please wait</span>
        </p>
        <p
          v-else-if="translationRecordsLoadingError"
          class="c-TranslationRecordListSection__emptyState">
          <span>An error occurred while loading translation records from Tarkin... Please try again</span>
        </p>
        <div
          v-else-if="taskDetails.translation_records && taskDetails.translation_records.length"
          class="c-TranslationRecordListSection__tableContainer">
          <table class="c-TranslationRecordListSection__table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Editor</th>
                <th>Status</th>
                <th class="c-TranslationRecordListSection__edit">Edit Distance</th>
                <th class="c-TranslationRecordListSection__timeTaken">Time taken</th>
                <th>Device</th>
                <th class="c-TranslationRecordListSection__datesHeader">Created / Completed</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in paginatedPages"
                :key="index"
                @click="linkToTranslationRecord(item)">
                <td
                  :id="item.id"
                  class="c-TranslationRecordListSection__id"
                  @click="copyText(item.id, index, $event)">
                  <span :data-test-id="`msg-tr-list-id${index}`">{{ truncateId(item.id) }}</span>
                  <i class="c-TranslationRecordListSection__copyIcon"></i>
                  <Tooltip
                    :message="item.isIdCopied ? 'Copied!' : item.id"
                    :anchorName="item.id"></Tooltip>
                </td>
                <td class="c-TranslationRecordListSection__editor">
                  <a
                    v-if="item.editor && item.editor.name"
                    :id="`treditor${index}`"
                    :href="uadminEditorUrl(item.editor.name)"
                    rel="noopener noreferrer"
                    target="_blank"
                    :data-test-id="`msg-tr-list-editor${index}`"
                    @click="editorLink($event)">
                    {{ item.editor.name }}
                    <Tooltip
                      :message="'Go to editor profile'"
                      :position="'top'"
                      :anchorName="`treditor${index}`"></Tooltip>
                  </a>
                  <span v-else>{{ (item.editor && item.editor.name) || '---' }}</span>
                </td>
                <td class="c-TranslationRecordListSection__status">
                  <StatusChip
                    :status="item.action"
                    :data-test-id="`msg-tr-list-status${index}`"></StatusChip>
                </td>
                <td
                  class="c-TranslationRecordListSection__editDistance"
                  :data-test-id="`msg-tr-list-edit-distance${index}`">{{ item.avg_edit_distance != null ? item.avg_edit_distance : '---' }}</td>
                <td class="c-TranslationRecordListSection__timeTaken">
                  <span
                    :id="`trTimeTaken${index}`"
                    :data-test-id="`msg-tr-list-time-taken${index}`">
                    {{ item.parsed_duration ?  item.parsed_duration : item.duration }}
                    <Tooltip
                      v-if="item.parsed_duration && item.full_duration"
                      :message="item.full_duration"
                      :position="'top'"
                      :anchorName="`trTimeTaken${index}`"></Tooltip>
                  </span>
                </td>
                <td
                  class="c-TranslationRecordListSection__device"
                  :data-test-id="`msg-tr-list-device${index}`">{{ item.device || '---' }}</td>
                <td
                  class="c-TranslationRecordListSection__dates"
                  :class="translationRecordDatesClassObj(item)">
                  <div
                    class="c-TranslationRecordListSection__datesContainer"
                    :class="{ 'alwaysVisible': !item.session_replay_url && item.action !== 'submit' }">
                    <p :id="`trCreatedAt${index}`">
                      {{ item.parsed_created_at || '---' }}
                      <Tooltip
                        v-if="item.parsed_created_at && item.utc_parsed_created_at"
                        :message="item.utc_parsed_created_at"
                        :position="'top'"
                        :anchorName="`trCreatedAt${index}`"></Tooltip>
                    </p>
                    <p
                      v-if="item.parsed_completed_at"
                      :id="`trCompletedAt${index}`">
                      {{ item.parsed_completed_at }}
                      <Tooltip
                        v-if="item.utc_parsed_completed_at"
                        :message="item.utc_parsed_completed_at"
                        :position="'top'"
                        :anchorName="`trCompletedAt${index}`"></Tooltip>
                    </p>
                  </div>
                  <div
                    v-if="item.session_replay_url || item.action === 'submit'"
                    class="c-TranslationRecordListSection__actionsContainer">
                    <a
                      v-if="item.session_replay_url"
                      :id="`sessionreplay${index}`"
                      :href="item.session_replay_url"
                      rel="noopener noreferrer"
                      target="_blank"
                      class="c-TranslationRecordListSection__fullstory"
                      :data-test-id="`msg-tr-list-fullstory${index}`"
                      @click="fullStoryLink($event)"
                      @mouseenter="handleMouseOver(item, 'isReplayHovered', true)"
                      @mouseleave="handleMouseOver(item, 'isReplayHovered', false)">
                      <Icon
                        class="icon"
                        icon="external-hyperlink"
                        :icon-style="item.isReplayHovered ? 'is-primary' : 'is-dark-gray'" />
                      <Tooltip
                        message="Link to Fullstory"
                        :position="'top'"
                        :anchorName="`sessionreplay${index}`"></Tooltip>
                    </a>
                    <button
                      v-if="item.action === 'submit'"
                      :id="`sendtoeval${index}`"
                      type="button"
                      class="c-TranslationRecordListSection__sendToEval"
                      :data-test-id="`msg-tr-list-send-evaluation${index}`"
                      @click="toogleConfirmationSendToEvaluation(item, $event)"
                      @mouseenter="handleMouseOver(item, 'isSendToEvalHovered', true)"
                      @mouseleave="handleMouseOver(item, 'isSendToEvalHovered', false)">
                      <Icon
                        class="icon"
                        icon="send-email"
                        :icon-style="item.isSendToEvalHovered ? 'is-primary' : 'is-dark-gray'" />
                      <Tooltip
                        message="Send to Evaluation"
                        :position="'top'"
                        :anchorName="`sendtoeval${index}`"></Tooltip>
                    </button>
                  </div>
                </td>
                <td @click.stop="">
                  <ActionMenu
                    :menu-items="actionMenuItems"
                    @menuClick="$event => $event.action(item.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          v-else
          class="c-TranslationRecordListSection__emptyState">
          <span>No Translation Records have been created yet</span>
          <Tooltip
            :textOverflowEllipsis="false"
            message="This data is coming directly from Tarkin, if Translation Records do not appear here, they are not in the queue." />
        </p>
        <Pagination
          v-if="totalPages > 1"
          :currentPage="currPage"
          :paginationItems="paginationItems"
          :totalPages="totalPages"
          @changePage="handleChangePage" />
      </div>
    </Card>
    <ConfirmationModal
      :data="sendToEvaluationData"
      :visibility="confirmationModalData.visibility"
      @toggleModal="toogleConfirmationSendToEvaluation(null)"></ConfirmationModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusChip from '@/components/StatusChip'
import ConfirmationModal from '@/components/ConfirmationModal'
import Pagination from '@/components/Pagination'
import {
  copyToClipboard,
  truncateString,
  showNotification,
  parseRequestError,
  trackEvent
} from '@/utils/generalUtils'
import { Button, Tooltip, Card, Icon } from '@unbabel/ui'
import { GLOBAL_GETTERS, GLOBAL_ACTIONS, TASK_TYPE, MIXPANEL_EVENTS } from '@/data/enum'
import ActionMenu from '@/components/ActionMenu'

export default {
  name: 'TranslationRecordListSection',
  components: {
    ActionMenu,
    Button,
    Card,
    Tooltip,
    StatusChip,
    ConfirmationModal,
    Icon,
    Pagination
  },
  props: {
    taskDetails: {
      type: Object,
      required: true
    },
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      confirmationModalData: {
        visibility: false,
        item: null
      },
      sendToEvaluationData: {
        title: 'Send to evaluation?',
        text: [
          'Are you sure you want to send this task to evaluation? You will not be able to undo this action.'
        ],
        actionLeft: {
          label: 'send',
          buttonType: 'is-text',
          buttonStyle: 'is-primary',
          handler: this.confirmationModalLeftActionHandler
        },
        actionRight: {
          label: 'dismiss',
          buttonType: 'is-text',
          buttonStyle: 'is-gray-800',
          handler: this.confirmationModalRightActionHandler
        }
      },
      isEditorUrlCopied: false,
      currPage: 0,
      paginationItems: 30,
      actionMenuItems: [
        {
          label: 'Reassign task',
          value: 'clear_skips',
          action: id => this.handleReassignTask(id)

        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: GLOBAL_GETTERS.USER,
      translationRecordsLoading: GLOBAL_GETTERS.HT_TASKS_LOADING
    }),
    totalPages () {
      if (this.taskDetails.translation_records && this.taskDetails.translation_records.length) {
        return Math.ceil(this.taskDetails.translation_records.length / this.paginationItems)
      }
      return 0
    },
    paginatedPages () {
      return this.taskDetails.translation_records.slice(this.currPage * this.paginationItems, (this.currPage + 1) * this.paginationItems)
    },
    isSeniorReview () {
      return this.taskDetails.type === TASK_TYPE.REVIEW
    },
    trCount () {
      return this.taskDetails.translation_records && this.taskDetails.translation_records.length ? this.taskDetails.translation_records.length : 0
    },
    translationRecordsLoadingError () {
      return this.messageDetails?.human_translation_details?.translationRecordsLoadingError
    }
  },
  methods: {
    handleChangePage (newPage) {
      this.currPage = newPage
    },
    editorLink (ev) {
      ev.stopPropagation()
      trackEvent(MIXPANEL_EVENTS.EDITOR_PROFILE_LINK, {
        area: this.$route.name,
        type: 'editor-profile'
      })
    },
    fullStoryLink (ev) {
      ev.stopPropagation()
      trackEvent(MIXPANEL_EVENTS.FULLSTORY_LINK, {
        area: this.$route.name,
        type: 'fullstory'
      })
    },
    copyUrlToClipboard () {
      if (!this.isEditorUrlCopied) {
        this.isEditorUrlCopied = true
        copyToClipboard(`${process.env.VUE_APP_CORE_BASE_URL}/editor/senior_review/${this.taskDetails.id}/`)

        setTimeout(() => {
          this.isEditorUrlCopied = false
        }, 1000)
      }
    },
    copyText (id, index, ev) {
      ev.stopPropagation()
      if (!this.taskDetails.translation_records[index].isIdCopied) {
        this.taskDetails.translation_records[index].isIdCopied = true
        copyToClipboard(id)

        setTimeout(() => {
          this.taskDetails.translation_records[index].isIdCopied = false
        }, 1000)
      }
    },
    truncateId (id) {
      return truncateString(id, 6, true)
    },
    translationRecordDatesClassObj (item) {
      return {
        'bottom-right': item.parsed_created_at && item.parsed_completed_at
      }
    },
    linkToTranslationRecord (item) {
      trackEvent(MIXPANEL_EVENTS.OPEN_TR_CLICK, {
        area: this.$route.name
      })

      this.$router.push({
        name: 'TranslationRecordDetails',
        params: {
          conversationId: this.messageDetails.thread_id || 'THREAD_ID',
          messageId: this.messageDetails.uid || 'MESSAGE_ID',
          taskId: this.taskDetails.id,
          translationRecordId: item.id
        }
      })
    },
    uadminEditorUrl (editorName) {
      return `${process.env.VUE_APP_CORE_BASE_URL}/uadmin/users/${editorName}`
    },
    toogleConfirmationSendToEvaluation (item, ev) {
      ev.stopPropagation()
      this.confirmationModalData.item = item
      this.confirmationModalData.visibility = !this.confirmationModalData.visibility
    },
    confirmationModalLeftActionHandler () {
      this.confirmationModalData.visibility = false

      trackEvent(MIXPANEL_EVENTS.SEND_TO_EVALUATION, {
        area: this.$route.name
      })

      const payload = {
        id: this.confirmationModalData?.item?.id,
        uid: this.messageDetails?.uid,
        content_type: this.messageDetails?.human_translation_details?.content_type,
        data: {
          requester_username: this.user.email
        }
      }

      this.$store.dispatch(GLOBAL_ACTIONS.SEND_RECORD_TO_EVALUATION, payload).then((res) => {
        showNotification(this, 'is-added', `The translation record ${payload.id} was successfully sent to evaluation`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error sending the translation record ${payload.id} to evaluation ::: ${msg}`)
      })
    },
    confirmationModalRightActionHandler () {
      this.confirmationModalData.visibility = false
    },
    handleMouseOver (item, prop, val) {
      item[`${prop}`] = val
    },
    async handleReassignTask (id) {
      const payload = {
        id: id,
        actor: this.user.email
      }
      await this.$store.dispatch(GLOBAL_ACTIONS.REASSIGN_TASK, payload).then((res) => {
        showNotification(this, 'is-added', `Task ${id} was reassigned`)
      }, err => {
        showNotification(this, 'is-alert', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-TranslationRecordListSection {
  &__tableContainer {
    overflow-x: auto;
  }

  &__table {
    background-color: $un-white;
    border-spacing: 0;
    min-width: 100%;

    th,
    td {
      padding: 0 $base-padding;
      text-align: left;
      vertical-align: middle;
      height: 72px;

      &:first-child {
        padding-left: calc(#{$base-padding} * 2);
      }

      &:last-child {
        padding-right: calc(#{$base-padding} * 2);
      }

      &:first-child {
        padding-left: calc(#{$base-padding} * 2);
      }
    }

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      text-align: left;
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;
    }

    tbody tr {
      &:last-child {
        box-shadow: none;
      }
      &:hover {
        background-color: $un-n50;
        cursor: pointer;

        .c-TranslationRecordListSection__datesContainer {
          display: none;
        }

        .c-TranslationRecordListSection__actionsContainer {
          display: flex;
        }
      }
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.21px;
      text-align: left;
    }
  }

  &__editor {
    a {
      color: $un-blue;
      font-weight: 600;
    }

    a:hover {
      color: $un-blue;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__dates, &__datesHeader {
    text-align: right !important;

    p:nth-child(2) {
      margin-top: calc(#{$base-margin / 2});
    }
  }

  &__editDistance, &__timeTaken, &__dates {
    white-space: nowrap;
  }

  &__id {
    font-weight: 600;

    &:hover {
      cursor: pointer;

      span {
        padding-right: 0;
      }

      .c-TranslationRecordListSection__copyIcon {
        display: block;
      }
    }

    white-space: nowrap;
    display: flex;
    align-items: center;

    span {
      padding-right: 30px;
    }
  }

  &__copyIcon {
    display: none;
    margin-left: 6px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
  }

  &__datesContainer {
    &.alwaysVisible {
      display: block !important;
    }
  }

  &__actionsContainer {
    display: none;
    align-items: center;
    justify-content: flex-end;

    button, a {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      .icon {
        margin-left: 2px;
      }

      &:hover {
        background-color: $un-blue-light;
      }
    }

    a {
      margin-right: calc(#{$base-margin / 2});
    }
  }

  &__emptyState {
    display: flex;
    justify-content: center;
    padding: $base-padding;
    width: 100%;

    > span {
      font-family: $primary-font;
      font-style: italic;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: $un-n700;
      margin-right: $base-margin;
    }
  }
}
</style>
