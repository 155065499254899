<template>
  <div class="l-SearchView">
    <div
      class="l-SearchView__search"
      @keyup="handleKeyup">
      <div class="l-SearchView__searchContent">
        <GroupedInputText
          class="l-SearchView__input triggerEnter"
          select-label="Search"
          :select-items="groupedInputTextSelectItems"
          input-label-prefix="Search by"
          :selectWidth="181"
          :data="parseGroupedInputTextValue"
          :disabled="groupedInputTextDisabledState"
          :is-active="groupedInputTextActiveState"
          @change="updateFilters"
          @input="updateFilters">
          </GroupedInputText>
        <ChipMultiSelect
          class="l-SearchView__input"
          :data="filters[`${FILTERS_DICT.TEMPLATE}`]"
          :select-items="templateSelectItems"
          :disabled="otherFieldsDisabled"
          :label="'Template'"
          :is-active="filterFieldsActive[`${FILTERS_DICT.TEMPLATE}`]"
          @change="updateFilters(FILTERS_DICT.TEMPLATE, $event)">
        </ChipMultiSelect>
        <ChipMultiSelect
          class="l-SearchView__input"
          :data="filters[`${FILTERS_DICT.STATUS}`]"
          :select-items="statusSelectItems"
          :disabled="otherFieldsDisabled"
          :label="'Status'"
          :is-active="filterFieldsActive[`${FILTERS_DICT.STATUS}`]"
          @change="updateFilters(FILTERS_DICT.STATUS, $event)">
        </ChipMultiSelect>
        <OldDateTimePicker
          class="l-SearchView__input"
          :data="filters[`${FILTERS_DICT.FROM_CREATED_DATE}`]"
          :max-date="maxDate"
          :disabled="datesDisabled"
          label="From Created Date"
          :has-clear-button="false"
          :is-active="filterFieldsActive[`${FILTERS_DICT.FROM_CREATED_DATE}`]"
          @change="updateFilters(FILTERS_DICT.FROM_CREATED_DATE, $event)">
        </OldDateTimePicker>
        <OldDateTimePicker
          class="l-SearchView__input"
          :data="filters[`${FILTERS_DICT.TO_CREATED_DATE}`]"
          :min-date="minDate"
          :disabled="datesDisabled"
          label="To Created Date"
          :has-clear-button="false"
          :is-active="filterFieldsActive[`${FILTERS_DICT.TO_CREATED_DATE}`]"
          @change="updateFilters(FILTERS_DICT.TO_CREATED_DATE, $event)">
        </OldDateTimePicker>
        <ChipMultiSelect
          class="l-SearchView__input brand"
          label="Pipeline Group"
          :select-items="brandOptions"
          :from-external-source="true"
          :is-data-loading="brandDataLoading"
          :min-char-match="2"
          :data="filters[`${FILTERS_DICT.TRANSLATION_PROFILE_IDS}`]"
          :disabled="otherFieldsDisabled"
          :is-active="filterFieldsActive[`${FILTERS_DICT.TRANSLATION_PROFILE_IDS}`]"
          @search="handleBrandSearch"
          @change="updateFilters(FILTERS_DICT.TRANSLATION_PROFILE_IDS, $event)">
        </ChipMultiSelect>
        <ChipMultiSelect
          class="l-SearchView__input client"
          label="Client"
          :select-items="canonicalNameOptions"
          :from-external-source="true"
          :is-data-loading="isCanonicalNameDataLoading"
          :min-char-match="2"
          :data="filters[`${FILTERS_DICT.CANONICAL_NAME}`]"
          :disabled="otherFieldsDisabled"
          :is-active="filterFieldsActive[`${FILTERS_DICT.CANONICAL_NAME}`]"
          @search="handleCanonicalNameSearch"
          @change="updateFilters(FILTERS_DICT.CANONICAL_NAME, ...arguments)">
        </ChipMultiSelect>
        <InputText
          class="l-SearchView__input triggerEnter"
          label="Client Username"
          :data="filters[`${FILTERS_DICT.USERNAME}`]"
          :disabled="otherFieldsDisabled"
          :is-active="filterFieldsActive[`${FILTERS_DICT.USERNAME}`]"
          @change="updateFilters(FILTERS_DICT.USERNAME, $event)">
        </InputText>
        <ChipMultiSelect
          class="l-SearchView__input"
          label="Document type"
          :data="filters[`${FILTERS_DICT.TEXT_FORMATS}`]"
          :disabled="otherFieldsDisabled"
          :select-items="textFormatSelectItems"
          :is-active="filterFieldsActive[`${FILTERS_DICT.TEXT_FORMATS}`]"
          @change="updateFilters(FILTERS_DICT.TEXT_FORMATS, $event)">
        </ChipMultiSelect>
        <ChipMultiSelect
          class="l-SearchView__input"
          label="Source Language"
          :data="filters[`${FILTERS_DICT.SOURCE_LANGUAGE}`]"
          :disabled="otherFieldsDisabled"
          :select-items="sourceLangOptions"
          :is-active="filterFieldsActive[`${FILTERS_DICT.SOURCE_LANGUAGE}`]"
          @change="updateFilters(FILTERS_DICT.SOURCE_LANGUAGE, $event)">
        </ChipMultiSelect>
        <ChipMultiSelect
          class="l-SearchView__input"
          label="Target Language"
          :data="filters[`${FILTERS_DICT.TARGET_LANGUAGE}`]"
          :disabled="otherFieldsDisabled"
          :select-items="targetLangOptions"
          :is-active="filterFieldsActive[`${FILTERS_DICT.TARGET_LANGUAGE}`]"
          @change="updateFilters(FILTERS_DICT.TARGET_LANGUAGE, $event)">
        </ChipMultiSelect>
        <ChipMultiSelect
          class="l-SearchView__input"
          label="Origin"
          :data="filters[`${FILTERS_DICT.ORIGINS}`]"
          :disabled="otherFieldsDisabled"
          :select-items="originSelectItems"
          :is-active="filterFieldsActive[`${FILTERS_DICT.ORIGINS}`]"
          @change="updateFilters(FILTERS_DICT.ORIGINS, $event)">
        </ChipMultiSelect>
        <Select
          class="l-SearchView__input"
          label="Architecture"
          :data="filters[`${FILTERS_DICT.ARCHITECTURES}`]"
          :disabled="otherFieldsDisabled"
          :select-items="architectureSelectItems"
          :is-active="filterFieldsActive[`${FILTERS_DICT.ARCHITECTURES}`]"
          @change="updateFilters(FILTERS_DICT.ARCHITECTURES, $event)">
        </Select>
        <Select
          class="l-SearchView__input"
          label="Pivoted"
          :data="filters[`${FILTERS_DICT.IS_PIVOTED}`]"
          :disabled="otherFieldsDisabled"
          :select-items="pivotedSelectItems"
          :is-active="filterFieldsActive[`${FILTERS_DICT.IS_PIVOTED}`]"
          @change="updateFilters(FILTERS_DICT.IS_PIVOTED, $event)">
        </Select>
        <Select
          class="l-SearchView__input"
          label="Use case"
          :data="filters[`${FILTERS_DICT.USECASE}`]"
          :disabled="otherFieldsDisabled"
          :select-items="usecaseSelectItems"
          :is-active="filterFieldsActive[`${FILTERS_DICT.USECASE}`]"
          @change="updateFilters(FILTERS_DICT.USECASE, $event)">
        </Select>
      </div>
      <div class="l-SearchView__buttonsRow">
        <Button
          class="clear"
          label="clear all"
          button-type="is-text"
          data-test-id="clear-search"
          @click="clearSearch">
        </Button>
        <Button
          label="search"
          button-type="is-contained"
          :disabled="isSearchDisabled"
          data-test-id="search-btn"
          @click="search">
        </Button>
      </div>
    </div>
    <div class="l-SearchView__results">
      <div class="l-SearchView__filters">
        <div class="contentType">
          <button
            type="button"
            :class="{ active: !$route.query[`${FILTERS_DICT.CONTENT_TYPE}`] }"
            data-test-id="all-content-types"
            @click="applyContentTypeFilter">
            <span>All</span>
          </button>
          <button
            type="button"
            :class="{ active: $route.query[`${FILTERS_DICT.CONTENT_TYPE}`] === CONTENT_TYPE.TICKET }"
            data-test-id="ticket-content-type"
            @click="applyContentTypeFilter(CONTENT_TYPE.TICKET)">
            <span>Tickets</span>
          </button>
          <button
            type="button"
            :class="{ active: $route.query[`${FILTERS_DICT.CONTENT_TYPE}`] === CONTENT_TYPE.CHAT }"
            data-test-id="chat-content-type"
            @click="applyContentTypeFilter(CONTENT_TYPE.CHAT)">
            <span>Chat</span>
          </button>
          <button
            v-show="contentTypeFaqConditions"
            type="button"
            :class="{ active: $route.query[`${FILTERS_DICT.CONTENT_TYPE}`] === CONTENT_TYPE.FAQ }"
            data-test-id="faqs-content-type"
            @click="applyContentTypeFilter(CONTENT_TYPE.FAQ)">
            <span>FAQ's</span>
          </button>
        </div>
        <div
          v-show="translationDirectionConditions"
          class="translationDirection">
          <button
            type="button"
            :class="{ active: $route.query[`${FILTERS_DICT.TRANSLATION_DIRECTION}`] === 'inbound' }"
            data-test-id="translation-direction-inbound"
            @click="applyTranslationDirectionFilter('inbound')">
            <span>Inbound</span>
          </button>
          <button
            type="button"
            :class="{ active: $route.query[`${FILTERS_DICT.TRANSLATION_DIRECTION}`] === 'outbound' }"
            data-test-id="translation-direction-outbound"
            @click="applyTranslationDirectionFilter('outbound')">
            <span>Outbound</span>
          </button>
        </div>
      </div>
      <div class="l-SearchView__actions">
        <button
          type="button"
          @click="downloadResults">
          <span>Download</span>
          <i class="download"></i>
        </button>
        <button
          type="button"
          @click="refreshSearch">
          <span>Updated <b>{{ refreshTime }} (UTC)</b></span>
          <i class="update"></i>
        </button>
      </div>
    </div>
    <SearchTable
      class="l-SearchView__searchTable"
      :data="searchList">
    </SearchTable>
    <div
      v-show="!isLoading && searchList && searchList.length"
      class="l-SearchView__pagination">
      <p>Showing {{ searchList && searchList.length }} translations</p>
      <Button
        v-show="!filters[`${FILTERS_DICT.LAST_PAGE}`]"
        label="load more"
        button-type="is-outlined"
        data-test-id="load-more-results"
        @click="loadMoreResults" />
    </div>
    <SidePanel
      v-if="$route.query && $route.query.pThrId && $route.query.pMsgId"
      class="l-SearchView__sidePanel"
      @closeSidePanel="handleCloseSidePanel"></SidePanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  GLOBAL_GETTERS,
  GLOBAL_ACTIONS,
  FILTERS_DICT,
  MIXPANEL_EVENTS,
  CONTENT_TYPE
} from '@/data/enum'
import {
  showNotification,
  parseRequestError,
  trackEvent,
  prefixObjectKeys
} from '@/utils/generalUtils'
import {
  updateURLparams
} from '@/utils/urlUtils'
import {
  SEARCH_ACTIVE_FILTER_FIELDS,
  SEARCH_BUTTON_UI_DISABLED_STATUS,
  PAGINATION_HANDLE_REFRESH_PAGE_FILTER_INTERDEPENDENCIES,
  SEARCH_FILTERS_UI_DISABLING,
  UPDATE_FILTERS_ACTION_INTERDEPENDENCIES,
  CLEAR_SEARCH_FILTER_INTERDEPENDENCIES
} from '@/data/filters'
import {
  ChipMultiSelect,
  Select,
  InputText,
  GroupedInputText,
  Button
} from '@unbabel/ui'
import SearchTable from '@/components/SearchTable.vue'
import SidePanel from '@/components/SidePanel'
import OldDateTimePicker from '@/components/OldDateTimePicker'

export default {
  name: 'Search',
  components: {
    SearchTable,
    SidePanel,
    ChipMultiSelect,
    InputText,
    GroupedInputText,
    OldDateTimePicker,
    Select,
    Button
  },
  data () {
    return {
      searchFilters: {},
      minDate: undefined,
      maxDate: undefined,
      groupedInputTextKeySelected: FILTERS_DICT.UID,
      groupedInputTextDisabled: false,
      otherFieldsDisabled: false,
      datesDisabled: false,
      brandOptions: [],
      brandDataLoading: false,
      canonicalNameOptions: [],
      isCanonicalNameDataLoading: false,
      FILTERS_DICT,
      CONTENT_TYPE
    }
  },
  computed: {
    ...mapGetters({
      isLoading: GLOBAL_GETTERS.LOADING,
      filters: GLOBAL_GETTERS.SEARCH_FILTERS,
      searchList: GLOBAL_GETTERS.LIST,
      refreshTime: GLOBAL_GETTERS.REFRESH_TIME,
      sourceLangOptions: GLOBAL_GETTERS.SOURCE_LANG_OPTIONS,
      targetLangOptions: GLOBAL_GETTERS.TARGET_LANG_OPTIONS,
      statusSelectItems: GLOBAL_GETTERS.STATUS_SELECT_ITEMS,
      templateSelectItems: GLOBAL_GETTERS.TEMPLATE_SELECT_ITEMS,
      textFormatSelectItems: GLOBAL_GETTERS.TEXT_FORMAT_SELECT_ITEMS,
      originSelectItems: GLOBAL_GETTERS.ORIGIN_SELECT_ITEMS,
      architectureSelectItems: GLOBAL_GETTERS.ARCH_SELECT_ITEMS,
      pivotedSelectItems: GLOBAL_GETTERS.PIVOTED_SELECT_ITEMS,
      groupedInputTextSelectItems: GLOBAL_GETTERS.GROUPED_INPUT_TEXT_SELECT_ITEMS,
      contentTypeSelectItems: GLOBAL_GETTERS.CONTENT_TYPE_SELECT_ITEMS,
      usecaseSelectItems: GLOBAL_GETTERS.USECASE_SELECT_ITEMS,
      user: GLOBAL_GETTERS.USER
    }),
    sidePanelConditions () {
      return this.$route.query && this.$route.query.pThrId &&
      this.$route.query.pThrId.length > 0 &&
      this.$route.query.pMsgId && this.$route.query.pMsgId.length > 0
    },
    parseGroupedInputTextValue () {
      if (this.filters[`${FILTERS_DICT.UID}`]) {
        return {
          [FILTERS_DICT.UID]: this.filters[`${FILTERS_DICT.UID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.THREAD_ID}`]) {
        return {
          [FILTERS_DICT.THREAD_ID]: this.filters[`${FILTERS_DICT.THREAD_ID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.EXTERNAL_ID}`]) {
        return {
          [FILTERS_DICT.EXTERNAL_ID]: this.filters[`${FILTERS_DICT.EXTERNAL_ID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.THREAD_EXTERNAL_ID}`]) {
        return {
          [FILTERS_DICT.THREAD_EXTERNAL_ID]: this.filters[`${FILTERS_DICT.THREAD_EXTERNAL_ID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.PROJECT_ID}`]) {
        return {
          [FILTERS_DICT.PROJECT_ID]: this.filters[`${FILTERS_DICT.PROJECT_ID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.TASK_ID}`]) {
        return {
          [FILTERS_DICT.TASK_ID]: this.filters[`${FILTERS_DICT.TASK_ID}`]
        }
      }
      if (this.filters[`${FILTERS_DICT.TRANSLATION_RECORD_ID}`]) {
        return {
          [FILTERS_DICT.TRANSLATION_RECORD_ID]: this.filters[`${FILTERS_DICT.TRANSLATION_RECORD_ID}`]
        }
      }

      return {
        [`${this.groupedInputTextKeySelected}`]: undefined
      }
    },
    groupedInputTextDisabledState () {
      return this.groupedInputTextDisabled &&
      (this.groupedInputTextKeySelected !== FILTERS_DICT.THREAD_ID &&
      this.groupedInputTextKeySelected !== FILTERS_DICT.THREAD_EXTERNAL_ID)
    },
    groupedInputTextActiveState () {
      return this.filterFieldsActive[`${FILTERS_DICT.UID}`] ||
      this.filterFieldsActive[`${FILTERS_DICT.THREAD_ID}`] ||
      this.filterFieldsActive[`${FILTERS_DICT.EXTERNAL_ID}`] ||
      this.filterFieldsActive[`${FILTERS_DICT.THREAD_EXTERNAL_ID}`] ||
      this.filterFieldsActive[`${FILTERS_DICT.TRANSLATION_RECORD_ID}`] ||
      this.filterFieldsActive[`${FILTERS_DICT.TASK_ID}`]
    },
    filterFieldsActive () {
      return SEARCH_ACTIVE_FILTER_FIELDS(this.searchFilters)
    },
    isSearchDisabled () {
      return SEARCH_BUTTON_UI_DISABLED_STATUS(this.searchFilters, this.filterFieldsActive)
    },
    contentTypeFaqConditions () {
      return !this.filters[`${FILTERS_DICT.TRANSLATION_DIRECTION}`]
    },
    translationDirectionConditions () {
      return !this.filters[`${FILTERS_DICT.CONTENT_TYPE}`] || this.filters[`${FILTERS_DICT.CONTENT_TYPE}`] !== CONTENT_TYPE.FAQ
    }
  },
  methods: {
    applyContentTypeFilter (val) {
      switch (val) {
        case CONTENT_TYPE.TICKET:
        case CONTENT_TYPE.CHAT:
        case CONTENT_TYPE.FAQ:
          this.updateFilters(FILTERS_DICT.CONTENT_TYPE, val)
          this.search()
          break
        default:
          this.updateFilters(FILTERS_DICT.CONTENT_TYPE, null)
          this.search()
          break
      }
    },
    applyTranslationDirectionFilter (val) {
      const filterValue = this.$route.query[`${FILTERS_DICT.TRANSLATION_DIRECTION}`] &&
      this.$route.query[`${FILTERS_DICT.TRANSLATION_DIRECTION}`] === val ? undefined : val
      this.updateFilters(FILTERS_DICT.TRANSLATION_DIRECTION, filterValue)
      this.search()
    },
    handleBrandSearch (search) {
      this.brandDataLoading = true
      this.$store.dispatch(GLOBAL_ACTIONS.FETCH_BRAND_INFO, search).then((res) => {
        this.brandDataLoading = false
        this.brandOptions = res.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      }, err => {
        this.brandDataLoading = false
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error fetching Brand filter information :: ${msg}`)
      })
    },
    handleCanonicalNameSearch (search) {
      this.isCanonicalNameDataLoading = true
      this.$store.dispatch(GLOBAL_ACTIONS.FETCH_CANONICAL_NAME_INFO, search).then((res) => {
        this.isCanonicalNameDataLoading = false
        const viableOptions = res.filter(cn => cn.friendly_name && cn.id)
        this.canonicalNameOptions = viableOptions.map(item => {
          return {
            label: item.friendly_name,
            value: item.id
          }
        })
      }, err => {
        this.isCanonicalNameDataLoading = false
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error fetching Canonical Name filter information :: ${msg}`)
      })
    },
    handleKeyup (ev) {
      // trigger search with Enter only for inputText elements
      if (ev.key === 'Enter' && ev.target.classList && ev.target.classList[0] && ev.target.classList[0] === 'c-inputText__input') {
        this.search()
      }
    },
    loadMoreResults () {
      trackEvent(MIXPANEL_EVENTS.LOAD_MORE, {})

      // update URL params and perform search
      updateURLparams(this, this.searchFilters).then(() => {
        this.$store.dispatch(GLOBAL_ACTIONS.DO_SEARCH, {
          ctx: this,
          filters: this.searchFilters,
          isLoadMore: true
        }).catch(err => {
          const msg = parseRequestError(err)
          showNotification(this, 'is-alert', `Error with next page on Search :: ${msg}`)
        })
      })
    },
    updateFilters (key, value) {
      // update filter value
      this.searchFilters[key] = value

      // record the selected key on the groupedInputText to handle the fields disable properly
      if (key === FILTERS_DICT.UID || key === FILTERS_DICT.THREAD_ID ||
      key === FILTERS_DICT.EXTERNAL_ID || key === FILTERS_DICT.THREAD_EXTERNAL_ID ||
      key === FILTERS_DICT.TASK_ID || key === FILTERS_DICT.TRANSLATION_RECORD_ID) {
        this.groupedInputTextKeySelected = key
      }

      // disable the UI according to the filters values
      const disableInfo = SEARCH_FILTERS_UI_DISABLING(key, this.searchFilters)
      this.searchFilters = disableInfo.searchFilters
      this.groupedInputTextDisabled = disableInfo.groupedInputTextDisabled
      this.otherFieldsDisabled = disableInfo.otherFieldsDisabled
      this.datesDisabled = disableInfo.datesDisabled

      // handle filters interdependencies
      const interdependencies = UPDATE_FILTERS_ACTION_INTERDEPENDENCIES(this.searchFilters)
      this.searchFilters = interdependencies.searchFilters
      this.minDate = interdependencies.minDate
      this.maxDate = interdependencies.maxDate
    },
    search () {
      // update URL params and perform search
      updateURLparams(this, this.searchFilters).then(() => {
        const reqStartTime = new Date()

        this.$store.dispatch(GLOBAL_ACTIONS.DO_SEARCH, {
          ctx: this,
          filters: this.searchFilters,
          isNewSearch: true
        }).then(() => {
          const reqEndTime = new Date()
          const diff = reqEndTime - reqStartTime

          trackEvent(MIXPANEL_EVENTS.DO_SEARCH, {
            ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
            success: true,
            time_taken: diff
          })
        }, err => {
          const reqEndTime = new Date()
          const diff = reqEndTime - reqStartTime

          trackEvent(MIXPANEL_EVENTS.DO_SEARCH, {
            ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
            success: false,
            time_taken: diff
          })

          const msg = parseRequestError(err)
          showNotification(this, 'is-alert', `Error applying Search :: ${msg}`)
        })
      })
    },
    refreshSearch () {
      // handle filters interdependencies
      this.searchFilters = PAGINATION_HANDLE_REFRESH_PAGE_FILTER_INTERDEPENDENCIES(this.searchFilters)

      const reqStartTime = new Date()
      this.$store.dispatch(GLOBAL_ACTIONS.DO_SEARCH, {
        ctx: this,
        filters: this.searchFilters,
        isNewSearch: true
      }).then(() => {
        const reqEndTime = new Date()
        const diff = reqEndTime - reqStartTime

        trackEvent(MIXPANEL_EVENTS.REFRESH_SEARCH, {
          ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
          success: true,
          time_taken: diff
        })
      }, err => {
        const reqEndTime = new Date()
        const diff = reqEndTime - reqStartTime

        trackEvent(MIXPANEL_EVENTS.REFRESH_SEARCH, {
          ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
          success: false,
          time_taken: diff
        })
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error refreshing Search :: ${msg}`)
      })
    },
    async downloadResults () {
      const reqStartTime = new Date()
      try {
        await this.$store.dispatch(GLOBAL_ACTIONS.DOWNLOAD_SEARCH_RESULTS, {
          ctx: this,
          filters: this.searchFilters
        })
        const reqEndTime = new Date()
        const diff = reqEndTime - reqStartTime

        trackEvent(MIXPANEL_EVENTS.DOWNLOAD_SEARCH_RESULTS, {
          ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
          success: true,
          time_taken: diff
        })

        showNotification(this, 'is-added', `Processing download. Sending report to ${this.user.email} with results from this search.`)
      } catch (e) {
        const reqEndTime = new Date()
        const diff = reqEndTime - reqStartTime

        trackEvent(MIXPANEL_EVENTS.DOWNLOAD_SEARCH_RESULTS, {
          ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
          success: false,
          time_taken: diff
        })

        const msg = parseRequestError(e)
        showNotification(this, 'is-alert', `Error downloading results :: ${msg}`)
      }
    },
    clearSearch () {
      // handle filters interdependencies
      const clearFilters = CLEAR_SEARCH_FILTER_INTERDEPENDENCIES({ ...this.searchFilters })

      // update URL params and perform search
      updateURLparams(this, clearFilters).then(() => {
        const reqStartTime = new Date()

        this.$store.dispatch(GLOBAL_ACTIONS.DO_SEARCH, {
          ctx: this,
          filters: clearFilters,
          isNewSearch: true
        }).then(() => {
          const reqEndTime = new Date()
          const diff = reqEndTime - reqStartTime

          trackEvent(MIXPANEL_EVENTS.CLEAR_SEARCH, {
            ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
            success: true,
            time_taken: diff
          })
        }, err => {
          const reqEndTime = new Date()
          const diff = reqEndTime - reqStartTime

          trackEvent(MIXPANEL_EVENTS.CLEAR_SEARCH, {
            ...prefixObjectKeys(this.searchFilters, 'search_filters_'),
            success: true,
            time_taken: diff
          })
          const msg = parseRequestError(err)
          showNotification(this, 'is-alert', `Error clearing Search :: ${msg}`)
        })
      })
    },
    handleCloseSidePanel () {
      const queryParams = { ...this.$route.query }
      delete queryParams.pThrId
      delete queryParams.pMsgId

      this.$router.push({
        name: 'Search',
        query: queryParams
      })
    }
  },
  watch: {
    filters: {
      handler: function (newVal) {
        this.searchFilters = newVal

        // disable the UI according to the filters values
        const disableInfo = SEARCH_FILTERS_UI_DISABLING(null, this.searchFilters)
        this.searchFilters = disableInfo.searchFilters
        this.groupedInputTextDisabled = disableInfo.groupedInputTextDisabled
        this.otherFieldsDisabled = disableInfo.otherFieldsDisabled
        this.datesDisabled = disableInfo.datesDisabled

        // handle filters interdependencies
        const interdependencies = UPDATE_FILTERS_ACTION_INTERDEPENDENCIES(this.searchFilters)
        this.searchFilters = interdependencies.searchFilters
        this.minDate = interdependencies.minDate
        this.maxDate = interdependencies.maxDate
      },
      immediate: true
    }
  },
  beforeMount () {
    this.$store.dispatch(GLOBAL_ACTIONS.INIT_SEARCH_VIEW, this).catch(err => {
      const msg = parseRequestError(err)
      showNotification(this, 'is-alert', `Error initializing Search :: ${msg}`)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.l-SearchView {
  h4 {
    font-family: $secondary-font;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.52;
    color: $un-n900;
    margin-bottom: $base-margin;
  }

  &__search {
    border-radius: $border-radius;
    background-color: $un-white;
    padding: calc(#{$base-padding} * 1.5);
    margin-bottom: calc(#{$base-margin} * 2.5);
    border: 1px solid $un-n200;
  }

  &__searchContent {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    width: calc(33.3% - calc(#{$base-margin} * (2/3)));
    margin-right: $base-margin;
    margin-bottom: $base-margin;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__buttonsRow {
    display: flex;
    justify-content: flex-end;

    .clear {
      margin-right: $base-margin;
    }
  }

  &__results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $base-margin;

    .l-SearchView__filters {
      display: flex;

      .contentType {
        margin-right: calc(#{$base-margin} * 2);
      }

      button {
        border: 1px solid #C8D0E0;
        border-radius: 30px;
        background-color: transparent;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
        color: $un-n900;
        padding: 6px 12px;
        margin-right: calc(#{$base-margin} / 2);
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover, &.active {
          color: $un-blue;
          border: 1px solid $un-blue;
          background-color: rgba(162, 167, 238, 0.16);
        }
      }
    }
    .l-SearchView__actions {
      display: flex;

      button {
        border: none;
        background-color: transparent;
        margin: 0 10px;
        padding: 0;
        font-family: $primary-font;
        font-size: 14px;
        color: $un-n900;
        cursor: pointer;
        display: flex;

        span {
          margin-right: 5px;
        }

        b {
          font-weight: 600;
        }

        i {
          margin-top: 1px;
          width: 18px;
          height: 18px;
          display: inline-block;
          background-size: cover;
          background-repeat: no-repeat;

          &.update {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23707A89' fill-rule='evenodd' d='M21.375 5.495a.625.625 0 010 1.25h-2.196a8.756 8.756 0 011.569 5.18 8.703 8.703 0 01-2.69 6.134 8.717 8.717 0 01-6.062 2.438 8.706 8.706 0 01-3.365-.674.62.62 0 01-.337-.817.624.624 0 01.817-.338 7.451 7.451 0 002.554.571l.33.008a7.468 7.468 0 005.196-2.09 7.452 7.452 0 002.306-5.259A7.482 7.482 0 0018.249 7.6v2.27a.625.625 0 01-1.249 0V6.12c0-.345.28-.625.625-.625h3.75zM12.012 3c1.156 0 2.286.226 3.356.67a.622.622 0 01.337.817.623.623 0 01-.817.336 7.478 7.478 0 00-2.876-.574c-1.944 0-3.787.74-5.19 2.084a7.447 7.447 0 00-2.313 5.255 7.487 7.487 0 001.241 4.299v-2.268A.625.625 0 117 13.62v3.75c0 .345-.28.625-.625.625h-.198l-.038.003-.041-.003H2.625a.625.625 0 010-1.25h2.198a8.739 8.739 0 01-1.563-5.183A8.693 8.693 0 015.958 5.43 8.712 8.712 0 0112.012 3z'/%3E%3C/svg%3E");
          }

          &.download {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0ODUgNDg1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0ODUgNDg1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PHBhdGggZD0iTTQyNi41LDQ1OGgtMzY4QzUxLDQ1OCw0NSw0NjQsNDUsNDcxLjVTNTEsNDg1LDU4LjUsNDg1aDM2OGM3LjUsMCwxMy41LTYsMTMuNS0xMy41UzQzNCw0NTgsNDI2LjUsNDU4eiIvPjxwYXRoIGQ9Ik0yMzMsMzc4LjdjMi41LDIuNSw2LDQsOS41LDRzNy0xLjQsOS41LTRsMTA3LjUtMTA3LjVjNS4zLTUuMyw1LjMtMTMuOCwwLTE5LjFjLTUuMy01LjMtMTMuOC01LjMtMTkuMSwwTDI1NiwzMzYuNXYtMzIzQzI1Niw2LDI1MCwwLDI0Mi41LDBTMjI5LDYsMjI5LDEzLjV2MzIzbC04NC40LTg0LjRjLTUuMy01LjMtMTMuOC01LjMtMTkuMSwwcy01LjMsMTMuOCwwLDE5LjFMMjMzLDM3OC43eiIvPjwvZz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PC9zdmc+");
          }
        }
      }
    }
  }

  &__pagination {
    background-color: $un-white;
    text-align: center;
    padding: calc(#{$base-padding} * 1.5) 0 calc(#{$base-padding} * 2) 0;

    p {
      font-family: $primary-font;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      margin-bottom: $base-margin;
    }

    Button {
      margin: 0 auto;
    }
  }

  &__sidePanel {
    position: relative;
    z-index: 10000;
  }
}
</style>
