<template>
  <div class="c-FlowDetailsSection">
    <Card title="Pipeline" :count="workflowTemplateType">
      <div slot="header-subInfo" class="c-FlowDetailsSection__subInfo">
        <div
          id="msg-time-spent">
          Time spent: {{ messageDetails.timeSpent }}
          <Tooltip
            v-if="messageDetails.full_timeSpent"
            :message="`Time spent: ${messageDetails.full_timeSpent}`"
            :position="'bottom'"
            anchorName="msg-time-spent"/>
        </div>
        <div
          v-if="messageDetails.parsedDeadline"
          id="msg-deadline"
          v-html="deadlineLabel">
        </div>
      </div>
      <div
        slot="header-actions"
        class="c-FlowDetailsSection__cardActions">
        <Button
          class="c-FlowDetailsSection__traceBtn"
          label="View Details"
          button-type="is-outlined"
          data-test-id="msg-flow-details-btn"
          @click="toggleTraceInfoVisibility" />
        <Button
          v-show="retryPipelineVisibility"
          class="c-FlowDetailsSection__retryBtn"
          label="Retry"
          icon="refresh"
          button-style="is-alert"
          button-type="is-outlined"
          data-test-id="msg-action-retry"
          @click="handleActionClick({
            value: 'retry_pipeline'
          })" />
        <ActionMenu
          :menu-items="actionMenuItems"
          data-test-id="admin-action-menu"
          @menuClick="handleActionClick" />
      </div>
      <div
        slot="body"
        class="c-FlowDetailsSection__cardBody">
        <FlowDetails
          class="c-FlowDetailsSection__flow"
          :data="messageDetails"
          :modal-visibility="traceModalVisibility"
          @updateModalVisibility="toggleTraceInfoVisibility">
        </FlowDetails>
      </div>
    </Card>
    <Modal
      title="Flow Trace"
      :is-visible="traceModalVisibility"
      @toggleVisibility="toggleTraceInfoVisibility">
      <div class="c-FlowDetailsSection__modalContentHeader">
        <h6>
          <span>Network request</span>
          <a
            :href="generateControlpaneLink"
            rel="noopener noreferrer"
            target="_blank">
            <i></i>
          </a>
        </h6>
        <div>
          <button
            class="c-FlowDetailsSection__expandAll"
            type="button"
            @click="expandAll">
          </button>
          <button
            class="c-FlowDetailsSection__collapseAll"
            type="button"
            @click="collapseAll">
          </button>
          <button
            class="c-FlowDetailsSection__copy"
            type="button"
            @click="copyToClipboard">
            <span v-if="isCopied">Copied!</span>
          </button>
        </div>
      </div>
      <div
        ref="FlowDetailsSectionTrace"
        class="c-FlowDetailsSection__trace">
      </div>
      <p class="c-FlowDetailsSection__footerInfo">{{ traceInfo }}</p>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  OVERALL_STATUS,
  ARCH_STATUS,
  TEMPLATE_LABEL,
  MODULE,
  MIXPANEL_EVENTS
} from '@/data/enum'
import { Card, Button, Tooltip } from '@unbabel/ui'
import { copyToClipboard, trackEvent } from '@/utils/generalUtils'
import JSONFormatter from 'json-formatter-js'
import FlowDetails from '@/components/FlowDetails'
import ActionMenu from '@/components/ActionMenu'
import Modal from '@/components/Modal'

const DELIVERABLE_MT_STATUS = [
  OVERALL_STATUS.HT_START_STATUS,
  OVERALL_STATUS.HT_END_STATUS,
  OVERALL_STATUS.HT_SKIP_STATUS,
  OVERALL_STATUS.HT_TIMEOUT_STATUS,
  OVERALL_STATUS.HT_CANCEL_STATUS,
  OVERALL_STATUS.REBUILD_START_STATUS,
  OVERALL_STATUS.REBUILD_END_STATUS,
  OVERALL_STATUS.FAILED_STATUS]

const UNCANCELABLE_TRANSLATION_STATUS = [
  OVERALL_STATUS.HT_CANCEL_STATUS,
  OVERALL_STATUS.CANCELED_STATUS,
  OVERALL_STATUS.COMPLETED_STATUS]

export default {
  name: 'FlowDetailsSection',
  components: {
    Card,
    Button,
    Tooltip,
    ActionMenu,
    Modal,
    FlowDetails
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      traceModalVisibility: false,
      isCopied: false,
      jsonFormatter: undefined,
      defaultLevel: 2,
      traceInfo: 'All phases a translation went through in chronological order. If the translation has a “Reset” phase, consult the “phases_data” section and expand the “RESET” phase to see what happened to the translation before it was reset.'
    }
  },
  computed: {
    deadlineLabel () {
      if (!this.messageDetails) return ''

      const { deliveredAt, parsedDeadline, parsedDeadlineDiff, deadline } = this.messageDetails
      let out = `Deadline: ${parsedDeadline}`

      if (!deliveredAt) {
        if (new Date(deadline) < new Date()) {
          out += ' <span class="is-alert"> (Overdue) </span>'
        } else {
          out += ` (${parsedDeadlineDiff.diffElapsed})`
        }
      }

      return out
    },
    workflowTemplateType () {
      const template = this.messageDetails?.template_type

      if (!template) return ''

      return TEMPLATE_LABEL[template] ?? template
    },
    retryPipelineVisibility () {
      return this.messageDetails?.status === OVERALL_STATUS.FAILED_STATUS
    },
    actionMenuItems () {
      // eslint-disable-next-line camelcase
      const { status, phases, translation_profile } = this.messageDetails ?? {}
      const menuItems = []

      if (DELIVERABLE_MT_STATUS.includes(status) && phases?.find(item => item.name === MODULE.HT)) {
        menuItems.push({
          label: 'Deliver MT',
          value: 'deliver_mt',
          icon: 'send-email'
        })
      }

      if (!UNCANCELABLE_TRANSLATION_STATUS.includes(status)) {
        menuItems.push({
          label: 'Cancel translation',
          value: 'cancel_ht',
          icon: 'remove-circle'
        })
      }

      if (status !== OVERALL_STATUS.RESET_STATUS ||
        translation_profile?.architecture === ARCH_STATUS.CORE ||
        translation_profile?.architecture === ARCH_STATUS.CORE_FALLBACK) {
        menuItems.push({
          label: 'Reset Flow',
          value: 'reset_flow',
          icon: 'restart'
        })
      }

      return menuItems
    },
    generateControlpaneLink () {
      return `${process.env.VUE_APP_API_URL}/customerapi/v2/customers/${this.messageDetails?.customer?.username || 'CUSTOMER-USERNAME'}/translation_profiles/${this.messageDetails?.translation_profile?.id || 'TP-ID'}`
    }
  },
  methods: {
    toggleTraceInfoVisibility () {
      this.traceModalVisibility = !this.traceModalVisibility
      const area = this.$route.name
      trackEvent(MIXPANEL_EVENTS.FLOW_DETAILS, {
        area
      })
    },
    handleActionClick (item, index) {
      this.$emit('actionClick', item, index)
    },
    expandAll () {
      this.jsonFormatter.openAtDepth(20)
    },
    collapseAll () {
      this.jsonFormatter.openAtDepth(1)
    },
    copyToClipboard () {
      copyToClipboard(JSON.stringify(this.messageDetails.trace))
      this.isCopied = true

      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    }
  },
  watch: {
    messageDetails: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.phases && newVal.phases.length && newVal.trace) {
          // wait for DOM to update...
          Vue.nextTick().then(() => {
            // if there is a Trace JSON already displayed, remove it to render the new information
            if (this.$refs.FlowDetailsSectionTrace.firstChild) {
              this.$refs.FlowDetailsSectionTrace.removeChild(this.$refs.FlowDetailsSectionTrace.firstChild)
            }

            this.jsonFormatter = new JSONFormatter(newVal && newVal.trace, this.defaultLevel, {})
            this.$refs.FlowDetailsSectionTrace.appendChild(this.jsonFormatter.render())
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

::v-deep .c-Label__text {
  max-width: 100%;
  text-transform: none;
}

.c-FlowDetailsSection {
  &__subInfo{
    margin-top: $size--2;
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n900;

    ::v-deep .is-alert {
      color: $un-red-dark;
    }
  }

  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__modalContentHeader {
    height: 40px;
    padding: 0 $base-padding;
    background-color: $un-n100;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      display: flex;
      align-items: center;
      font-weight: 600;

      span {
        margin-right: $base-margin;
      }

      i {
        width: 24px;
        height: 11px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='11' viewBox='0 0 24 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.082 2.5C9.23575 1.54814 8.02364 1.00247 6.75 1H5.25C2.76472 1 0.75 3.01472 0.75 5.5C0.75 7.98528 2.76472 10 5.25 10H6.75C8.02353 9.99702 9.23544 9.45144 10.082 8.5' stroke='%23343941' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.918 2.5C14.7642 1.54803 15.9763 1.00234 17.25 1H18.75C21.2353 1 23.25 3.01472 23.25 5.5C23.25 7.98528 21.2353 10 18.75 10H17.25C15.9764 9.99728 14.7644 9.45165 13.918 8.5' stroke='%23343941' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.75 5.499H17.25' stroke='%23343941' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    > div {
      display: flex;

      button {
        padding: 0;
        margin: 0;
        background-color: none;
        border: none;
        margin-right: $base-margin;
        position: relative;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__expandAll::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='24' viewBox='0 0 12 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.75 12.48C11.75 11.8173 11.1904 11.28 10.5 11.28H1.5C0.809644 11.28 0.25 11.8173 0.25 12.48C0.25 13.1427 0.809644 13.68 1.5 13.68H10.5C11.1904 13.68 11.75 13.1427 11.75 12.48Z' fill='%23343941'/%3E%3Cpath d='M6.00006 15.599C5.66854 15.599 5.3506 15.7255 5.11618 15.9505C4.88176 16.1756 4.75006 16.4808 4.75006 16.799V18.959C4.75006 19.0916 4.63813 19.199 4.50006 19.199H3.00006C2.59566 19.1991 2.23113 19.433 2.07638 19.7917C1.92164 20.1504 2.00715 20.5632 2.29306 20.8378L5.29306 23.7178C5.68356 24.0925 6.31656 24.0925 6.70706 23.7178L9.70706 20.8378C9.99225 20.5631 10.0773 20.1507 9.92266 19.7925C9.76801 19.4342 9.40402 19.2005 9.00006 19.2H7.50006C7.36199 19.2 7.25006 19.0925 7.25006 18.96V16.8C7.25032 16.4816 7.11875 16.1761 6.8843 15.9508C6.64985 15.7256 6.33175 15.599 6.00006 15.599V15.599Z' fill='%23343941'/%3E%3Cpath d='M6.70709 1.24032C6.31659 0.865552 5.68358 0.865552 5.29308 1.24032L2.29308 4.12032C2.00646 4.39478 1.92049 4.80807 2.07533 5.16716C2.23016 5.52626 2.59525 5.7603 3.00008 5.76H4.50008C4.63816 5.76 4.75008 5.86745 4.75008 6V8.16C4.75008 8.82274 5.30973 9.36 6.00008 9.36C6.69044 9.36 7.25008 8.82274 7.25008 8.16V5.99904C7.25064 5.86687 7.3624 5.76 7.50008 5.76H9.00008C9.40448 5.75992 9.76902 5.52602 9.92376 5.16735C10.0785 4.80867 9.99299 4.39583 9.70709 4.12128L6.70709 1.24032Z' fill='%23343941'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }

  &__collapseAll::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 23.67C11.6903 23.67 12.25 23.1104 12.25 22.42V18.907C12.25 18.7689 12.3619 18.657 12.5 18.657H14.316C14.5179 18.6566 14.6997 18.5347 14.7769 18.3482C14.8541 18.1616 14.8115 17.947 14.669 17.804L11.354 14.487C11.155 14.3001 10.8449 14.3001 10.646 14.487L7.33097 17.8C7.18843 17.943 7.14587 18.1576 7.22307 18.3442C7.30027 18.5307 7.48208 18.6526 7.68397 18.653H9.49997C9.63804 18.653 9.74997 18.7649 9.74997 18.903V22.42C9.74997 23.1104 10.3096 23.67 11 23.67Z' fill='%23343941'/%3E%3Cpath d='M11 0.327999C10.3096 0.327999 9.74997 0.887643 9.74997 1.578V5.091C9.74997 5.22907 9.63804 5.341 9.49997 5.341H7.68397C7.48208 5.34144 7.30027 5.46326 7.22307 5.64981C7.14587 5.83635 7.18843 6.05102 7.33097 6.194L10.646 9.511C10.7398 9.60504 10.8671 9.65789 11 9.65789C11.1328 9.65789 11.2602 9.60504 11.354 9.511L14.669 6.194C14.8115 6.05102 14.8541 5.83635 14.7769 5.64981C14.6997 5.46326 14.5179 5.34144 14.316 5.341H12.5C12.3619 5.341 12.25 5.22907 12.25 5.091V1.578C12.25 1.24648 12.1183 0.928536 11.8839 0.694116C11.6494 0.459695 11.3315 0.327999 11 0.327999V0.327999Z' fill='%23343941'/%3E%3Cpath d='M0.75 12C0.750552 12.69 1.31003 13.249 2 13.249H20C20.6904 13.249 21.25 12.6894 21.25 11.999C21.25 11.3086 20.6904 10.749 20 10.749H2C1.66831 10.749 1.35021 10.8808 1.11576 11.1155C0.881314 11.3501 0.749735 11.6683 0.75 12Z' fill='%23343941'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }

  &__copy {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(#{$base-margin} / 2);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    position: relative;

    span {
      background-color: $un-n900;
      color: $un-white;
      border-radius: $border-radius;
      padding: calc(#{$base-padding} / 4) calc(#{$base-padding} / 2);
      text-align: left;
      font-family: $primary-font;
      font-size: 12px;
      line-height: 18px;
      white-space: nowrap;
      position: absolute;
      top: 30px;
      left: -15px;
    }
  }

  &__trace {
    background-color: $un-n50;
    padding: calc(#{$base-padding} * 1.5);
    height: calc(100% - 131px);
    overflow: auto;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }

  &__footerInfo {
    font-size: 14px;
    line-height: 24px;
    color: $un-n900;
  }
}
</style>
