<template>
  <header class="c-Header">
    <div
      class="c-Header__logoBlock"
      @click="trackClickEvent('logo')">
      <a :href="baseUrl">
        <i class="logo"></i>
        <p>X-Ray</p>
      </a>
    </div>
    <div class="c-Header__navBlock">
      <ul class="c-Header__nav">
        <li @click="trackClickEvent('search')">
          <router-link
            :to="{ name: 'Search' }"
            :class="{ active: isTranslationsActive }">Translations</router-link>
        </li>
        <li @click="trackClickEvent('changelog')">
          <router-link
            :to="{ name: 'Changelog' }"
            :class="{ active: isReleaseNotesActive }">Release Notes</router-link>
        </li>
      </ul>
      <div class="c-Header__actions">
        <div>
          <button
            type="button"
            :class="{ active: isHelpMenuActive }"
            @click="showMenu('help')">
            <p>
              <span>?</span>
            </p>
          </button>
          <Menu
            class="c-Header__menu"
            :items="helpMenuItems"
            :active="isHelpMenuActive"
            @menuClose="hideMenu('help')"
            @menuClick="handleMenuClick($event, 'help')" />
        </div>
        <div>
          <button
            type="button"
            :class="{ active: isUserMenuActive }"
            @click="showMenu('user')">
            <p>
              <span>{{ userInitials }}</span>
            </p>
          </button>
          <Menu
            class="c-Header__menu"
            :items="userMenuItems"
            :active="isUserMenuActive"
            @menuClose="hideMenu('user')"
            @menuClick="handleMenuClick($event, 'user')" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { Menu } from '@unbabel/ui'
import { trackEvent } from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'

export default {
  name: 'Header',
  components: {
    Menu
  },
  props: {
    user: {
      type: Object,
      required: false
    },
    isShortcutsModalVisible: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  data () {
    return {
      keysPressed: {},
      isHelpMenuActive: false,
      isUserMenuActive: false,
      helpMenuItems: [
        {
          label: 'Keyboard Shortcuts',
          labelShortcut: '⌘K',
          icon: ''
        },
        {
          label: 'Troubleshooting on X-Ray',
          divider: true,
          icon: 'external-hyperlink'
        },
        {
          label: 'Give us feedback',
          icon: 'external-hyperlink'
        }
      ]
    }
  },
  computed: {
    baseUrl () {
      return process.env.VUE_APP_E2E_APPLICATION_BASE_URL
    },
    isTranslationsActive () {
      return this.$route.name !== 'Changelog'
    },
    isReleaseNotesActive () {
      return this.$route.name === 'Changelog'
    },
    userMenuItems () {
      return [
        {
          label: this.user.name
        },
        {
          label: 'Logout',
          icon: 'logout'
        }
      ]
    },
    userInitials () {
      if (this.user.family_name && this.user.given_name) {
        return `${this.user.given_name.charAt(0)}${this.user.family_name.charAt(0)}`
      }

      return 'U'
    }
  },
  methods: {
    trackClickEvent (type) {
      trackEvent(MIXPANEL_EVENTS.HEADER_LINK, {
        type
      })
    },
    showMenu (menu) {
      if (menu === 'help') {
        this.isHelpMenuActive = true
      } else if (menu === 'user') {
        this.isUserMenuActive = true
      }
    },
    hideMenu (menu) {
      if (menu === 'help') {
        this.isHelpMenuActive = false
      } else if (menu === 'user') {
        this.isUserMenuActive = false
      }
    },
    handleMenuClick (item, menu) {
      if (menu === 'help') {
        this.isHelpMenuActive = false

        if (item.label === this.helpMenuItems[0].label) {
          trackEvent(MIXPANEL_EVENTS.HEADER_MENUS, {
            area: this.$route.name,
            menu: 'help-center',
            type: 'keyboard-shortcuts'
          })

          this.shortcutsModalVisibility(true)
        }
        if (item.label === this.helpMenuItems[1].label) {
          trackEvent(MIXPANEL_EVENTS.HEADER_MENUS, {
            area: this.$route.name,
            menu: 'help-center',
            type: 'troubleshooting'
          })

          // Troubleshooting on X-Ray
          window.open('https://docs.google.com/document/d/1NTRTXWGcHjmjHQ1eHLTSla6XgdTZEiXshJUBxLzcc4E/edit?usp=sharing', '_blank', 'noopener,noreferrer')
        }
        if (item.label === this.helpMenuItems[2].label) {
          trackEvent(MIXPANEL_EVENTS.HEADER_MENUS, {
            area: this.$route.name,
            menu: 'help-center',
            type: 'product-feedback'
          })

          // prod feedback
          window.open('https://forms.gle/T5GYZuUYNwf9GbgX6', '_blank', 'noopener,noreferrer')
        }
      } else if (menu === 'user') {
        this.isUserMenuActive = false

        if (item.label === this.userMenuItems[1].label) {
          trackEvent(MIXPANEL_EVENTS.HEADER_MENUS, {
            area: this.$route.name,
            menu: 'user-menu',
            type: 'logout'
          })

          this.logout()
        }
      }
    },
    shortcutsModalVisibility (val) {
      this.$emit('toggleShortcutsModal', val)
    },
    logout () {
      this.$keycloak.logout()
    },
    handleKeyDown (ev) {
      if (ev.metaKey && ev.code === 'KeyK' && !this.isShortcutsModalVisible) {
        ev.preventDefault()
        this.shortcutsModalVisibility(true)
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown, true)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown, true)
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-Header {
  background-color: $un-blue;
  width: 100%;
  height: $header-height;
  position: fixed;
  will-change: transform;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 10000;

  &__logoBlock {
    background-color: $un-blue-dark;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 $base-padding;
      outline: none;
    }

    .logo {
      margin-right: $base-margin;
      width: 26px;
      height: 18px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='28' viewBox='0 0 39 28'%3E%3Cpath fill='%23FFF' d='M3.322 16.438c0 5.202 4.285 9.418 9.57 9.418 1.743 0 3.378-.46 4.786-1.261-.391.055-.791.084-1.198.084-4.624 0-8.373-3.69-8.373-8.24V2.311H3.322v14.126zM1 16.431V0h9.5v16.431c0 1.297 1.063 2.348 2.375 2.348s2.375-1.051 2.375-2.348v-4.694C15.25 5.255 20.567 0 27.125 0 33.682 0 38.997 5.252 39 11.732v16.436h-9.5V11.737c0-1.297-1.063-2.348-2.375-2.348s-2.375 1.051-2.375 2.348v4.697c-.001 6.48-5.317 11.734-11.875 11.734S1 22.913 1 16.431zm21.321-.022v-4.7c0-4.54 3.722-8.222 8.312-8.222.404 0 .8.028 1.19.084-1.398-.8-3.021-1.259-4.752-1.259-5.246 0-9.5 4.208-9.5 9.398v4.699c0 2.595-2.126 4.699-4.749 4.699.992.737 2.226 1.174 3.562 1.174 3.278 0 5.935-2.627 5.937-5.87v-.003zm9.607-4.65v14.097h4.75V11.759c0-3.243-2.658-5.873-5.937-5.873-1.337 0-2.57.437-3.563 1.175 2.624 0 4.75 2.103 4.75 4.698z'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: cover;
    }

    p {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $un-white;
      white-space: nowrap;
    }
  }

  &__navBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(#{$base-padding} * 2) 0 $base-padding;
    width: 100%;
    height: 100%;
  }

  &__nav {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    li {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    a {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $un-white;
      padding: 0 $base-padding;
      height: 100%;
      display: flex;
      align-items: center;

      &.active {
        font-weight: 600;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > div {
      position: relative;
      margin: 0 0 0 calc(#{$base-margin} / 2);
      width: 40px;
      height: 40px;

      &:first-child span {
        font-size: 14px;
        font-weight: 700;
        color: $un-white;
      }

      &:last-child {
        button p {
          background-color: $un-white;
        }

        button span {
          font-family: $secondary-font;
          font-weight: 600;
          font-size: 11px;
          letter-spacing: 0.1px;
          color: $un-blue;
        }
      }
    }

    button {
      outline: none;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover, &.active {
        background-color: $un-blue-dark;
      }

      p {
        width: 21px;
        height: 21px;
        border-radius: 20px;
        border: 1px solid $un-white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 48px;
    right: 0px;
  }
}
</style>
