export const OVERALL_STATUS = {
  INIT_STATUS: 'INIT',
  RESET_STATUS: 'RESET',
  MT_FALLBACK_START_STATUS: 'MT_FALLBACK_START',
  MT_FALLBACK_END_STATUS: 'MT_FALLBACK_END',
  MT_HT_FALLBACK_START_STATUS: 'MT_HT_FALLBACK_START',
  MT_HT_FALLBACK_END_STATUS: 'MT_HT_FALLBACK_END',
  MT_START_STATUS: 'MT_START',
  MT_END_STATUS: 'MT_END',
  HT_START_STATUS: 'HT_START',
  HT_SKIP_STATUS: 'HT_SKIP',
  HT_TIMEOUT_STATUS: 'HT_TIMEOUT',
  HT_CANCEL_STATUS: 'HT_CANCEL',
  HT_PAUSE_STATUS: 'HT_PAUSE',
  HT_END_STATUS: 'HT_END',
  REBUILD_START_STATUS: 'REBUILD_START',
  REBUILD_END_STATUS: 'REBUILD_END',
  MARKUP_ALIGNER_START_STATUS: 'MARKUP_ALIGNER_START',
  MARKUP_ALIGNER_END_STATUS: 'MARKUP_ALIGNER_END',
  TM_UPDATE_START_STATUS: 'TM_UPDATE_START',
  TM_UPDATE_END_STATUS: 'TM_UPDATE_END',
  DELIVER_START_STATUS: 'DELIVER_START',
  DELIVER_END_STATUS: 'DELIVER_END',
  COMPLETED_STATUS: 'COMPLETED',
  FAILED_STATUS: 'FAILED',
  CANCELED_STATUS: 'CANCELED',
  UNKNOWN_STATUS: 'UNKNOWN'
}

export const TASK_TYPE = {
  MAP_REDUCE_PAID: 'map_reduce_paid',
  SEGMENT_CURATION: 'segment_curation',
  REVIEW: 'review',
  CLIENT_REVIEW: 'client_review'
}

export const TAG_PURPOSE = {
  OPEN: 'open',
  CLOSE: 'close',
  SELF_CLOSE: 'self_close'
}

export const ARCH_STATUS = {
  MAESTRO: 'maestro',
  CORE: 'core',
  CORE_FALLBACK: 'core_fallback'
}

export const STEP_STATUS = {
  INIT: 'init',
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
  TIMEDOUT: 'timedout',
  SKIPPED: 'skipped',
  CANCELLED: 'cancelled'
}

export const WORKFLOW_STATUS = {
  INIT: 'init',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

export const CONTENT_TYPE = {
  CHAT: 'chat',
  TICKET: 'ticket',
  FAQ: 'faqs'
}

export const TEMPLATE = {
  REALTIME_BASE: 'realtime_base',
  TOWER_MT: 'tower_mt',
  REALTIME_FUNCTION: 'realtime_function',
  REALTIME_CUSTOMIZED: 'realtime_customized',
  LITE_PEMT: 'lite_pemt',
  MID_PEMT: 'mid_pemt',
  FULL_PEMT: 'full_pemt',
  PROFESSIONAL_TRANSLATION: 'professional_translation',
  CREATIVE_TRANSLATION: 'creative_translation',

  REALTIME_BASE_CHATS: 'realtime_base_chats',
  REALTIME_BASE_EMAILS: 'realtime_base_emails',
  REALTIME_FUNCTION_CHATS: 'realtime_function_chats',
  REALTIME_CUSTOMIZED_CHATS: 'realtime_customized_chats',
  LITE_PEMT_EMAILS: 'lite_pemt_emails',
  MID_PEMT_EMAILS: 'mid_pemt_emails',

  MODULE_PRICING: 'module_pricing'
}

export const FIXED_TEMPLATES = {
  TOWER_MT: 'tower_mt',
  REALTIME_BASE: 'realtime_base',
  REALTIME_FUNCTION: 'realtime_function',
  REALTIME_CUSTOMIZED: 'realtime_customized',
  LITE_PEMT: 'lite_pemt',
  MID_PEMT: 'mid_pemt',
  FULL_PEMT: 'full_pemt',
  PROFESSIONAL_TRANSLATION: 'professional_translation',
  CREATIVE_TRANSLATION: 'creative_translation'
}

export const TEMPLATE_LABEL = {
  // Legacy pricing
  [TEMPLATE.REALTIME_BASE_CHATS]: 'Machine Standard',
  [TEMPLATE.REALTIME_BASE_EMAILS]: 'Machine Standard',
  [TEMPLATE.REALTIME_FUNCTION_CHATS]: 'Machine Domain',
  [TEMPLATE.REALTIME_CUSTOMIZED_CHATS]: 'Machine Customized',
  [TEMPLATE.LITE_PEMT_EMAILS]: 'Hybrid Lite',
  [TEMPLATE.MID_PEMT_EMAILS]: 'Hybrid Plus',

  // Fixed pricing
  [TEMPLATE.REALTIME_BASE]: 'Machine Standard',
  [TEMPLATE.REALTIME_FUNCTION]: 'Machine Domain',
  [TEMPLATE.REALTIME_CUSTOMIZED]: 'Machine Customized',
  [TEMPLATE.LITE_PEMT]: 'Hybrid Lite',
  [TEMPLATE.MID_PEMT]: 'Hybrid Plus',
  [TEMPLATE.FULL_PEMT]: 'Premium Review',
  [TEMPLATE.CREATIVE_TRANSLATION]: 'Premium Creative',
  [TEMPLATE.PROFESSIONAL_TRANSLATION]: 'Premium Professional',
  [TEMPLATE.TOWER_MT]: 'Tower MT',

  // Variable pricing
  [TEMPLATE.MODULE_PRICING]: 'Variable'
}

export const FILTERS_DICT = {
  UID: 'uid',
  TASK_ID: 'tskid',
  TRANSLATION_RECORD_ID: 'trid',
  EXTERNAL_ID: 'extid',
  THREAD_EXTERNAL_ID: 'thrextid',
  PROJECT_ID: 'pid',
  TEMPLATE: 'tmpl',
  STATUS: 'st',
  DATE_LESS_THAN: 'dlt',
  DATE_GREATER_OR_EQUAL_THAN: 'dgte',
  USERNAME: 'usrn',
  SOURCE_LANGUAGE: 'slg',
  TARGET_LANGUAGE: 'tlg',
  TEXT_FORMATS: 'tfmt',
  ORIGINS: 'orig',
  IS_PIVOTED: 'piv',
  ARCHITECTURES: 'arch',
  USECASE: 'usc',
  THREAD_ID: 'thrid',
  CANONICAL_NAME: 'cname',
  TRANSLATION_PROFILE_IDS: 'tpids',
  CONTENT_TYPE: 'ct',
  TRANSLATION_DIRECTION: 'td',
  OLDEST_FIRST: 'of', // not visible on interface
  BY_COMPLETION_DATE: 'cd', // not visible on interface
  LAST_PAGE: 'lpg', // not visible on interface
  LIMIT: 'lmt', // not visible on interface
  FROM_CREATED_DATE: 'fcd', // not visible on interface
  TO_CREATED_DATE: 'tcd', // not visible on interface
  NP_DATE_LESS_THAN: 'npdlt', // not visible on interface
  NP_DATE_GREATER_OR_EQUAL_THAN: 'npgte' // not visible on interface
}

export const GLOBAL_GETTERS = {
  USER: 'user',
  LOADING: 'loading',
  HT_TASKS_LOADING: 'htTasksLoading',
  NUGGETS_LOADING: 'nuggetsLoading',
  SEARCH_FILTERS: 'searchFilters',
  STATUS_SELECT_ITEMS: 'statusSelectItems',
  TEMPLATE_SELECT_ITEMS: 'templateSelectItems',
  TEXT_FORMAT_SELECT_ITEMS: 'textFormatSelectItems',
  ORIGIN_SELECT_ITEMS: 'originSelectItems',
  ARCH_SELECT_ITEMS: 'archSelectItems',
  PIVOTED_SELECT_ITEMS: 'pivotedSelectItems',
  GROUPED_INPUT_TEXT_SELECT_ITEMS: 'groupedInputTextSelectItems',
  CONTENT_TYPE_SELECT_ITEMS: 'contentTypeSelectItems',
  USECASE_SELECT_ITEMS: 'usecaseSelectItems',
  REFRESH_TIME: 'refreshTime',
  SOURCE_LANG_OPTIONS: 'sourceLangOptions',
  TARGET_LANG_OPTIONS: 'targetLangOptions',
  LIST_ITEM_INDEX: 'listItemSelectedIndex',
  LIST: 'list',
  MESSAGE_DETAILS: 'details',
  TASK_DETAILS: 'taskDetails',
  TRANSLATION_RECORD_DETAILS: 'translationRecordDetails'
}

export const GLOBAL_ACTIONS = {
  SET_LOADING: 'setLoading',
  SET_USER_INFO: 'setUserInfo',
  INIT_SEARCH_VIEW: 'initSearchView',
  INIT_MESSAGE_DETAILS_VIEW: 'initDetailsView',
  LOAD_MESSAGE_DETAILS_PREVIEW: 'loadMessageDetailsPreview',
  INIT_TASKS_VIEW: 'initTaskView',
  INIT_TRANSLATION_RECORD_VIEW: 'initTranslationRecordView',
  DO_SEARCH: 'doSearch',
  DOWNLOAD_SEARCH_RESULTS: 'downloadSearchResults',
  FETCH_BRAND_INFO: 'fetchBrandInformation',
  FETCH_CANONICAL_NAME_INFO: 'fetchCanonicalNameInformation',
  CANCEL_HT: 'cancelHumanTranslation',
  CHANGE_HT_PRIO: 'changeHumanTaskPriority',
  SEND_RECORD_TO_EVALUATION: 'sendTranslationRecordToEvaluation',
  SEND_TO_MARKUP_ALIGNER: 'sendToMarkupAligner',
  RESTART_PIPELINE: 'restartPipeline',
  RETRY_PIPELINE: 'retryPipeline',
  DELIVER_MT: 'deliverMachineTranslation',
  PAUSE_HT: 'pauseHumanTranslation',
  RESUME_HT: 'resumeHumanTranslation',
  SELECT_LIST_ITEM: 'selectListItem',
  CLEAR_SKIPS: 'clearSkips',
  FORCE_DEGRADATION: 'forceDegradation',
  ASSIGN_TASK: 'assignTask',
  REASSIGN_TASK: 'reassignTask',
  CANCEL_TASK: 'cancelTask'
}

export const GLOBAL_MUTATIONS = {
  UPDATE_USER: 'updateUser',
  UPDATE_LOADING: 'updateLoading',
  UPDATE_HT_TASKS_LOADING: 'updateHtTasksLoading',
  UPDATE_NUGGETS_LOADING: 'updateNuggetsLoading',
  UPDATE_SEARCH_FILTERS: 'updateSearchFilters',
  UPDATE_REFRESH_TIME: 'updateRefreshTime',
  UPDATE_LIST: 'updateList',
  APPEND_LIST: 'appendToList',
  UPDATE_MESSAGE_DETAILS: 'updateDetails',
  UPDATE_TASK_DETAILS: 'updateTaskDetails',
  UPDATE_TRANSLATION_RECORD_DETAILS: 'updateTranslationRecordDetails',
  UPDATE_LIST_ITEM_INDEX: 'updateListItemSelectedIndex'
}

export const MIXPANEL_EVENTS = {
  APP_INIT: 'Initializing Xray',
  DO_SEARCH: 'Search',
  REFRESH_SEARCH: 'Refresh search results',
  CLEAR_SEARCH: 'Clear a search',
  SIDEPANEL_TOP_MSG_DTLS: 'Message details opened with expand button',
  SIDEPANEL_BOTTOM_MSG_DTLS: 'Message details opened with "open detailed view"',
  ROWS_CHANGE: 'Items per page',
  HEADER_LINK: 'Header link',
  COPY_ID_BLOCK: 'ID copied',
  LINK_SIDEPANEL: 'Sidepanel page',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  CP_LINK: 'View on Portal',
  FLOW_DETAILS: 'Flow Details page',
  DETAILS_VIEW_CORE: 'View information in Core',
  DETAILS_VIEW_NUGGETS: 'Nuggets details page',
  ADMIN_ACTION: 'Admin actions',
  BREADCRUMBS_CLICK: 'Breadcrumb navigation',
  NUGGETS_LAYOUT_TOGGLE: 'Tab on nuggets details page',
  HOVER_LIST_DETAILS: 'Translation details hover',
  SEND_RECORD_TO_EVALUATION: 'Send Record to evaluation',
  DOWNLOAD_RAW_TEXT: 'Download raw text',
  DOWNLOAD_SEARCH_RESULTS: 'Download search results',
  POPOVER_OPEN: 'Open popover',
  LOAD_MORE: 'Load more',
  HEADER_MENUS: 'Header menus',
  ACTION_MENU_OPEN: 'Open action menu',
  EDITOR_PROFILE_LINK: 'Editor profile link',
  FULLSTORY_LINK: 'Fullstory link',
  CATEGORY_LINK: 'Category link',
  EDIT_PRIORITY: 'Edit task priority',
  SEND_TO_EVALUATION: 'Send to Evaluation',
  OPEN_TASK_CLICK: 'Task row click',
  OPEN_TR_CLICK: 'Translation Record row click',
  SIDEPANEL_ACTIONS_CLICK: 'Sidepanel main actions click'
}

export const BLOCKING_REASON = {
  ICE: 'ice_tm_match',
  EXACT: 'exact_tm_match',
  EXTERNAL_ICE: 'external_ice_tm_match',
  EXTERNAL_EXACT: 'external_exact_tm_match',
  EXTERNAL_FUZZY: 'external_fuzzy_tm_match',
  EXTERNAL_TRANSLATION: 'external_translation',
  QE: 'quality_level'
}

export const BLOCKING_BY = {
  TM: 'tm_match',
  ICE: 'ice_tm_match',
  QE: 'quality_level'
}

export const EXECUTION_STEPS = {
  MT: 'build_machine_translation_rebuild_quality_estimation',
  HT: 'human-translation',
  REBUILD: 'rebuild',
  DELIVERY: 'delivery',
  METERING: 'metering',
  TM_UPDATE: 'tm_update'
}

export const MODULE = {
  MT: 'BUILD_MACHINE_TRANSLATION_REBUILD_QUALITY_ESTIMATION',
  HT: 'HUMAN_TRANSLATION',
  REBUILD: 'REBUILD',
  DELIVERY: 'DELIVERY',
  METERING: 'METERING',
  TM_UPDATE: 'TM_UPDATE'
}

export const ELEMENT_TYPE = {
  SEPARATOR: 'separator'
}

export const FILE_PROCESSING_LABELS = {
  STATUS: 'processing status',
  JOB_ID: 'file_processing_job_id',
  CONFIG_ID: 'file_processing_config_id'
}

export const TASK_TYPE_LABEL = {
  post_edition: 'Post Edition',
  revision: 'Revision',
  client_review: 'Client Review'

}

export const HT_TASK_STATUS = {
  ENQUEUED: 'enqueued',
  DEQUEUED: 'dequeued'
}

export const SYSTEM_NAME = 'x-ray'
