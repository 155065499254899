import { languageOptions } from '@/data/languages'
import { statusOptions } from '@/data/status'
import { SEARCH_FILTERS_CONFIG } from '@/data/filters'
import { FILTERS_DICT, ARCH_STATUS, CONTENT_TYPE, FIXED_TEMPLATES, TEMPLATE_LABEL } from '@/data/enum'

// setting up Status select items
const statusSelectItems = Object.keys(statusOptions).reduce((filtered, key) => {
  if (statusOptions[`${key}`].type === 'overall') {
    filtered.push({
      label: statusOptions[`${key}`].label,
      class: statusOptions[`${key}`].class,
      value: key
    })
  }
  return filtered
}, [])

// setting up Template select items
const templateSelectItems = Object.values(FIXED_TEMPLATES).map(template => {
  return {
    label: TEMPLATE_LABEL[template],
    value: template
  }
})

// setting up Language (source & target) select items
const languageSelectItems = Object.keys(languageOptions).map(key => {
  return {
    label: languageOptions[`${key}`].label,
    value: key
  }
})

// setting up initial values for the Search Filters
const initialSearchFilters = {}
Object.keys(SEARCH_FILTERS_CONFIG).forEach(key => {
  initialSearchFilters[`${key}`] = SEARCH_FILTERS_CONFIG[`${key}`].value
})

export default {
  user: null,
  loading: false,
  htTasksLoading: false,
  nuggetsLoading: false,
  statusSelectItems,
  templateSelectItems,
  textFormatSelectItems: [
    {
      label: 'Text',
      value: 'text'
    },
    {
      label: 'HTML',
      value: 'html'
    },
    {
      label: 'Xliff',
      value: 'xliff'
    }
  ],
  originSelectItems: [
    {
      label: 'Conversation API',
      value: 'conversation_api'
    },
    {
      label: 'Freshchat',
      value: 'freshchat_int'
    },
    {
      label: 'Freshdesk',
      value: 'freshdesk_int'
    },
    {
      label: 'Helpshift Tickets',
      value: 'helpshift_int'
    },
    {
      label: 'Helpshift Chat',
      value: 'helpshift_chat_int'
    },
    {
      label: 'Intercom',
      value: 'intercom_int'
    },
    {
      label: 'Kustomer Chat',
      value: 'kustomer_chat_int'
    },
    {
      label: 'Kustomer Email',
      value: 'kustomer_email_int'
    },
    {
      label: 'Microsoft Dynamics',
      value: 'dynamics_int'
    },
    {
      label: 'Salesforce Chatter',
      value: 'salesforce_chatter_int'
    },
    {
      label: 'Salesforce Knowledge Base',
      value: 'salesforce_kb'
    },
    {
      label: 'Salesforce Live Agent',
      value: 'salesforce_la_int'
    },
    {
      label: 'Salesforce Service Cloud',
      value: 'salesforce_int'
    },
    {
      label: 'SiteCore',
      value: 'sitecore_int'
    },
    {
      label: 'Tickets Interface',
      value: 'ticket_clerk'
    },
    {
      label: 'Unbabel API',
      value: 'api'
    },
    {
      label: 'Web Order',
      value: 'web_order'
    },
    {
      label: 'Web Order File',
      value: 'web_order_file'
    },
    {
      label: 'WixAnswers',
      value: 'wixanswers_int'
    },
    {
      label: 'Zendesk Chat',
      value: 'zendesk_chat_int'
    },
    {
      label: 'Zendesk Guide',
      value: 'zendesk_faq_int'
    },
    {
      label: 'Zendesk Support',
      value: 'zendesk_int'
    },
    {
      label: 'Zoho Desk',
      value: 'zoho_int'
    },
    {
      label: 'Lingo24',
      value: 'lingo24_int'
    },
    {
      label: 'Project API',
      value: 'project_api_int'
    },
    {
      label: 'Projects App',
      value: 'projects_app_int'
    },
    {
      label: 'EmailFlow',
      value: 'emailflow_int'
    }
  ],
  architectureSelectItems: [
    {
      label: 'Core fallback',
      value: ARCH_STATUS.CORE_FALLBACK
    },
    {
      label: 'Maestro',
      value: ARCH_STATUS.MAESTRO
    }
  ],
  pivotedSelectItems: [
    {
      label: 'Only Pivoted translations',
      value: 'True'
    },
    {
      label: 'Not pivoted translations',
      value: 'False'
    }
  ],
  groupedInputTextSelectItems: [
    { label: 'UID', value: FILTERS_DICT.UID },
    { label: 'Thread ID', value: FILTERS_DICT.THREAD_ID },
    { label: 'Project ID', value: FILTERS_DICT.PROJECT_ID },
    { label: 'Ext. Msg. ID', value: FILTERS_DICT.EXTERNAL_ID },
    { label: 'Ext. Thread ID', value: FILTERS_DICT.THREAD_EXTERNAL_ID },
    { label: 'Task ID', value: FILTERS_DICT.TASK_ID },
    { label: 'T. Record ID', value: FILTERS_DICT.TRANSLATION_RECORD_ID }
  ],
  contentTypeSelectItems: [
    { label: 'Chat', value: CONTENT_TYPE.CHAT },
    { label: 'Tickets', value: CONTENT_TYPE.TICKET },
    { label: 'FAQ', value: CONTENT_TYPE.FAQ }
  ],
  usecaseSelectItems: [
    { label: 'Business standard', value: 'business_standard' },
    { label: 'Training', value: 'training' },
    { label: 'Retraining', value: 'retraining' },
    { label: 'Research', value: 'research' },
    { label: 'Linguistic', value: 'linguistic' },
    { label: 'Community', value: 'community' },
    { label: 'TM Curation', value: 'tm_curation' },
    { label: 'Unknown', value: 'unknown_usecase' }
  ],
  sourceLanguageOptions: languageSelectItems,
  targetLanguageOptions: languageSelectItems,
  searchFilters: initialSearchFilters,
  refreshTime: undefined,
  list: [],
  messageDetails: {},
  taskDetails: {},
  translationRecordDetails: {},
  listItemSelectedIndex: undefined
}
