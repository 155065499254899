<template>
  <div class="c-Diagram">
    <ul class="c-Diagram__items">
      <li
        v-for="(item, index) in data"
        :id="item.link"
        :key="index">
        <DiagramItem :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import DiagramItem from '@/components/DiagramItem.vue'

export default {
  name: 'Diagram',
  components: {
    DiagramItem
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-Diagram {
  width: 100%;
  height: 100%;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;

    & > li {
      position: relative;
      flex: 1;
      padding-left: calc(#{$base-padding} * 1.5);
      padding-bottom: calc(#{$base-padding} * 2);
      margin: 13px 0;
      border-left: 2px solid $un-blue;

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 24px;
        display: block;
        position: absolute;
        top: -26px;
        left: -15px;
        border: 2px solid $un-blue;
      }

      &::after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: $un-blue;
        display: block;
        position: absolute;
        top: -18px;
        left: -7px;
      }

      &:last-child {
        border-left: 2px solid transparent;
      }
    }
  }
}
</style>
