<template>
  <div class="c-MessageDetailsLayout">
    <TitleSection
      class="c-MessageDetailsLayout__titleSection"
      :message-details="messageDetails"
      :file-information="messageDetails.file_processing_information"
      area="messageDetails" />
    <FlowDetailsSection
      class="c-MessageDetailsLayout__flowDetails"
      :message-details="messageDetails"
      @actionClick="handleActionClick" />
    <MessageTranslationProfileSection
      class="c-MessageDetailsLayout__translationProfile"
      :message-details="messageDetails" />
    <MessageTranslationTextSection
      class="c-MessageDetailsLayout__translationText"
      :message-details="messageDetails" />
    <MtSection
      v-if="messageDetails.machine_translation_details && messageDetails.machine_translation_details[0]"
      class="c-MessageDetailsLayout__mt"
      :message-details="messageDetails"
      :step="1" />
    <MtSection
      v-if="messageDetails.machine_translation_details && messageDetails.machine_translation_details[1]"
      class="c-MessageDetailsLayout__mt"
      :message-details="messageDetails"
      :step="2" />
    <HtSection
      v-if="messageDetails.human_translation_details &&
      messageDetails.human_translation_details.uid"
      class="c-MessageDetailsLayout__ht"
      :message-details="messageDetails" />
    <ConfirmationModal
      :visibility="presentingConfirmationModal"
      :data="confirmModalData"
    />
  </div>
</template>

<script>
import {
  GLOBAL_ACTIONS,
  MIXPANEL_EVENTS
} from '@/data/enum'
import {
  showNotification,
  parseRequestError,
  trackEvent
} from '@/utils/generalUtils'
import TitleSection from '@/components/TitleSection'
import FlowDetailsSection from '@/components/FlowDetailsSection'
import MessageTranslationProfileSection from '@/components/MessageTranslationProfileSection'
import MessageTranslationTextSection from '@/components/MessageTranslationTextSection'
import MtSection from '@/components/MtSection'
import HtSection from '@/components/HtSection'
import ConfirmationModal from '@/components/ConfirmationModal'

export default {
  name: 'MessageDetailsLayout',
  components: {
    TitleSection,
    FlowDetailsSection,
    MessageTranslationProfileSection,
    MessageTranslationTextSection,
    ConfirmationModal,
    MtSection,
    HtSection
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      presentingConfirmationModal: false,
      confirmModalData: {}
    }
  },
  methods: {
    unsetModal () {
      this.presentingConfirmationModal = false
      this.confirmModalData = {}
    },
    setModal (payload) {
      const { callback, confirmText, rejectText, confirmStyle, ...props } = payload
      this.confirmModalData = {
        ...props,
        actionLeft: {
          label: rejectText,
          buttonType: 'is-text',
          buttonStyle: 'is-primary',
          handler: this.unsetModal
        },
        actionRight: {
          label: confirmText,
          buttonType: 'is-contained',
          buttonStyle: confirmStyle,
          handler: callback
        }
      }
      this.presentingConfirmationModal = true
    },
    handleActionClick (item) {
      const modalTitle = 'Are you sure?'
      let modalText = ['This action is <span class="text--bold">not reversible</span>.']
      let confirmStyle = 'is-primary'
      let confirmText = 'Confirm'
      let rejectText = 'Cancel'
      let callback = null

      switch (item.value) {
        case 'retry_pipeline':
          modalText.unshift('Are you sure you want to retry this pipeline?')
          confirmText = 'Retry'
          confirmStyle = 'is-alert'
          callback = () => {
            this.retryPipeline()
            this.trackAction('retryPipeline')
          }
          break
        case 'reset_flow':
          modalText.unshift('Are you sure you want to reset this pipeline?')
          confirmText = 'Reset'
          confirmStyle = 'is-alert'
          callback = () => {
            this.resetFlow()
            this.trackAction('restartPipeline')
          }
          break
        case 'deliver_mt':
          modalText.unshift('Are you sure you want to deliver the current MT?')
          confirmText = 'Deliver'
          callback = () => {
            this.deliverMT()
            this.trackAction('deliverMT')
          }
          break
        case 'pause_ht':
          modalText = ['Are you sure you want to pause this translation?']
          confirmText = 'Pause'
          callback = () => {
            this.pauseHT()
            this.trackAction('pauseHT')
          }
          break
        case 'resume_ht':
          modalText = ['Are you sure you want to resume this translation?']
          confirmText = 'Resume'
          callback = () => {
            this.resumeHT()
            this.trackAction('resumeHT')
          }
          break
        case 'cancel_ht':
          modalText = ['Are you sure you want to cancel this translation?']
          confirmText = 'Yes'
          rejectText = 'No'
          confirmStyle = 'is-alert'
          callback = () => {
            this.cancelHT()
            this.trackAction('cancelHT')
          }
          break
        case 'markup_aligner':
          this.sendToMarkupAligner()
          this.trackAction('sendToMarkupAligner')
          break
      }

      if (callback !== null) {
        this.setModal({
          title: modalTitle,
          text: modalText,
          confirmStyle,
          confirmText,
          rejectText,
          callback
        })
      }

      return { type: item.value, callback }
    },
    trackAction (type) {
      trackEvent(MIXPANEL_EVENTS.ADMIN_ACTION, {
        type
      })
    },
    sendToMarkupAligner () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.SEND_TO_MARKUP_ALIGNER, payload).then((res) => {
        showNotification(this, 'is-added', `Translation ${this.messageDetails.uid} was successfully sent to Markup Aligner`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error sending to Markup Aligner on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    resetFlow () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.RESTART_PIPELINE, payload).then((res) => {
        showNotification(this, 'is-added', `Pipeline for translation ${this.messageDetails.uid} was successfully reset`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error resetting pipeline on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    retryPipeline () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.RETRY_PIPELINE, payload).then((res) => {
        showNotification(this, 'is-added', `Pipeline for translation ${this.messageDetails.uid} was successfully retried`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error retrying pipeline on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    deliverMT () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.DELIVER_MT, payload).then((res) => {
        showNotification(this, 'is-added', `Delivery of MT for translation ${this.messageDetails.uid} was successful`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error delivering MT on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    pauseHT () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.PAUSE_HT, payload).then((res) => {
        showNotification(this, 'is-added', `Pause of HT for translation ${this.messageDetails.uid} was successful`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error pausing HT on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    resumeHT () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.RESUME_HT, payload).then((res) => {
        showNotification(this, 'is-added', `Resume of HT for translation ${this.messageDetails.uid} was successful`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error resuming HT on translation ${this.messageDetails.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    },
    cancelHT () {
      const payload = {
        uid: this.messageDetails.uid,
        content_type: this.messageDetails.translation_profile && this.messageDetails.translation_profile.content_type
      }

      this.$store.dispatch(GLOBAL_ACTIONS.CANCEL_HT, payload).then((res) => {
        showNotification(this, 'is-added', `Translation ${this.messageDetails.human_translation_details.uid} was successfully cancelled`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error cancelling Human Translation for translation ${this.messageDetails.human_translation_details.uid} :: ${msg}`)
      }).finally(() => {
        this.presentingConfirmationModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-MessageDetailsLayout {
  &__titleSection {
    margin-bottom: $base-margin;
  }
  &__flowDetails, &__translationProfile, &__translationText, &__mt, &__ht {
    margin-bottom: $base-margin;
  }
}
</style>
