<template>
  <div class="l-TaskDetails">
    <TitleSection
      class="l-TaskDetails__titleSection"
      :message-details="messageDetails"
      :task-details="taskDetails"
      area="taskDetails" />
    <div class="l-TaskDetails__topBlock">
      <TaskTranslationProfileSection
        class="l-TaskDetails__translationProfile"
        :message-details="messageDetails" />
      <QueueSection
        class="l-TaskDetails__queue"
        :task-details="taskDetails" />
    </div>
    <TarkinNuggetsSection
      v-if="taskDetails && taskDetails.source_nuggets && taskDetails.source_nuggets.length"
      class="l-TaskDetails__translationText"
      :source-nuggets="taskDetails.source_nuggets"
      :target-nuggets="taskDetails.target_nuggets"
      :language="taskDetails.target_language"
      :task-type="taskDetails.type"
      :display-target="targetNuggetsVisibility"
      area="taskDetails" />
    <TranslationRecordListSection
      class="l-TaskDetails__tasks"
      :task-details="taskDetails"
      :message-details="messageDetails" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GLOBAL_GETTERS, GLOBAL_ACTIONS } from '@/data/enum'
import TitleSection from '@/components/TitleSection'
import TaskTranslationProfileSection from '@/components/TaskTranslationProfileSection'
import QueueSection from '@/components/QueueSection'
import TarkinNuggetsSection from '@/components/TarkinNuggetsSection'
import TranslationRecordListSection from '@/components/TranslationRecordListSection'
import { showNotification, parseRequestError } from '@/utils/generalUtils'

export default {
  name: 'Details',
  components: {
    TitleSection,
    TaskTranslationProfileSection,
    QueueSection,
    TarkinNuggetsSection,
    TranslationRecordListSection
  },
  computed: {
    ...mapGetters({
      messageDetails: GLOBAL_GETTERS.MESSAGE_DETAILS,
      taskDetails: GLOBAL_GETTERS.TASK_DETAILS
    }),
    targetNuggetsVisibility () {
      let visibility = false

      if (this.taskDetails?.translation_records?.length) {
        visibility = this.taskDetails?.translation_records.some(item => item.action === 'submit')
      }
      return {
        visibility,
        reason: visibility ? null : 'No Translation Record submitted yet...'
      }
    }
  },
  beforeMount () {
    const payload = {
      uid: this.$route.params.messageId,
      threadId: this.$route.params.threadId,
      taskId: this.$route.params.taskId
    }

    this.$store.dispatch(GLOBAL_ACTIONS.INIT_TASKS_VIEW, payload).catch(err => {
      const msg = parseRequestError(err)
      showNotification(this, 'is-alert', `Error initializing Task Details :: ${msg}`)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.l-TaskDetails {
  &__titleSection {
    margin-bottom: calc(#{$base-margin} * 1.5);
  }
  &__topBlock {
    display: flex;
    margin-bottom: $base-margin;
  }
  &__translationProfile {
    width: calc(66.6% - calc(#{$base-margin} / 2));
    margin-right: $base-margin;
  }
  &__queue {
    width: calc(33.3% - calc(#{$base-margin} / 2));
  }
  &__translationText {
    margin-bottom: $base-margin;
  }
}
</style>
