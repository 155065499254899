<template>
  <Card
      class="c-MtSection"
      :title="title"
      :data-test-id="`msg-mt-title${step}`">
      <div
        slot="body"
        class="c-MtSection__cardBody">
        <CardBodyLayout>
          <tr colspan="4">
            <td>
              <p>Languages</p>
              <p :data-test-id="`msg-mt-languages${step}`">{{ languages }}</p>
            </td>
            <td>
              <p>Job engine</p>
              <p :data-test-id="`msg-mt-job-engine${step}`">{{ jobEngine }}</p>
            </td>
            <td>
              <p>Model name</p>
              <p :data-test-id="`msg-mt-model-name${step}`">{{ modelName }}</p>
            </td>
            <td>
              <p>Version</p>
              <p :data-test-id="`msg-mt-model-version${step}`">{{ modelVersion }}</p>
            </td>
            <td class="c-MtSection__qe">
              <p>
                <span>QE score</span>
                <Tooltip
                  class="tooltip"
                  message="Score / Threshold" />
              </p>
              <p
                v-if="qeThreshold > 0"
                :data-test-id="`msg-mt-qe-score${step}`">
                <span :class="overThresholdClass">{{ qeScore }}</span>
                <span>/</span>
                <span>
                  {{ qeThreshold }}
                </span>
              </p>
              <p v-else>---</p>
            </td>
          </tr>
        </CardBodyLayout>
      </div>
    </Card>
</template>

<script>
import { languageOptions } from '@/data/languages'
import { Card, Tooltip } from '@unbabel/ui'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'MtSection',
  components: {
    Card,
    Tooltip,
    CardBodyLayout
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    },
    step: {
      type: Number,
      required: true,
      validator (stepVal) {
        return [
          1,
          2
        ].includes(stepVal)
      }
    }
  },
  computed: {
    title () {
      if (this.messageDetails?.translation_profile?.pivoted) {
        return `Machine Translation - Step ${this.step}`
      }

      return 'Machine Translation'
    },
    languages () {
      if (this.messageDetails?.translation_profile?.source_language &&
      this.messageDetails?.translation_profile?.target_language) {
        const source = this.fetchLangObject('source_language')
        const target = this.fetchLangObject('target_language')

        if (this.messageDetails?.translation_profile?.pivoted && this.step === 1) {
          return source ? `${source.label} > English` : `${source.label} > en`
        } else if (this.messageDetails?.translation_profile?.pivoted && this.step === 2) {
          return target.label ? `English > ${target.label}` : `en > ${target.label}`
        }

        return (source.label && target.label) ? `${source.label} > ${target.label}` : `${this.messageDetails?.translation_profile?.source_language} > ${this.messageDetails?.translation_profile?.target_language}`
      }

      return '---'
    },
    jobEngine () {
      return this.messageDetails?.machine_translation_details[this.step - 1].job_engine || '---'
    },
    modelName () {
      return this.messageDetails?.machine_translation_details[this.step - 1].job_engine_model_name || '---'
    },
    modelVersion () {
      return this.messageDetails?.machine_translation_details[this.step - 1].parsed_job_engine_model_version ||
      this.messageDetails?.machine_translation_details[this.step - 1].job_engine_model_version || '---'
    },
    qeScore () {
      const qeScore = parseFloat(this.messageDetails?.machine_translation_details[this.step - 1].qe_score)
      return qeScore ? Math.round((qeScore + Number.EPSILON) * 1000) / 1000 : qeScore === 0 ? 0 : '--- '
    },
    qeThreshold () {
      const qeThreshold = parseFloat(this.messageDetails?.machine_translation_details[this.step - 1].qe_threshold)
      return qeThreshold ? Math.round((qeThreshold + Number.EPSILON) * 1000) / 1000 : null
    },
    overThresholdClass () {
      return {
        overThreshold: (this.qeScore >= 0 && this.qeThreshold >= 0 && this.qeScore >= this.qeThreshold) || false,
        belowThreshold: (this.qeThreshold >= 0 && this.qeScore < this.qeThreshold) || false
      }
    }
  },
  methods: {
    fetchLangObject (key) {
      if (this.messageDetails.translation_profile && this.messageDetails.translation_profile[`${key}`]) {
        return languageOptions[`${this.messageDetails.translation_profile[`${key}`]}`]
      }

      return undefined
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-MtSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__qe {
    p:first-child {
      display: flex;
      margin-bottom: -1px !important;

      .tooltip {
        margin: -2px 0 0 calc(#{$base-margin} / 2);
      }
    }

    .overThreshold {
      color: $un-green-dark;
      font-weight: 600;
    }

    .belowThreshold {
      color: $un-red-dark;
      font-weight: 600;
    }
  }
}
</style>
