<template>
  <div
    v-if="id"
    :id="randomElemId"
    class="c-IdCopyBlock"
    :class="classObject"
    @click="copyText(id)">
    <i
     v-show="id"
     class="c-IdCopyBlock__icon"
     :class="icon"></i>
    <span class="c-IdCopyBlock__label">{{ label }}</span>
    <span class="c-IdCopyBlock__id">{{ truncateId }}</span>
    <Tooltip
      :message="tooltipText"
      :anchorName="randomElemId"></Tooltip>
  </div>
</template>

<script>
import {
  copyToClipboard,
  truncateString,
  trackEvent
} from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'
import { Tooltip } from '@unbabel/ui'

export default {
  name: 'IdCopyBlock',
  components: {
    Tooltip
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: '',
      validator (type) {
        return [
          'uid',
          'chatId',
          'projectId'
        ].includes(type)
      }
    },
    // To refactor this component once the task details redesign has been developed
    isNewVersion: {
      type: Boolean,
      required: false,
      default: () => false
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    id: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      isCopied: false,
      randomElemId: `idcopyblock${Math.floor(Math.random() * 100000000000000000000)}`
    }
  },
  computed: {
    truncateId () {
      return truncateString(this.id, 6)
    },
    tooltipText () {
      if (this.isCopied) {
        return 'Copied!'
      }

      return this.id
    },
    classObject () {
      return {
        isNewVersion: this.isNewVersion,
        copyActive: this.id
      }
    }
  },
  methods: {
    copyText () {
      const area = this.$route.name
      let type

      // TODO: This needs to come from the filters config and the labels should be included there.
      // Changes on this component and MessageDetailsLayout
      switch (this.label) {
        case 'UID':
          type = 'uid'
          break
        case 'Thread ID':
          type = 'thread_id'
          break
        case 'Ext. Msg. ID':
          type = 'external_id'
          break
        case 'Ext. Thread ID':
          type = 'thread_external_id'
          break
        case 'Project ID':
          type = 'pid'
          break
        case 'ID':
          type = 'task_id'
          break
      }

      if (this.id && this.id.length && !this.isCopied) {
        trackEvent(MIXPANEL_EVENTS.COPY_ID_BLOCK, {
          area,
          type,
          content: this.id
        })

        this.isCopied = true
        copyToClipboard(this.id)

        setTimeout(() => {
          this.isCopied = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-IdCopyBlock {
  display: flex;
  height: 50px;
  padding: 0 calc(#{$base-padding} * 0.5);
  align-items: center;
  position: relative;

  &.copyActive:hover {
    cursor: pointer;
    background-color: $un-blue-light;

    .c-IdCopyBlock__label, .c-IdCopyBlock__id {
      color: $un-blue;
    }

    .c-IdCopyBlock__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E") !important;
    }
  }

  &__label, &__id {
    font-family: $primary-font;
    font-size: 10px;
    line-height: 1.31;
    letter-spacing: 0.29px;
    color: $un-n900;
  }

  &__label {
    margin-right: $base-margin;
  }

  &__icon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(#{$base-margin} / 2);

    &.uid {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M2.625 21.992c-.167 0-.324-.065-.442-.183-.187-.187-.236-.472-.123-.71l2.424-5.09c-.81-1.417-1.237-3.024-1.235-4.66.008-5.15 4.204-9.34 9.355-9.34h.029c1.749 0 3.46.488 4.946 1.411C19.7 4.74 21.182 6.81 21.75 9.25c.569 2.44.156 4.95-1.163 7.072-1.32 2.125-3.39 3.608-5.827 4.177-.706.165-1.428.248-2.145.248-1.619 0-3.217-.428-4.632-1.238l-5.09 2.424c-.084.04-.177.06-.27.06zm9.98-18.735c-4.464 0-8.1 3.631-8.107 8.094-.002 1.52.422 3.005 1.224 4.295.114.183.126.406.034.599l-1.81 3.803 3.801-1.811c.084-.04.176-.061.269-.061.116 0 .23.032.33.095 1.29.802 2.767 1.226 4.27 1.226.622 0 1.247-.072 1.86-.215 2.11-.493 3.904-1.778 5.05-3.62 2.364-3.802 1.194-8.818-2.608-11.18-1.288-.802-2.77-1.225-4.286-1.225h-.028z'/%3E%3C/svg%3E%0A");
    }

    &.chatId {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M16.406 8.395c1.283 0 2.489.5 3.396 1.406.845.846 1.337 1.953 1.398 3.139l.006.255v.001c-.002 1.71-.933 3.295-2.4 4.142v3.657c0 .243-.146.462-.37.555-.074.03-.152.045-.23.045-.156 0-.31-.06-.424-.175l-3.424-3.425h-.352c-2.646 0-4.8-2.153-4.8-4.8s2.154-4.8 4.8-4.8h2.4zm.002 1.2h-2.402c-1.985 0-3.6 1.615-3.6 3.6s1.615 3.6 3.6 3.6h.6c.16 0 .312.064.424.176l2.576 2.576v-2.571c0-.232.134-.443.344-.542 1.247-.59 2.054-1.861 2.056-3.24 0-.96-.374-1.865-1.053-2.544-.68-.68-1.583-1.055-2.545-1.055zM12.2 2.4c.992 0 1.8.807 1.8 1.8v2.4c0 .331-.269.6-.6.6-.331 0-.6-.269-.6-.6V4.2c0-.33-.27-.6-.6-.6H3.8c-.33 0-.6.27-.6.6v6c0 .33.27.6.6.6H5c.331 0 .6.269.6.6v2.152l1.376-1.376c.234-.235.614-.235.848 0 .235.234.235.614 0 .848l-2.4 2.4c-.115.115-.268.176-.424.176-.077 0-.155-.015-.23-.045-.224-.093-.37-.312-.37-.555v-3h-.6c-.993 0-1.8-.807-1.8-1.8v-6c0-.993.807-1.8 1.8-1.8h8.4z'/%3E%3C/svg%3E%0A");
    }

    &.projectId{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E") !important;
    }
  }

  &.isNewVersion {
    .c-IdCopyBlock__icon {
      &.uid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%2359616E' fill-rule='evenodd' d='M2.625 21.992c-.167 0-.324-.065-.442-.183-.187-.187-.236-.472-.123-.71l2.424-5.09c-.81-1.417-1.237-3.024-1.235-4.66.008-5.15 4.204-9.34 9.355-9.34h.029c1.749 0 3.46.488 4.946 1.411C19.7 4.74 21.182 6.81 21.75 9.25c.569 2.44.156 4.95-1.163 7.072-1.32 2.125-3.39 3.608-5.827 4.177-.706.165-1.428.248-2.145.248-1.619 0-3.217-.428-4.632-1.238l-5.09 2.424c-.084.04-.177.06-.27.06zm9.98-18.735c-4.464 0-8.1 3.631-8.107 8.094-.002 1.52.422 3.005 1.224 4.295.114.183.126.406.034.599l-1.81 3.803 3.801-1.811c.084-.04.176-.061.269-.061.116 0 .23.032.33.095 1.29.802 2.767 1.226 4.27 1.226.622 0 1.247-.072 1.86-.215 2.11-.493 3.904-1.778 5.05-3.62 2.364-3.802 1.194-8.818-2.608-11.18-1.288-.802-2.77-1.225-4.286-1.225h-.028z'/%3E%3C/svg%3E%0A");
      }

      &.chatId {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%2359616E' fill-rule='evenodd' d='M16.406 8.395c1.283 0 2.489.5 3.396 1.406.845.846 1.337 1.953 1.398 3.139l.006.255v.001c-.002 1.71-.933 3.295-2.4 4.142v3.657c0 .243-.146.462-.37.555-.074.03-.152.045-.23.045-.156 0-.31-.06-.424-.175l-3.424-3.425h-.352c-2.646 0-4.8-2.153-4.8-4.8s2.154-4.8 4.8-4.8h2.4zm.002 1.2h-2.402c-1.985 0-3.6 1.615-3.6 3.6s1.615 3.6 3.6 3.6h.6c.16 0 .312.064.424.176l2.576 2.576v-2.571c0-.232.134-.443.344-.542 1.247-.59 2.054-1.861 2.056-3.24 0-.96-.374-1.865-1.053-2.544-.68-.68-1.583-1.055-2.545-1.055zM12.2 2.4c.992 0 1.8.807 1.8 1.8v2.4c0 .331-.269.6-.6.6-.331 0-.6-.269-.6-.6V4.2c0-.33-.27-.6-.6-.6H3.8c-.33 0-.6.27-.6.6v6c0 .33.27.6.6.6H5c.331 0 .6.269.6.6v2.152l1.376-1.376c.234-.235.614-.235.848 0 .235.234.235.614 0 .848l-2.4 2.4c-.115.115-.268.176-.424.176-.077 0-.155-.015-.23-.045-.224-.093-.37-.312-.37-.555v-3h-.6c-.993 0-1.8-.807-1.8-1.8v-6c0-.993.807-1.8 1.8-1.8h8.4z'/%3E%3C/svg%3E%0A");
      }

      &.projectId{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E") !important;
      }
    }

    .c-IdCopyBlock__label, .c-IdCopyBlock__id {
      color: $un-n700;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
