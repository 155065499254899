<template>
  <div
    ref="mainElem"
    class="c-OldDateTimePicker"
    :class="OlddateTimePickerClassObj">
    <VueCtkDateTimePicker
      :id="datePickerId"
      v-model="model"
      :label="label"
      :no-keyboard="disabled"
      :disabled="disabled"
      :only-time="onlyTime"
      :only-date="onlyDate"
      :format="inputFormat"
      :formatted="fieldFormat"
      :output-format="outputFormat"
      :min-date="minDate"
      :max-date="maxDate"
      :no-clear-button="!hasClearButton"
      color="#3843D0"
      @input="handleInput">
    </VueCtkDateTimePicker>
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { randomizeId } from '@/utils/generalUtils'

export default {
  name: 'OldDateTimePicker',
  components: {
    VueCtkDateTimePicker
  },
  props: {
    /**
     * The OldDateTimePicker data field
     */
    data: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * The OldDateTimePicker label
     */
    label: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * The OldDateTimePicker input format (momentJS style)
     */
    inputFormat: {
      type: String,
      required: false,
      default: 'YYYY-MM-DDTHH:mm:ss.SSSZ'
    },
    /**
     * The OldDateTimePicker display format (momentJS style)
     */
    fieldFormat: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD HH:mm'
    },
    /**
     * The OldDateTimePicker output format (momentJS style)
     */
    outputFormat: {
      type: String,
      required: false,
      default: 'YYYY-MM-DDTHH:mm:ss'
    },
    /**
     * If the OldDateTimePicker should be for dates only
     * @values true, false
     */
    onlyDate: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * If the OldDateTimePicker should be for time only
     * @values true, false
     */
    onlyTime: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The OldDateTimePicker MIN date (use the same format as fieldFormat)
     */
    minDate: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * The OldDateTimePicker MAX date (use the same format as fieldFormat)
     */
    maxDate: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * If the OldDateTimePicker is in an active state
     * @values true, false
     */
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * If the OldDateTimePicker is disabled
     * @values true, false
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * If the OldDateTimePicker display's the clear button
     * @values true, false
     */
    hasClearButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      datePickerId: randomizeId('datepickerid'),
      model: undefined
    }
  },
  computed: {
    OlddateTimePickerClassObj () {
      return {
        disabled: this.disabled,
        isActive: this.isActive
      }
    }
  },
  watch: {
    data: {
      handler (newVal) {
        this.model = newVal
      },
      immediate: true
    }
  },
  mounted () {
    this.$refs.mainElem.addEventListener('focus', this.handleFocus, true)
  },
  beforeDestroy () {
    this.$refs.mainElem.removeEventListener('focus', this.handleFocus, true)
  },
  methods: {
    handleInput (val) {
      /**
       * Triggers when OldDateTimePicker changes it's value
       * @event change
       * @property {string} val new value set
       */
      this.$emit('change', val)
    },
    handleFocus (ev) {
      if (this.disabled) {
        ev.preventDefault()
        ev.stopPropagation()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/variables.scss";

.c-OldDateTimePicker {
  $font-size-value: 16px;
  position: relative;

  &.disabled {

    .field-input {
      border-color: $un-n500 !important;
      background-color: $un-n100 !important;
      color: $un-n500 !important;
      cursor: not-allowed !important;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::placeholder {
        color: $un-n500 !important;
      }
    }

    label {
      color: $un-n500 !important;
    }
  }

  &.isActive {
    .date-time-picker {

      .field .field-input {
        border-color: $un-blue;
        box-shadow: inset 0 0 0 1px $un-blue;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &::placeholder {
          color: $un-blue;
          opacity: 1;
        }
      }

      label {
        color: $un-blue;
      }
    }
  }

  .date-time-picker {
    height: 50px;
    font-family: $primary-font !important;

    .field {
      height: 100%;

      &.is-focused .field-input {
        box-shadow: inset 0 0 0 1px $un-blue;
      }
    }

    .field-input {
      height: 100%;
      border: 1px solid $un-gray-light;
      border-radius: $border-radius;
      background-color: $un-white;
      font-family: $primary-font;
      font-size: $font-size-value;
      line-height: $font-size-value;
      color: $un-n700;
      box-sizing: border-box;
      padding-top: 4px;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::placeholder {
        color: $un-n700;
        opacity: 1;
      }
    }

    label {
      color: $un-n700;
      font-family: $primary-font;
      top: 8px;
    }
  }
}
</style>
