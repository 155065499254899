<template>
  <Card
      class="c-QueueSection"
      title="Queue">
      <div
        v-if="taskDetails.category_id"
        slot="header-actions"
        class="c-QueueSection__cardActions">
        <a
          :href="tarkinCategoryUrl"
          target="_blank"
          rel="noopener noreferrer">
          <Button
            label="Category"
            button-type="is-outlined"
            icon="external-hyperlink"
            @click="trackAction" />
        </a>
      </div>
      <div
        slot="body"
        class="c-QueueSection__cardBody">
        <CardBodyLayout>
          <tr colspan="2">
            <td>
              <p>
                <span>Deadline</span>
                <Tooltip
                  class="tooltip"
                  message="Time Spent / SLA (can be 0)" />
              </p>
              <p>
                <span :class="exceededSla">
                  {{ taskDetails.time_elapsed ? taskDetails.time_elapsed : taskDetails.time_taken }}
                </span>
                <span>/</span>
                <span>
                  {{ taskDetails.parsed_timeout_seconds }}
                </span>
              </p>
            </td>
            <td>
              <p>Priority</p>
              <p data-test-id="msg-task-priority">{{ taskDetails.priority || '---' }}</p>
            </td>
          </tr>
          <tr colspan="2">
            <td>
              <p>
                <span>Expertise Level</span>
                <Tooltip
                  class="tooltip"
                  message="How the expertise level will change over time" />
              </p>
              <p data-test-id="msg-task-expertise">
                <span>{{ taskDetails.expertise_level !== null ? taskDetails.expertise_level : 'No level set' }}</span>
                <span v-if="taskDetails.expertise_level !== null && taskDetails.time_to_degredation">
                  ({{ taskDetails.time_to_degredation }} to degradation)
                </span>
              </p>
            </td>
          </tr>
        </CardBodyLayout>
      </div>
    </Card>
</template>

<script>
import { Card, Button, Tooltip } from '@unbabel/ui'
import { trackEvent } from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'QueueSection',
  components: {
    Card,
    Button,
    Tooltip,
    CardBodyLayout
  },
  props: {
    taskDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    tarkinCategoryUrl () {
      return `${process.env.VUE_APP_TARKIN_BASE_URL}/category/${this.taskDetails.category_id}`
    },
    exceededSla () {
      return {
        exceeded: this.taskDetails.exceeded_sla
      }
    }
  },
  methods: {
    trackAction () {
      trackEvent(MIXPANEL_EVENTS.CATEGORY_LINK, {
        area: this.$route.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-QueueSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  td p {
    display: flex;

    .tooltip {
      margin: -2px 0 0 calc(#{$base-margin} / 2);
    }
  }

  .exceeded {
    color: $un-red-dark;
    font-weight: 600;
  }
}
</style>
