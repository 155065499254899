<template>
  <div>
    <Card
      class="c-HtSection"
      :title="`Human translation tasks (${parsedTasks.length})`">
      <div
        slot="header-actions"
        class="c-HtSection__cardActions">
        <Button
          v-show="editConditions && editPriorityVisibility"
          label="Cancel editing"
          button-type="is-text"
          icon="pencil"
          data-test-id="msg-cancel-edit-priority"
          @click="toggleEditPriority">
        </Button>
        <Button
          v-show="editConditions && !editPriorityVisibility"
          label="Edit priority"
          button-type="is-outlined"
          icon="pencil"
          data-test-id="msg-toggle-edit-priority"
          @click="toggleEditPriority">
        </Button>
        <InputModal
          v-show="editPriorityVisibility && messageDetails.human_translation_details.maxPriority >= 0"
          class="c-HtSection__editModal"
          title="Editing priority"
          @reset="resetPriorityValue"
          @save="changePriority">
          <NumberPicker
            label="Priority"
            :data="overallPriority"
            data-test-id="msg-priority-input"
            @change="updateTasksPriority">
          </NumberPicker>
        </InputModal>
        <ActionMenu
          v-if="messageDetails.customer && messageDetails.customer.canonical_name"
          :menu-items="actionMenuItems"
          data-test-id="task-action-menu"
          @menuClick="handleActionClick" />
      </div>
      <div
        slot="body"
        class="c-HtSection__cardBody">
        <p
          v-if="htTasksLoading"
          class="c-HtSection__emptyState">
          <span>Loading Tasks from Tarkin... Please wait</span>
        </p>
        <p
          v-else-if="tasksLoadingError"
          class="c-HtSection__emptyState">
          <span>An error occurred while loading tasks from Tarkin... Please try again</span>
        </p>
        <div
          v-else-if="parsedTasks.length"
          class="c-HtSection__tableContainer">
          <div class="c-HtSection__tableContent">
            <table
              class="c-HtSection__table">
              <thead>
              <tr>
                <th v-show="editPriorityVisibility">
                  <Checkbox
                    :label="''"
                    iconStyle="minus"
                    :data="bulkEditPriorityValue"
                    data-test-id="select-all-edit-prio"
                    @change="handleCheckboxChange(true, $event)"></Checkbox>
                </th>
                <th>Id</th>
                <th>Type</th>
                <th>Priority</th>
                <th>Edit Distance</th>
                <th>Words</th>
                <th>Skips</th>
                <th>Status</th>
                <th>Editors</th>
                <th>Devices</th>
                <th class="c-HtSection__datesHeader">Created / Completed</th>
                <th>Deadline</th>
                <th />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in paginatedPages"
                  :key="index"
                  :data-test-id="`msg-ht-row${index}`"
                  @click="linkToTask($event, item)">
                <td
                  v-show="editPriorityVisibility"
                  class="c-HtSection__checkbox">
                  <Checkbox
                    class="checkboxClassIdentifier"
                    :label="''"
                    :data="item.isChecked"
                    :data-test-id="`msg-ht-edit-prio${index}`"
                    @change="handleCheckboxChange(false, $event, index)"></Checkbox>
                </td>
                <td
                  :id="item.id"
                  class="c-HtSection__id"
                  @click="copyText(item.id, index, $event)">
                  <span :data-test-id="`msg-ht-id${index}`">{{ truncateId(item.id) }}</span>
                  <i class="c-HtSection__copyIcon"></i>
                  <Tooltip
                    :message="item.isIdCopied ? 'Copied!' : item.id"
                    :anchorName="item.id"></Tooltip>
                </td>
                <td
                  class="c-HtSection__type"
                  :data-test-id="`msg-ht-type${index}`">{{ item.parsed_type || item.type }}</td>
                <td
                  class="c-HtSection__priority"
                  :data-test-id="`msg-ht-priority${index}`">{{ item.priority }}</td>
                <td
                  class="c-HtSection__editDistance"
                  :data-test-id="`msg-ht-edit-distance${index}`">{{ item.task_edit_distance }}</td>
                <td
                  class="c-HtSection__words"
                  :data-test-id="`msg-ht-words${index}`">{{ item.num_words || 0 }}</td>
                <td
                  class="c-HtSection__skips"
                  :data-test-id="`msg-ht-skips${index}`">{{ item.task_skipped_count }}</td>
                <td class="c-HtSection__status">
                  <StatusChip
                    :class="item.task_status"
                    :status="item.task_status"
                    :data-test-id="`msg-ht-status${index}`"></StatusChip>
                </td>
                <td
                  class="c-HtSection__editors"
                  :data-test-id="`msg-ht-editors${index}`">{{ (item.translation_records && item.translation_records.length) || '---' }}</td>
                <td
                  class="c-HtSection__devices"
                  :data-test-id="`msg-ht-devices${index}`">{{ item.parsed_devices || '---' }}</td>
                <td
                  class="c-HtSection__dates"
                  :class="taskDatesClassObj(item)">
                  <p :id="`taskCreatedAt${index}`">
                    {{ item.parsed_created_at || '---' }}
                    <Tooltip
                      v-if="item.parsed_created_at && item.utc_parsed_created_at"
                      :message="item.utc_parsed_created_at"
                      :position="'top'"
                      :anchorName="`taskCreatedAt${index}`"></Tooltip>
                  </p>
                  <p
                    v-if="item.row_parsed_completed_at"
                    :id="`taskCompletedAt${index}`">
                    {{ item.row_parsed_completed_at }}
                    <Tooltip
                      v-if="item.utc_parsed_completed_at"
                      :message="item.utc_parsed_completed_at"
                      :position="'top'"
                      :anchorName="`taskCompletedAt${index}`"></Tooltip>
                  </p>
                </td>
                <td class="c-HtSection__timeSpent">
                  <p :id="`taskDeadline${index}`">
                    <span>
                      <span :class="exceededSlaClass(item)">
                        {{ item.time_elapsed ? item.time_elapsed : item.time_taken }}
                      </span>
                      <span>/</span>
                      <span>
                        {{ item.parsed_timeout_seconds }}
                      </span>
                    </span>
                    <Tooltip
                      message="Time Spent / SLA (can be 0)"
                      position="top"
                      :anchorName="`taskDeadline${index}`"></Tooltip>
                  </p>
                </td>
                <!--            we don't want menu click events to bubble and trigger row click handler-->
                <td @click.stop="">
                  <ActionMenu
                    :menu-items="getTaskActionMenuItems(item)"
                    data-test-id="task-action-menu"
                    @menuClick="$event => $event.action(item)"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            v-if="totalPages > 1"
            :currentPage="currPage"
            :paginationItems="paginationItems"
            :totalPages="totalPages"
            @changePage="handleChangePage" />
        </div>
        <p
          v-else
          class="c-HtSection__emptyState">
          <span>No tasks have been created yet</span>
          <Tooltip
            :textOverflowEllipsis="false"
            message="This data is coming directly from Tarkin, if Tasks do not appear here, they are not in the queue." />
        </p>
      </div>
    </Card>
    <ConfirmationModal
      :visibility="confirmDegradationModalVisibility"
      :data="confirmDegradationModalData"
    />
    <Modal
      class="c-AssignModal"
      :is-open="showAssignEditorModal"
      title="Assign to the specific member"
    >
      <template #body>
        <InputText
          label="username"
          :data="assignToUsername"
          @input="$event => assignToUsername = $event"
          @change="$event => assignToUsername = $event"
        />
      </template>
      <template #footer>
        <div class="c-AssignModal__footer">
          <Button
            label="Cancel"
            button-type="is-text"
            @click="closeAssignTaskModal"
          />
          <Button
            label="Assign"
            button-style="is-primary"
            :disabled="!assignToUsername"
            @click="assignTaskToEditor"
          />
        </div>
      </template>
    </Modal>
    <ConfirmationModal
      :visibility="shouldShowCancelReviewConfirmationModal"
      :data="cancelReviewModalData"
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  GLOBAL_GETTERS,
  GLOBAL_ACTIONS,
  OVERALL_STATUS,
  MIXPANEL_EVENTS,
  TASK_TYPE,
  HT_TASK_STATUS
} from '@/data/enum'
import {
  generateCPLinks,
  showNotification,
  parseRequestError,
  truncateString,
  copyToClipboard,
  trackEvent
} from '@/utils/generalUtils'
import ActionMenu from '@/components/ActionMenu'
import ConfirmationModal from '@/components/ConfirmationModal'
import InputModal from '@/components/InputModal'
import Pagination from '@/components/Pagination'
import StatusChip from '@/components/StatusChip'
import {
  Card,
  Button,
  Checkbox,
  NumberPicker,
  Tooltip,
  Modal,
  InputText
} from '@unbabel/ui'

export default {
  name: 'HtSection',
  components: {
    ConfirmationModal,
    Card,
    Button,
    InputModal,
    StatusChip,
    NumberPicker,
    Tooltip,
    ActionMenu,
    Checkbox,
    Pagination,
    Modal,
    InputText
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      parsedTasks: [],
      actionMenuItems: [
        {
          label: 'Editor instructions',
          value: 'editor_instructions',
          icon: 'external-hyperlink'
        }
      ],
      taskActionMenuItems: [
        {
          label: 'Clear skips',
          value: 'clear_skips',
          action: item => this.handleClearSkips(item.id)
        },
        {
          label: 'Force degradation',
          value: 'force_degradation',
          action: item => this.handleForceDegradation({ id: item.id, categoryId: item.category_id })
        },
        {
          label: 'Assign to ...',
          value: 'task_assign',
          action: item => this.handleAssignTask(item.id)
        },
        {
          label: 'Cancel',
          value: 'cancel',
          action: item => this.showCancelReviewConfirmationModal(item.id)
        }
      ],
      activeItem: {
        id: null,
        categoryId: null
      },
      confirmDegradationModalVisibility: false,
      showAssignEditorModal: false,
      editPriorityVisibility: false,
      overallPriority: undefined,
      currPage: 0,
      paginationItems: 30,
      assignToUsername: '',
      cancelTaskId: '',
      shouldShowCancelReviewConfirmationModal: false,
      cancelReviewModalData: {
        title: 'Cancel client review',
        text: ['The task will be delivered right after the last community human translation step, without the client review.'],
        actionLeft: {
          label: 'Cancel',
          buttonStyle: 'is-primary',
          buttonType: 'is-text',
          handler: () => {
            this.shouldShowCancelReviewConfirmationModal = false
          }
        },
        actionRight: {
          label: 'Confirm',
          buttonStyle: 'is-primary',
          buttonType: 'is-contained',
          handler: () => {
            this.shouldShowCancelReviewConfirmationModal = false
            this.handleCancelTask(this.cancelTaskId)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      htTasksLoading: GLOBAL_GETTERS.HT_TASKS_LOADING,
      user: GLOBAL_GETTERS.USER
    }),
    totalPages () {
      if (this.parsedTasks && this.parsedTasks.length) {
        return Math.ceil(this.parsedTasks.length / this.paginationItems)
      }
      return 0
    },
    paginatedPages () {
      return this.parsedTasks.slice(this.currPage * this.paginationItems, (this.currPage + 1) * this.paginationItems)
    },
    bulkEditPriorityValue () {
      return this.parsedTasks.some(item => item.isChecked)
    },
    tasksLoadingError () {
      return this.messageDetails?.human_translation_details?.tasksLoadingError
    },
    editConditions () {
      return (this.messageDetails?.status !== OVERALL_STATUS.HT_CANCEL_STATUS &&
        this.messageDetails?.status !== OVERALL_STATUS.COMPLETED_STATUS &&
        this.messageDetails?.human_translation_details?.tasks &&
        this.messageDetails?.human_translation_details?.tasks.length &&
        !this.messageDetails?.human_translation_details?.tasks.every(item => item.task_status === HT_TASK_STATUS.DEQUEUED))
    },
    confirmDegradationModalData () {
      return {
        title: 'Force task degradation',
        text: [
          'By forcing task degradation you will push the task in the general pool of translators.',
            `<a
                href="${process.env.VUE_APP_TARKIN_BASE_URL}/category/${this.activeItem?.categoryId || ''}"
                target="_blank"
            >
                See task details on Tarkin
            </a>`
        ],
        actionLeft: {
          label: 'Cancel',
          buttonType: 'is-text',
          buttonStyle: 'is-primary',
          handler: this.closeDegradeConfirmationModal
        },
        actionRight: {
          label: 'Confirm',
          buttonType: 'is-contained',
          buttonStyle: 'is-primary',
          handler: this.confirmForceDegradation
        }
      }
    }
  },
  methods: {
    handleChangePage (newPage) {
      this.currPage = newPage
    },
    handleCheckboxChange (isBulkChange, val, index) {
      if (isBulkChange) {
        this.parsedTasks.forEach((item, i) => {
          this.parsedTasks[i].isChecked = val
        })
      } else {
        this.parsedTasks[index].isChecked = val
      }

      this.parsedTasks = Object.assign([], this.parsedTasks)
    },
    linkToTask (ev, item) {
      const parentClassName = (ev.target && ev.target.parentNode && ev.target.parentNode.className) || undefined
      if (!parentClassName || (parentClassName &&
        parentClassName.indexOf('c-Checkbox__label') === -1 &&
        parentClassName.indexOf('checkboxClassIdentifier') === -1)) {
        trackEvent(MIXPANEL_EVENTS.OPEN_TASK_CLICK, {
          area: this.$route.name
        })

        this.$router.push({
          name: 'TaskDetails',
          params: {
            conversationId: this.messageDetails.thread_id || 'THREAD_ID',
            messageId: this.messageDetails.uid || 'MESSAGE_ID',
            taskId: item.id
          }
        })
      }
    },
    exceededSlaClass (item) {
      return {
        exceeded: item.exceeded_sla
      }
    },
    copyText (id, index, ev) {
      ev.stopPropagation()

      if (!this.messageDetails.human_translation_details.tasks[index].isIdCopied) {
        this.messageDetails.human_translation_details.tasks[index].isIdCopied = true
        copyToClipboard(id)

        setTimeout(() => {
          this.messageDetails.human_translation_details.tasks[index].isIdCopied = false
        }, 1000)
      }
    },
    truncateId (id) {
      return truncateString(id, 6, true)
    },
    taskDatesClassObj (item) {
      return {
        'bottom-right': item.parsed_created_at && item.parsed_completed_at
      }
    },
    handleActionClick (item) {
      window.open(`${generateCPLinks(this.messageDetails, 'translation_profile')}`, '_blank', 'noopener,noreferrer')
    },
    toggleEditPriority () {
      this.editPriorityVisibility = !this.editPriorityVisibility

      if (!this.editPriorityVisibility) {
        this.parsedTasks.forEach((item, i) => {
          this.parsedTasks[i].isChecked = false
        })
        this.parsedTasks = Object.assign([], this.parsedTasks)
      }
    },
    updateTasksPriority (val) {
      this.overallPriority = val
    },
    resetPriorityValue () {
      this.overallPriority = this.messageDetails.human_translation_details.maxPriority
    },
    changePriority () {
      this.editPriorityVisibility = false

      const taskIds = this.parsedTasks.reduce((filtered, item) => {
        if (item.isChecked) {
          filtered.push(item.id)
        }
        return filtered
      }, [])

      if (taskIds.length) {
        trackEvent(MIXPANEL_EVENTS.EDIT_PRIORITY, {
          area: this.$route.name,
          uid: this.messageDetails.human_translation_details.uid,
          tasks: taskIds,
          priority: this.overallPriority
        })

        const payload = {
          uid: this.messageDetails.human_translation_details.uid,
          content_type: this.messageDetails.human_translation_details.content_type,
          data: {
            task_ids: taskIds,
            priority: this.overallPriority
          }
        }

        this.$store.dispatch(GLOBAL_ACTIONS.CHANGE_HT_PRIO, payload).then((res) => {
          showNotification(this, 'is-added', `Priority on translation ${this.messageDetails.human_translation_details.uid} was successfully changed to ${this.overallPriority}`)
        }, err => {
          const msg = parseRequestError(err)
          showNotification(this, 'is-alert', `Error changing priority on translation ${this.messageDetails.human_translation_details.uid} :: ${msg}`)
        })
      }
    },
    handleClearSkips (taskId) {
      const payload = {
        task_id: taskId,
        actor: this.user.email
      }
      this.$store.dispatch(GLOBAL_ACTIONS.CLEAR_SKIPS, payload).then((res) => {
        showNotification(this, 'is-added', `Skips on task ${taskId} were successfully cleared`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error clearing skips on task ${taskId} :: ${msg}`)
      })
    },
    handleForceDegradation ({ id, categoryId }) {
      Object.assign(this.activeItem, { id, categoryId })
      this.confirmDegradationModalVisibility = true
    },
    async confirmForceDegradation () {
      const payload = {
        taskId: this.activeItem.id,
        actor: this.user.email
      }
      await this.$store.dispatch(GLOBAL_ACTIONS.FORCE_DEGRADATION, payload).then((res) => {
        showNotification(this, 'is-added', `Task ${this.activeItem.id} was force degraded`)
      }, err => {
        showNotification(this, 'is-alert', err)
      })
      this.closeDegradeConfirmationModal()
    },
    closeDegradeConfirmationModal () {
      Object.assign(this.activeItem, { id: null, categoryId: null })
      this.confirmDegradationModalVisibility = false
    },
    handleAssignTask (taskId) {
      this.activeItem.id = taskId
      this.showAssignEditorModal = true
    },
    showCancelReviewConfirmationModal (taskId) {
      this.cancelTaskId = taskId
      this.shouldShowCancelReviewConfirmationModal = true
    },
    handleCancelTask (taskId) {
      const payload = {
        task_ids: [taskId],
        actor: this.user.email,
        system: 'xray',
        // needed to do the page refresh call
        uid: this.messageDetails.uid
      }
      this.$store.dispatch(GLOBAL_ACTIONS.CANCEL_TASK, payload).then(() => {
        showNotification(this, 'is-added', `Task ${taskId} was successfully skipped`)
      }, err => {
        const msg = parseRequestError(err)
        showNotification(this, 'is-alert', `Error skipping task ${taskId} :: ${msg}`)
      })
    },
    closeAssignTaskModal () {
      this.activeItem.id = null
      this.assignToUsername = ''
      this.showAssignEditorModal = false
    },
    async assignTaskToEditor () {
      await this.$store.dispatch(GLOBAL_ACTIONS.ASSIGN_TASK, {
        taskId: this.activeItem.id,
        editorName: this.assignToUsername,
        actor: this.user.email
      }).then((res) => {
        showNotification(this, 'is-added', `Task ${this.activeItem.id} was assigned to ${this.assignToUsername}`)
      }, err => {
        showNotification(this, 'is-alert', err)
      })
      this.closeAssignTaskModal()
    },
    getTaskActionMenuItems (item) {
      return this.taskActionMenuItems.filter(menuItem => {
        if (item.type === TASK_TYPE.CLIENT_REVIEW && item.task_status === HT_TASK_STATUS.ENQUEUED) {
          return true
        }
        return menuItem.value !== 'cancel'
      })
    }
  },
  watch: {
    messageDetails: {
      handler (msgDetails) {
        if (msgDetails?.human_translation_details?.maxPriority >= 0) {
          this.overallPriority = msgDetails?.human_translation_details?.maxPriority
        }

        this.parsedTasks = msgDetails?.human_translation_details?.tasks?.sort((a, b) => {
          if (a.type === TASK_TYPE.REVIEW || b.type === TASK_TYPE.REVIEW) {
            return b.type === TASK_TYPE.REVIEW ? 1 : -1
          }
          return Date.parse(a.created_at) - Date.parse(b.created_at)
        }) || []
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-HtSection {
  &__cardActions {
    display: flex;
    position: relative;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__editModal {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, 100%);
    z-index: 1001;
  }

  &__tableContainer {
    width: 100%;
  }
  &__tableContent {
    width: 100%;
    overflow-x: auto;
  }

  &__table {
    width: 100%;
    border-spacing: 0;

    th,
    td {
      padding: 0 0 0 calc(#{$base-padding} * 1.5);
      text-align: left;
      vertical-align: middle;
      height: 72px;
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;
    }

    tbody tr:hover {
      background-color: $un-n50;
      cursor: pointer;
    }

    tbody tr:last-child  {
      box-shadow: none;
    }

    th:last-child, td:last-child  {
      padding-right: calc(#{$base-padding} * 1.5) !important;
    }

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.21px;
      text-align: left;
    }

    td:first-child  {
      font-weight: 600;
    }

    .c-Tooltip__messageContainer {
      margin-left: 40px !important;
    }
  }

  &__dates, &__datesHeader {
    text-align: right !important;

    p:nth-child(2) {
      margin-top: calc(#{$base-margin / 2});
    }
  }

  &__id {
    &:hover {
      cursor: pointer;

      span {
        padding-right: 0;
      }

      .c-HtSection__copyIcon {
        display: block;
      }
    }

    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;

    span {
      padding-right: 30px;
    }
  }

  &__copyIcon {
    display: none;
    margin-left: 6px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
  }

  &__number, &__status, &__dates, &__timeSpent {
    white-space: nowrap;
  }

  &__emptyState {
    display: flex;
    justify-content: center;
    padding: $base-padding;
    width: 100%;

    > span {
      font-family: $primary-font;
      font-style: italic;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: $un-n700;
      margin-right: $base-margin;
    }
  }

  .exceeded {
    color: $un-red-dark;
    font-weight: 600;
  }
}
.c-AssignModal {
  min-width: 600px;
  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $base-margin;
    ::v-deep .c-Button {
      margin-left: $base-margin;
    }
  }
}
</style>
