<template>
  <div
    ref="popover"
    class="c-Popover"
    :class="positionClass">
    <button
      class="c-Popover__triggerBtn"
      :class="{ isActive: visibility }"
      @click="togglePopover"
      @mouseenter="handleMouseHover(true)"
      @mouseleave="handleMouseHover(false)">
      <Icon
        icon="expand-more"
        :icon-style="(visibility || isHovered) ? 'is-un-n900' : 'is-un-n700'"></Icon>
    </button>
    <div
      v-show="visibility"
      class="c-Popover__content">
      <table>
        <tr
          v-for="(item, index) in dataItems"
          :key="index"
          :class="['c-Popover__item', {'separator': isSeparator(item)}]">
          <td v-if="isSeparator(item)" colspan="2" class="separator__space"></td>
          <template v-else>
            <td :data-test-id="`popover-label${index}`" class="c-Popover__label">{{ item.label }}</td>
            <td class="c-Popover__value">
              <span
                :data-test-id="`popover-value${index}`">{{ item.value }}</span>
              <button
                v-if="item.canCopy && item.value !== '---'"
                type="button"
                class="c-Popover__copy"
                @click="handleCopy(item, index)">
                <i
                  v-show="!item.isIdCopied"
                  class="c-Popover__copyIcon" />
                <Icon
                  v-show="item.isIdCopied"
                  icon="check-alternate"
                  :width="20"
                  :height="20"
                  icon-style="is-dark-gray" />
              </button>
            </td>
          </template>
        </tr>
      </table>
      <div class="c-Popover__button">
        <Button
          v-if="appSmithLink.show"
          label="File processing back office"
          button-type="is-text"
          icon="external-hyperlink"
          @click="redirectToExternalLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Icon } from '@unbabel/ui'
import {
  copyToClipboard,
  trackEvent
} from '@/utils/generalUtils'
import { MIXPANEL_EVENTS, ELEMENT_TYPE } from '@/data/enum'

export default {
  name: 'Popover',
  components: {
    Button,
    Icon
  },
  props: {
    data: {
      type: Array,
      required: true,
      validator: (data) => {
        const conditions = []
        data.forEach(item => {
          conditions.push(Object.keys(item).indexOf('label'))
          conditions.push(Object.keys(item).indexOf('value'))
        })

        return Object.keys(data).length === 0 || conditions.every(cond => cond > -1)
      }
    },
    hPosition: {
      type: String,
      required: false,
      default: () => 'right',
      validator (pos) {
        return [
          'right',
          'left'
        ].includes(pos)
      }
    },
    appSmithLink: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    }
  },
  data () {
    return {
      visibility: false,
      isHovered: false,
      dataItems: [],
      area: null
    }
  },
  methods: {
    togglePopover () {
      this.visibility = !this.visibility

      if (this.visibility) {
        trackEvent(MIXPANEL_EVENTS.POPOVER_OPEN, {
          area: this.$route.name,
          type: this.$refs.popover.getAttribute('data-test-id') // used also for the e2e tests
        })
      }
    },
    onClickOutside (event) {
      const { popover } = this.$refs
      if (!popover || popover.contains(event.target)) {
        return
      }
      this.visibility = false
    },
    handleMouseHover (val) {
      this.isHovered = val
    },
    handleCopy (item, index) {
      if (!this.dataItems[index].isIdCopied) {
        const area = this.$route.name
        trackEvent(MIXPANEL_EVENTS.COPY_ID_BLOCK, {
          area,
          type: item.type || null,
          content: item.value
        })

        this.dataItems[index].isIdCopied = true
        copyToClipboard(item.value)
        setTimeout(() => {
          this.dataItems[index].isIdCopied = false
        }, 1000)
      }
    },
    redirectToExternalLink () {
      window.location.href = this.appSmithLink.link
    },
    isSeparator (row) {
      return row.value === ELEMENT_TYPE.SEPARATOR
    }
  },
  computed: {
    positionClass () {
      return {
        left: this.hPosition === 'left',
        right: this.hPosition === 'right'
      }
    }
  },
  watch: {
    data: {
      handler (val) {
        if (val && val.length) {
          this.dataItems = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-Popover {
  position: relative;
  z-index: 1002;

  &.left {
    .c-Popover__content {
      left: 6px;
    }
  }

  &.right {
    .c-Popover__content {
      right: 6px;
    }
  }

  button {
    background-color: transparent;
    outline: 0;
    margin: 0;
    padding: 0 calc(#{$base-padding} / 2);
    cursor: pointer;
    border: 0;
  }

  &__triggerBtn {
    width: 40px;
    height: 40px;
    border-radius: 40px;

    &:hover, &.isActive {
      background-color: $un-blue-light;
    }
  }

  &__content {
    background-color: $un-white;
    box-shadow: 0px 3px 6px rgba(174, 185, 203, 0.23), 0px 3px 6px rgba(174, 185, 203, 0.16);
    border-radius: $border-radius;
    padding: $base-padding calc(#{$size--7-half} / 2) calc(#{$base-padding} / 2) calc(#{$size--7-half} / 2);
    position: absolute;
    top: 48px;

    td {
      font-family: $primary-font;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 0 calc(#{$base-padding} / 2) calc(#{$base-padding} / 2) calc(#{$base-padding} / 2);
      white-space: nowrap;

      &:first-child {
        color: $un-n700;
        text-align: right;
      }
      &:last-child {
        color: $un-gray-bg;
        text-align: left;
        display: flex;
        vertical-align: middle;
      }
    }
  }

  &__copy {
    margin: 0 0 0 calc(#{$base-margin} / 2);
    display: inline-block;
    cursor: pointer;

    i.c-Popover__copyIcon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%2359616E' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      width: 20px;
      height: 20px;
    }

    &:hover i.c-Popover__copyIcon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E") !important;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: $base-margin;

    ::v-deep .c-Button span{
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
    }
  }

  .separator {
    position: relative;
  }

  .separator::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $size--2-half;
    border-top: 1px solid $neutral--30;
  }

  .separator__space {
    height: $size--3-half;
  }
}
</style>
