<template>
  <div class="c-LanguagePair">
    <div class="c-LanguagePair__bg">
      <p class="c-LanguagePair__source">
        <img :src="`/flags-dark/${source}.svg`">
        <span>{{ sourceCode }}</span>
      </p>
      <p class="c-LanguagePair__target">
        <img :src="`/flags-dark/${target}.svg`">
        <span>{{ targetCode }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguagePair',
  props: {
    source: {
      type: String,
      required: true,
      default: () => ''
    },
    target: {
      type: String,
      required: true,
      default: () => ''
    }
  },
  computed: {
    sourceCode () {
      return this.getShortLangCode(this.source)
    },
    targetCode () {
      return this.getShortLangCode(this.target)
    }
  },
  methods: {
    getShortLangCode (val) {
      return val.indexOf('-') > -1 ? val.split('-')[1] : val
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-LanguagePair {
  display: inline-block;

  &__bg {
    white-space: nowrap;
    display: flex;
    align-items: center;
    background-color: $un-n50;
    padding: calc(#{$base-padding} / 2);
    border-radius: $border-radius;
  }

  p {
    display: flex;
    align-items: center;
  }

  &__source {
    border-right: 1px solid $un-gray-text;
    padding-right: calc(#{$base-padding} / 1.25);
  }

  &__target {
    padding-left: calc(#{$base-padding} / 1.25);
  }

  img {
    margin-right: calc(#{$base-margin} / 2);
    max-height: 26px;
    object-fit: contain;
  }

  span {
    color: $un-n900;
    font-size: 12px;
    line-height: 18px;
    font-family: $primary-font;
    text-transform: uppercase;
  }
}
</style>
