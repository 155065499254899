export const languageOptions = {
  af: { label: 'Afrikaans' },
  am: { label: 'Amharic' },
  'ar-ae': { label: 'Arabic (United Arab Emirates)' },
  'ar-dz': { label: 'Arabic (Algeria)' },
  'ar-eg': { label: 'Arabic (Egypt)' },
  'ar-il': { label: 'Arabic (Israel)' },
  'ar-qa': { label: 'Arabic (Qatar)' },
  'ar-sa': { label: 'Arabic (Saudi Arabia)' },
  ar: { label: 'Arabic' },
  aze: { label: 'Azerbaijani' },
  ben: { label: 'Bengali' },
  bg: { label: 'Bulgarian' },
  bs: { label: 'Bosnian' },
  ca: { label: 'Catalan' },
  cs: { label: 'Czech' },
  da: { label: 'Danish' },
  'de-at': { label: 'German (Austria)' },
  'de-ch': { label: 'German (Switzerland)' },
  de: { label: 'German' },
  dv: { label: 'Divehi' },
  el: { label: 'Greek' },
  'en-au': { label: 'English (Australia)' },
  'en-gb': { label: 'English (UK)' },
  en: { label: 'English' },
  'es-ar': { label: 'Spanish (AR)' },
  'es-cl': { label: 'Spanish (CL)' },
  'es-co': { label: 'Spanish (CO)' },
  'es-latam': { label: 'Spanish (Latam)' },
  'es-mx': { label: 'Spanish (MX)' },
  'es-pe': { label: 'Spanish (Peru)' },
  'es-us': { label: 'Spanish (US)' },
  'es-ve': { label: 'Spanish (VE)' },
  es: { label: 'Spanish' },
  et: { label: 'Estonian' },
  fa: { label: 'Farsi' },
  fi: { label: 'Finnish' },
  fil: { label: 'Filipino' },
  'fr-be': { label: 'French (Belgium)' },
  'fr-ca': { label: 'French (CA)' },
  'fr-ch': { label: 'French (Switzerland)' },
  'fr-lu': { label: 'French (Luxembourg)' },
  fr: { label: 'French' },
  ga: { label: 'Irish' },
  gu: { label: 'Gujarati' },
  he: { label: 'Hebrew' },
  hi: { label: 'Hindi' },
  hr: { label: 'Croatian' },
  ht: { label: 'Haitian Creole' },
  hu: { label: 'Hungarian' },
  hy: { label: 'Armenian' },
  id: { label: 'Indonesian' },
  is: { label: 'Icelandic' },
  'it-ch': { label: 'Italian (Switzerland)' },
  it: { label: 'Italian' },
  ja: { label: 'Japanese' },
  kk: { label: 'Kazakh' },
  km: { label: 'Khmer' },
  kn: { label: 'Kannada' },
  ko: { label: 'Korean' },
  lt: { label: 'Lithuanian' },
  lv: { label: 'Latvian' },
  mk: { label: 'Macedonian' },
  ml: { label: 'Malayalam' },
  mr: { label: 'Marathi' },
  'ms-my': { label: 'Malay' },
  mt: { label: 'Maltese' },
  my: { label: 'Myanmar (Burmese)' },
  'nl-be': { label: 'Dutch (Belgium)' },
  nl: { label: 'Dutch' },
  no: { label: 'Norwegian' },
  'pa-in': { label: 'Punjabi (India)' },
  'pa-pk': { label: 'Punjabi (Pakistan)' },
  pl: { label: 'Polish' },
  prs: { label: 'Dari' },
  'pt-br': { label: 'Portuguese (BR)' },
  pt: { label: 'Portuguese' },
  ro: { label: 'Romanian' },
  ru: { label: 'Russian' },
  sk: { label: 'Slovak' },
  sl: { label: 'Slovenian' },
  sq: { label: 'Albanian' },
  sr: { label: 'Serbian' },
  sv: { label: 'Swedish' },
  sw: { label: 'Swahili' },
  ta: { label: 'Tamil' },
  th: { label: 'Thai' },
  tr: { label: 'Turkish' },
  tuk: { label: 'Turkmen' },
  uk: { label: 'Ukrainian' },
  vi: { label: 'Vietnamese' },
  'zh-CN': { label: 'Chinese (Simplified)' },
  'zh-hk': { label: 'Chinese (HK)' },
  'zh-TW': { label: 'Chinese (Traditional)' },
  ur: { label: 'Urdu' }
}
