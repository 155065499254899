<template>
  <div class="c-FlowDetails">
    <div
      v-if="data && data.phases && data.phases.length"
      class="c-FlowDetails__flow">
      <FlowPhase
        v-for="(phase, index) in data.phases"
        :key="index"
        :data="phase"
        :flowphase-as-arrow="false"
        :style="{ width: `${100 / data.phases.length}%` }" />
    </div>

    <div v-if="data.usingOldPhases" class="c-FlowDetails__flow-warning mt-4">
        <Icon
          class="icon mr-2"
          icon="alert-circle"
          icon-style="is-important"
          sm
        />
        This is an old translation, some information may be missing.
      </div>
  </div>
</template>

<script>
import FlowPhase from '@/components/FlowPhase'
import { Icon } from '@unbabel/ui'

export default {
  name: 'FlowDetails',
  components: {
    FlowPhase,
    Icon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.c-FlowDetails {
  width: 100%;

  &__flow {
    display: flex;
    margin-left: -$base-margin;
    width: calc(100% + calc(#{$base-margin} * 2));

    &-warning {
      display: flex;
      align-items: center;
      color: $un-n700;
      font-size: 14px;
    }
  }
}
</style>
