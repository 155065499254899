<template>
  <div class="l-changelog">
    <h4>Release Notes</h4>
    <div class="l-changelog__diagram">
      <Diagram :data="diagramData"></Diagram>
    </div>
  </div>
</template>

<script>
import Diagram from '@/components/Diagram.vue'
import { changelogData } from '@/data/changelogData'

export default {
  name: 'Changelog',
  components: {
    Diagram
  },
  data () {
    return {
      diagramData: changelogData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.l-changelog {
  margin-bottom: calc(#{$base-margin} * 2);

  h4 {
    font-family: $secondary-font;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.52;
    color: $un-n900;
    margin-bottom: $base-margin;
  }

  &__diagram {
    width: 100%;
    margin: calc(#{$base-margin} * 3) 0 0 0;
  }
}
</style>
