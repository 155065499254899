<template>
  <table class="c-CardBodyLayout">
    <slot />
  </table>
</template>

<script>
export default {
  name: 'CardBodyLayout'
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-CardBodyLayout {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;

  tr:not(:first-child) td {
    padding-top: calc(#{$base-padding} * 2);
  }

  td {
    padding: 0 $base-padding 0 0;

    p {
      color: $un-n900;
      font-family: $primary-font;

      &:first-child {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        margin-bottom: calc(#{$base-margin} / 2);
      }
      &:last-child {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.24px;
      }
    }

    > div p:first-child {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.24px;
    }

    &:last-child {
      padding: 0;
    }
  }
}
</style>
