<template>
  <div class="c-Pagination">
    <div class="c-Pagination__info">
      <span>Page {{ currentPage + 1 }} of {{ totalPages }}</span>
      <span>({{ paginationItems }} items per page)</span>
    </div>
    <button
      type="button"
      class="c-Pagination__navigation left"
      title="Previous page"
      :disabled="!canNavigateBack"
      @click="navigate(DIRECTIONS.BACK)"
      @keydown.left="navigate(DIRECTIONS.BACK)"
    >
      <Icon
        :icon="'arrow-nav-left'"
        :icon-style="iconStyle(DIRECTIONS.BACK)"
      />
    </button>
    <button
      type="button"
      class="c-Pagination__navigation right"
      title="Next page"
      :disabled="!canNavigateFoward"
      @click="navigate(DIRECTIONS.FORWARD)"
      @keydown.right="navigate(DIRECTIONS.FORWARD)"
    >
      <Icon
        :icon="'arrow-nav-right'"
        :icon-style="iconStyle(DIRECTIONS.FORWARD)"
      />
    </button>
  </div>
</template>

<script>
import { Icon } from '@unbabel/ui'

const DIRECTIONS = {
  BACK: 'back',
  FORWARD: 'forward'
}

export default {
  name: 'Pagination',
  components: {
    Icon
  },
  props: {
    /**
     * The value (index) of the current page
     */
    currentPage: {
      type: Number,
      default: 0
    },
    /**
     * The total number of available pages
     */
    totalPages: {
      type: Number,
      default: 0
    },
    /**
     * The number of items
     */
    paginationItems: {
      type: Number,
      default: 10
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    DIRECTIONS
  }),
  computed: {
    canNavigateBack () {
      return this.currentPage > 0 && !this.isLoading
    },
    canNavigateFoward () {
      return (this.currentPage < this.totalPages - 1) && !this.isLoading
    }
  },
  methods: {
    navigate (direction) {
      let newPage = this.currentPage
      newPage = direction === DIRECTIONS.FORWARD
        ? newPage += 1
        : newPage -= 1

      this.$emit('changePage', newPage)
    },
    iconStyle (direction) {
      let iconStyle = 'is-primary'

      if (
        direction === DIRECTIONS.FORWARD) {
        iconStyle = this.canNavigateFoward ? 'is-primary' : 'is-disabled-gray'
      }
      if (
        direction === DIRECTIONS.BACK) {
        iconStyle = this.canNavigateBack ? 'is-primary' : 'is-disabled-gray'
      }

      return iconStyle
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-Pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $base-padding;
  font-family: $primary-font;
  font-size: 14px;

  &__info {
    margin-right: $base-margin;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      &:last-child {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }

  &__navigation {
    display: flex;
    padding: #{$base-padding / 2};
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    transition: background-color .2s;

    &:focus, &:active {
      outline: auto;
    }
    &:hover:enabled {
      cursor: pointer;
      background-color: $un-gray1;
      transition: background-color .2s;
    }
    &:disabled {
      cursor: not-allowed;
      svg {
        fill: $un-gray1-dark;
      }
    }
    svg {
      pointer-events: none;
    }
  }
}
</style>
