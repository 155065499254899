<template>
  <Card
    class="c-EditorSection"
    title="Editor">
    <div
      v-if="trDetails.editor && trDetails.editor.name"
      slot="header-actions"
      class="c-EditorSection__cardActions">
      <a
        :href="uadminEditorUrl"
        rel="noopener noreferrer"
        target="_blank"
        data-test-id="msg-tr-editor-profile-link">
        <Button
          label="Core profile"
          button-type="is-outlined"
          icon="external-hyperlink"
          @click="trackAction" />
      </a>
    </div>
    <div
      slot="body"
      class="c-EditorSection__cardBody">
      <CardBodyLayout>
        <tr colspan="2">
          <td>
            <p>Name</p>
            <p data-test-id="msg-tr-editor-name">{{ name }}</p>
          </td>
          <td>
            <p>Hourly Rate</p>
            <p data-test-id="msg-tr-editor-hour-rate">{{ hourlyRate }}</p>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { Card, Button } from '@unbabel/ui'
import { trackEvent } from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'EditorSection',
  components: {
    Card,
    Button,
    CardBodyLayout
  },
  props: {
    trDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    name () {
      return this.trDetails?.editor?.name || '---'
    },
    hourlyRate () {
      // units are cents
      return this.trDetails?.hourly_rate ? `$${Math.round((parseFloat(this.trDetails?.hourly_rate / 100) + Number.EPSILON) * 100) / 100}` : '---'
    },
    uadminEditorUrl () {
      return `${process.env.VUE_APP_CORE_BASE_URL}/uadmin/users/${this.trDetails?.editor?.name}`
    }
  },
  methods: {
    trackAction () {
      trackEvent(MIXPANEL_EVENTS.EDITOR_PROFILE_LINK, {
        area: this.$route.name,
        type: 'editor-profile'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-EditorSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }
}
</style>
