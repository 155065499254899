import {
  OVERALL_STATUS,
  ARCH_STATUS,
  HT_TASK_STATUS
} from '@/data/enum'

export const statusOptions = {
  // overall translation status
  [OVERALL_STATUS.INIT_STATUS]: {
    label: 'Created',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  [OVERALL_STATUS.RESET_STATUS]: {
    label: 'Resetting Flow',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_FALLBACK_START_STATUS]: {
    label: 'MT Fallback in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_FALLBACK_END_STATUS]: {
    label: 'MT Fallback complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_HT_FALLBACK_START_STATUS]: {
    label: 'MT+HT Fallback in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_HT_FALLBACK_END_STATUS]: {
    label: 'MT+HT Fallback complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_START_STATUS]: {
    label: 'MT in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.MT_END_STATUS]: {
    label: 'MT complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.HT_START_STATUS]: {
    label: 'HT in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.HT_SKIP_STATUS]: {
    label: 'HT skipped',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.HT_CANCEL_STATUS]: {
    label: 'HT canceled',
    labelType: 'is-subtle',
    labelStyle: 'is-error',
    type: 'overall'
  },
  [OVERALL_STATUS.HT_END_STATUS]: {
    label: 'HT complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.REBUILD_START_STATUS]: {
    label: 'Rebuild in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.REBUILD_END_STATUS]: {
    label: 'Rebuild complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.MARKUP_ALIGNER_START_STATUS]: {
    label: 'Markup Aligner in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.MARKUP_ALIGNER_END_STATUS]: {
    label: 'Markup Aligner complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.TM_UPDATE_START_STATUS]: {
    label: 'TM Update in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.TM_UPDATE_END_STATUS]: {
    label: 'TM Update complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.DELIVER_START_STATUS]: {
    label: 'Delivery in progress',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'overall'
  },
  [OVERALL_STATUS.DELIVER_END_STATUS]: {
    label: 'Delivery complete',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.COMPLETED_STATUS]: {
    label: 'Completed',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'overall'
  },
  [OVERALL_STATUS.FAILED_STATUS]: {
    label: 'Failed',
    labelType: 'is-subtle',
    labelStyle: 'is-error',
    type: 'overall'
  },
  [OVERALL_STATUS.CANCELED_STATUS]: {
    label: 'Canceled',
    labelType: 'is-subtle',
    labelStyle: 'is-error',
    type: 'overall'
  },
  [OVERALL_STATUS.UNKNOWN_STATUS]: {
    label: 'Unknown',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  // arch status
  [ARCH_STATUS.CORE]: {
    label: 'Core',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  [ARCH_STATUS.CORE_FALLBACK]: {
    label: 'Core Fallback',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  // translation record status (tarkin)
  submit: {
    label: 'Submitted',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'task'
  },
  skip: {
    label: 'Skipped',
    labelType: 'is-subtle',
    labelStyle: 'is-success',
    type: 'task'
  },
  cancel: {
    label: 'Canceled',
    labelType: 'is-subtle',
    labelStyle: 'is-error',
    type: 'task'
  },
  get: {
    label: 'Pending',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'task'
  },
  accept: {
    label: 'Pending',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'task'
  },
  // task status
  [HT_TASK_STATUS.ENQUEUED]: {
    label: 'enqueued',
    labelType: 'is-subtle',
    labelStyle: 'is-info',
    type: 'task'
  },
  [HT_TASK_STATUS.DEQUEUED]: {
    label: 'dequeued',
    labelType: 'is-bold',
    labelStyle: 'is-inverted',
    type: 'task'
  },
  task_status_unknown: {
    label: 'Unknown task status',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'task'
  },
  // skip reasons
  tm: {
    label: 'tm',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  },
  untranslatable: {
    label: 'untranslatable',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  },
  tm_or_untranslatable: {
    label: 'tm/untrans.',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  },
  qe_score: {
    label: 'qe score',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  },
  qe_alert: {
    label: 'qe alert',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  },
  original_number_words: {
    label: 'number of words',
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'skip'
  }
}
