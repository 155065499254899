<template>
  <div class="l-messageDetails">
    <MessageDetailsLayout :messageDetails="messageDetails"></MessageDetailsLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GLOBAL_GETTERS, GLOBAL_ACTIONS } from '@/data/enum'
import MessageDetailsLayout from '@/components/MessageDetailsLayout'
import { showNotification, parseRequestError } from '@/utils/generalUtils'

export default {
  name: 'Details',
  components: {
    MessageDetailsLayout
  },
  computed: {
    ...mapGetters({
      messageDetails: GLOBAL_GETTERS.MESSAGE_DETAILS
    })
  },
  beforeMount () {
    const payload = {
      uid: this.$route.params.messageId,
      threadId: this.$route.params.threadId
    }
    this.$store.dispatch(GLOBAL_ACTIONS.INIT_MESSAGE_DETAILS_VIEW, payload).catch(err => {
      const msg = parseRequestError(err)
      showNotification(this, 'is-alert', `Error initializing Message Details :: ${msg}`)
    })
  }
}
</script>
