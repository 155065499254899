<template>
  <Card
      class="c-MessageTranslationProfileSection"
      title="Pipeline Group">
      <div
        slot="header-actions"
        class="c-MessageTranslationProfileSection__cardActions">
        <a
          v-if="messageDetails.customer && messageDetails.customer.canonical_name && messageDetails.customer.canonical_name.length"
          :href="customerPortalTranslationProfileLink(messageDetails)"
          target="_blank"
          rel="noopener noreferrer">
          <Button
            label="Portal"
            button-type="is-outlined"
            icon="external-hyperlink"
            data-test-id="msg-portal-tp-link"
            @click="trackAction('translationProfile')" />
        </a>
      </div>
      <div
        slot="body"
        class="c-MessageTranslationProfileSection__cardBody">
        <CardBodyLayout>
          <tr colspan="6">
            <td>
              <p>Client</p>
              <div id="client-info">
                <p data-test-id="msg-canonical-name">{{ client }}</p>
                <Tooltip
                  :message="client"
                  :position="'bottom'"
                  anchorName="client-info" />
              </div>
            </td>
            <td>
              <p>Pipeline Group</p>
              <div id="translationProfile-info">
                <p data-test-id="msg-tp-name">{{ translationProfile }}</p>
                <Tooltip
                  :message="translationProfile"
                  :position="'bottom'"
                  anchorName="translationProfile-info" />
              </div>
            </td>
            <td>
              <p>Customer ID</p>
              <div
                id="customer-info"
                class="c-MessageTranslationProfileSection__customerID"
                @click="copyText(customerID, index)">
                <p data-test-id="msg-customer">{{ customerID }}</p>
                <i class="c-MessageTranslationProfileSection__customerID--icon" />
                <Tooltip
                    :message="isIdCopied ? 'Copied!' : 'Click to copy'"
                    position="bottom"
                    anchorName="customer-info" />
              </div>
            </td>
            <td>
              <p>Flow</p>
              <div id="flow-info">
                <p data-test-id="msg-flow">{{ flow }}</p>
                <Tooltip
                  :message="flow"
                  :position="'bottom'"
                  anchorName="flow-info" />
              </div>
            </td>
            <td v-if="displayHtFlowCondition">
              <p>HT Flow</p>
              <div id="htFlow-info">
                <p data-test-id="msg-ht-flow">{{ htFlow }}</p>
                <Tooltip
                  :message="htFlow"
                  :position="'bottom'"
                  anchorName="htFlow-info" />
              </div>
            </td>
            <td>
              <p>Register</p>
              <div id="register-info">
                <p data-test-id="msg-register">{{ register }}</p>
                <Tooltip
                  :message="register"
                  :position="'bottom'"
                  anchorName="register-info" />
              </div>
            </td>
            <td v-if="messageDetails.translation_profile && messageDetails.translation_profile.glossary_id">
              <p>Glossary</p>
              <div id="glossary-info">
                <a
                  :href="customerPortalGlossaryLink(messageDetails)"
                  target="_blank"
                  data-test-id="msg-glossary"
                  rel="noopener noreferrer"
                  class="glossary-btn"
                  @click="trackAction('glossary')"
                  >
                    <Icon icon="external-hyperlink" />
                    Open glossary
                </a>
              </div>
            </td>
          </tr>
        </CardBodyLayout>
      </div>
    </Card>
</template>

<script>
import {
  generateFlowName,
  generateCPLinks,
  copyToClipboard,
  trackEvent
} from '@/utils/generalUtils'
import { MIXPANEL_EVENTS, MODULE } from '@/data/enum'
import { Card, Button, Tooltip, Icon } from '@unbabel/ui'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'MessageTranslationProfileSection',
  components: {
    Card,
    Button,
    Tooltip,
    Icon,
    CardBodyLayout
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isIdCopied: false
  }),
  computed: {
    client () {
      return this.messageDetails?.customer?.canonical_name || '---'
    },
    translationProfile () {
      return this.messageDetails?.translation_profile?.name || '---'
    },
    customerID () {
      return this.messageDetails?.customer?.username || '---'
    },
    flow () {
      const generatedFlowName = generateFlowName(this.messageDetails)
      return `${generatedFlowName}`
    },
    displayHtFlowCondition () {
      return this.messageDetails?.phases?.find(item => item.name === MODULE.HT)
    },
    htFlow () {
      return this.messageDetails?.human_translation_details?.human_workflow_id || '---'
    },
    register () {
      return this.messageDetails?.trace?.workflow_execution?.requested_workflow?.tags?.register?.[0] || '---'
    }
  },
  methods: {
    trackAction (type) {
      trackEvent(MIXPANEL_EVENTS.CP_LINK, {
        area: this.$route.name,
        type
      })
    },
    copyText (id) {
      if (!this.isIdCopied) {
        this.isIdCopied = true
        copyToClipboard(id)

        setTimeout(() => {
          this.isIdCopied = false
        }, 1000)
      }
    },
    customerPortalTranslationProfileLink (item) {
      return generateCPLinks(item, 'translation_profile')
    },
    customerPortalGlossaryLink (item) {
      return generateCPLinks(item, 'glossary')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-MessageTranslationProfileSection {

  &__customerID {
    display: inline-flex;
    cursor: pointer;

    i {
      opacity: 0;
      width: 24px;
      height: 24px;
      margin-left: 4px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    }

    &:hover {
      text-decoration: underline;
      i {
        opacity: 1;
      }
    }
  }

  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  td > div {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  td {
    vertical-align: middle;

    .glossary-btn {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-left: auto;
      margin-bottom: .5em;

      .c-Icon {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px;
      }
    }
  }
}
</style>
