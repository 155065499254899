<template>
  <div
    :id="`statuschip${randomId}`"
    class="c-StatusChip"
    :class="statusChipClassObj"
    @click="handleClick">
    <Label
      class="linkClassIdentifier"
      :value="parsedStatus"
      :label-type="statusChipStyling.labelType"
      :label-style="statusChipStyling.labelStyle">
    </Label>
    <Tooltip
      v-if="tooltipMessage"
      class="c-StatusChip__tooltip"
      :message="parsedTooltipMessage"
      :position="tooltipPosition"
      :anchorName="`statuschip${randomId}`"></Tooltip>
  </div>
</template>

<script>
import { statusOptions } from '@/data/status'
import { randomizeId } from '@/utils/generalUtils'
import { Label, Tooltip } from '@unbabel/ui'

export default {
  name: 'StatusChip',
  components: {
    Label,
    Tooltip
  },
  props: {
    status: {
      type: String,
      required: true,
      default: () => 'Undefined status'
    },
    statusConfig: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: false,
      default: () => ''
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: () => ''
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: () => 'bottom'
    }
  },
  data () {
    return {
      randomId: randomizeId()
    }
  },
  computed: {
    statusChipClassObj () {
      return {
        isClickable: this.isClickable
      }
    },
    statusChipStyling () {
      if (this.statusConfig && Object.keys(this.statusConfig)) {
        return {
          labelType: this.statusConfig.labelType,
          labelStyle: this.statusConfig.labelStyle
        }
      }
      if (statusOptions[`${this.status}`]) {
        return {
          labelType: statusOptions[`${this.status}`].labelType,
          labelStyle: statusOptions[`${this.status}`].labelStyle
        }
      }
      return {
        labelType: 'is-subtle',
        labelStyle: 'is-inverted'
      }
    },
    parsedStatus () {
      if (this.statusConfig && Object.keys(this.statusConfig)) {
        return this.status
      }
      if (this.type && this.type === 'skip') {
        return statusOptions[`${this.status}`] ? `${statusOptions[`${this.status}`].label} skip` : `${this.status} skip`
      }
      if (statusOptions[`${this.status}`]) {
        return statusOptions[`${this.status}`].label
      }
      return 'Undefined status'
    },
    parsedTooltipMessage () {
      if (this.type && this.type === 'skip') {
        return statusOptions[`${this.tooltipMessage}`] ? `${statusOptions[`${this.tooltipMessage}`].label}` : this.tooltipMessage
      }
      return this.tooltipMessage
    }
  },
  methods: {
    handleClick () {
      if (this.isClickable) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-StatusChip {
  &.isClickable {
    cursor: pointer;
  }
}
</style>
