<template>
  <div class="c-InputModal">
    <div class="c-InputModal__topBlock">
      <p class="c-InputModal__title">{{ title }}</p>
      <button
        class="c-InputModal__reset"
        @click="resetValue">Reset</button>
    </div>
    <div class="c-InputModal__bottomBlock">
      <slot></slot>
      <Button
        class="c-InputModal__save"
        label="Save"
        @click="saveValue"></Button>
    </div>
  </div>
</template>

<script>
import { Button } from '@unbabel/ui'
export default {
  name: 'InputModal',
  components: {
    Button
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => ''
    }
  },
  methods: {
    resetValue () {
      this.$emit('reset')
    },
    saveValue () {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-InputModal {
  width: 314px;
  height: 140px;
  box-shadow: 0 14px 28px 0 rgba(174, 185, 203, 0.25), 0 10px 10px 0 rgba(174, 185, 203, 0.26);
  background-color: $un-white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: $base-padding;

  &__title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: $un-n900;
  }

  &__topBlock, &__bottomBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__reset {
    border: none;
    border-radius: none;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    padding: calc(#{$base-padding} / 3);
    font-size: 14px;
    font-family: $primary-font;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: $un-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  &__save {
    margin-left: $base-margin;
  }
}
</style>
