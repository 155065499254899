<template>
  <div
    class="c-Modal"
    :class="modalClassObj"
    @click.self.stop="closeModal">
    <div
      ref="search"
      tabindex="-1"
      class="c-Modal__window"
      :style="modalInlineStyle">
      <div class="c-Modal__header">
        <h5 class="c-Modal__title">{{ title }}</h5>
      </div>
      <div
        class="c-Modal__content"
        :class="{ disableBodyPadding: disableBodyPadding }">
        <slot></slot>
      </div>

      <div class="c-Modal__footer">
        <slot name="legend"></slot>
        <Button
          label="Close"
          title="Close Modal"
          button-style="is-primary-inverted"
          button-type="is-text"
          @click="closeModal"></Button>
      </div>

    </div>
  </div>
</template>

<script>
import { Button } from '@unbabel/ui'

export default {
  name: 'Modal',
  components: {
    Button
  },
  props: {
    widthPercentage: {
      type: Number,
      required: false,
      default: () => 90,
      validator (val) {
        if (val >= 40 && val <= 90) {
          return true
        }
        return false
      }
    },
    heightPercentage: {
      type: Number,
      required: false,
      default: () => 90,
      validator (val) {
        if (val >= 40 && val <= 90) {
          return true
        }
        return false
      }
    },
    widthPx: {
      type: Number,
      required: false
    },
    heightPx: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      required: true,
      default: () => 'Modal Window'
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: () => false
    },
    disableBodyPadding: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    modalClassObj () {
      return {
        'is-visible': this.isVisible
      }
    },
    modalInlineStyle () {
      if (this.widthPx || this.heightPx) {
        return {
          width: this.widthPx ? `${this.widthPx}px` : '100%',
          height: this.heightPx ? `${this.heightPx}px` : '100%',
          'min-width': 'unset',
          'min-height': 'unset'
        }
      }

      return {
        width: `${this.widthPercentage}%`,
        height: `${this.heightPercentage}%`
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('toggleVisibility')
    },
    browserSearchModified (event) {
      if (!this.isVisible) return

      if ((event.ctrlKey || event.metaKey) && (event.key.toLowerCase() === 'f')) {
        const searchElement = this.$refs.search

        if (!searchElement) return

        setTimeout(() => { // give time for the search bar to show
          searchElement.focus()
          const selection = window.getSelection()
          selection.removeAllRanges()

          const range = document.createRange()
          range.selectNodeContents(searchElement)
          selection.addRange(range)
        }, 300)
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.browserSearchModified)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.browserSearchModified)
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all $default-time $default-easing;
  z-index: 1000000 !important;

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  &__window {
    background-color: $un-white;
    border-radius: $border-radius;
    box-shadow: 0px 10px 10px 0px rgba(174, 185, 203, 0.26), 0px 14px 28px 0px rgba(174, 185, 203, 0.25);
    min-width: 40%;
    min-height: 40%;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
  }

  &__header {
    width: 100%;
    height: 66px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $base-padding calc(#{$base-padding} * 1.5);
  }

  &__title {
    font-family: $secondary-font;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.17px;
    color: $un-n900;
    font-weight: 600;
  }

  &__content {
    padding: 0 calc(#{$base-padding} * 1.5);
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 66px - 66px);

    &.disableBodyPadding {
      padding: 0 !important;
    }
  }

  &__footer {
    height: 66px;
    width: 100%;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $base-padding calc(#{$base-padding} * 1.5);
  }
}
</style>
