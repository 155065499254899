import format from 'date-fns/format'
import isSameYear from 'date-fns/isSameYear'
import { utcToZonedTime } from 'date-fns-tz'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import intervalToDuration from 'date-fns/intervalToDuration'

function formatInTimeZone (date, dateFormat, timezone = 'UTC') {
  return format(utcToZonedTime(date, timezone), dateFormat, { timeZone: timezone })
}

export function calcDatesDiff (startDate, endDate, forceSameYearForTesting) {
  const dateObjStart = new Date(startDate)
  const dateObjEnd = new Date(endDate)

  const parsedStartDate = (isSameYear(new Date(), dateObjStart) || forceSameYearForTesting) ? formatInTimeZone(dateObjStart, 'd MMM, HH:mm:ss') : formatInTimeZone(dateObjStart, 'd MMM yyyy, HH:mm')
  const parsedEndDate = (isSameYear(new Date(), dateObjEnd) || forceSameYearForTesting) ? formatInTimeZone(dateObjEnd, 'd MMM, HH:mm:ss') : formatInTimeZone(dateObjEnd, 'd MMM yyyy, HH:mm')

  const { diffElapsed, diffFullTime, diffAsSeconds } = parseDuration(dateObjStart, dateObjEnd)

  return {
    parsedStartDate,
    utcStartDate: `${formatInTimeZone(dateObjStart, 'dd-MM-yyyy HH:mm:ss')} UTC`,
    parsedEndDate,
    utcEndDate: `${formatInTimeZone(dateObjEnd, 'dd-MM-yyyy HH:mm:ss')} UTC`,
    diffElapsed,
    diffFullTime,
    diffAsSeconds
  }
}

export function parseDuration (dateObjStart, dateObjEnd) {
  let diffElapsed
  let diffFullTime
  const duration = intervalToDuration({
    start: dateObjStart,
    end: dateObjEnd
  })

  if (differenceInSeconds(dateObjEnd, dateObjStart) <= 1) {
    diffElapsed = '< 1s'
    diffFullTime = `0.${parseInt(differenceInMilliseconds(dateObjEnd, dateObjStart))} s`
  } else if (differenceInSeconds(dateObjEnd, dateObjStart) <= 60) {
    diffElapsed = `${parseInt(differenceInSeconds(dateObjEnd, dateObjStart))}s`
    diffFullTime = diffElapsed
  } else if (differenceInSeconds(dateObjEnd, dateObjStart) <= 3600) {
    diffElapsed = `${parseInt(differenceInMinutes(dateObjEnd, dateObjStart))}m`
    diffFullTime = `${duration.minutes}m ${duration.seconds}s`
  } else if (differenceInHours(dateObjEnd, dateObjStart) <= 24) {
    diffElapsed = `${parseInt(differenceInHours(dateObjEnd, dateObjStart))}h`
    diffFullTime = `${duration.hours}h ${duration.minutes}m ${duration.seconds}s`
  } else {
    diffElapsed = `${parseInt(differenceInHours(dateObjEnd, dateObjStart))}h`
    diffFullTime = `${duration.days}d ${duration.hours}h ${duration.minutes}m ${duration.seconds}s`
  }

  const diffAsSeconds = differenceInSeconds(dateObjEnd, dateObjStart)

  return {
    diffElapsed,
    diffFullTime,
    diffAsSeconds
  }
}

export function formatToXrayDateDisplay (date, isUtc) {
  const dateObj = new Date(date)

  if (isUtc) {
    return `${formatInTimeZone(dateObj, 'dd-MM-yyyy HH:mm:ss')} UTC`
  }

  if (!isSameYear(new Date(), dateObj)) {
    return formatInTimeZone(dateObj, 'd MMM yyyy, HH:mm')
  }

  return formatInTimeZone(dateObj, 'd MMM, HH:mm')
}

export function formatDate (dateObj, stringFormat, isUtc = false) {
  return isUtc ? formatInTimeZone(new Date(dateObj), stringFormat) : format(new Date(dateObj), stringFormat)
}
