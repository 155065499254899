<template>
  <Card
    class="c-TranslationRecordDetailsSection"
    title="Translation Record Details">
    <div
      v-if="trDetails.session_replay_url"
      slot="header-actions"
      class="c-TranslationRecordDetailsSection__cardActions">
      <a
        :href="trDetails.session_replay_url"
        target="_blank"
        rel="noopener noreferrer"
        data-test-id="msg-tr-fullstory">
        <Button
          label="Fullstory"
          button-type="is-outlined"
          icon="external-hyperlink"
          @click="trackAction" />
      </a>
    </div>
    <div
      slot="body"
      class="c-TranslationRecordDetailsSection__cardBody">
      <CardBodyLayout>
        <tr colspan="3">
          <td>
            <p>Created</p>
            <p
              id="trCreated"
              data-test-id="msg-tr-created">
              {{ created }}
              <Tooltip
                v-if="trDetails.utc_parsed_created_at"
                :message="trDetails.utc_parsed_created_at"
                :position="'bottom'"
                anchorName="trCreated"></Tooltip>
            </p>
          </td>
          <td
            v-if="deleted"
            class="deleted"
            data-test-id="msg-tr-deleted">
            <p>Deleted</p>
            <p
              id="trDeleted">
              {{ deleted }}
              <Tooltip
                v-if="trDetails.utc_parsed_deleted_at"
                :message="trDetails.utc_parsed_deleted_at"
                :position="'bottom'"
                anchorName="trDeleted"></Tooltip>
            </p>
          </td>
          <td
            v-else
            class="completed"
            data-test-id="msg-tr-completed">
            <p>Completed</p>
            <p
              id="trCompleted">
              {{ completed }}
              <Tooltip
                v-if="trDetails.utc_parsed_completed_at"
                :message="trDetails.utc_parsed_completed_at"
                :position="'bottom'"
                anchorName="trCompleted"></Tooltip>
            </p>
          </td>
          <td>
            <p>Time Taken</p>
            <p
              id="trTimeTaken"
              data-test-id="msg-tr-time-taken">
              {{ timeTaken }}
              <Tooltip
                v-if="trDetails.full_duration"
                :message="trDetails.full_duration"
                :position="'bottom'"
                anchorName="trTimeTaken"></Tooltip>
            </p>
          </td>
        </tr>
        <tr colspan="3">
          <td>
            <p>Editing Time</p>
            <p data-test-id="msg-tr-editing-time">{{ editingTime }}</p>
          </td>
          <td>
            <p>Paid Time</p>
            <p data-test-id="msg-tr-paid-time">{{ paidTime }}</p>
          </td>
          <td>
            <p>Editing Cost</p>
            <p data-test-id="msg-tr-editing-cost">{{ editingCost }}</p>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { Card, Button, Tooltip } from '@unbabel/ui'
import { trackEvent } from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'TranslationRecordDetailsSection',
  components: {
    Card,
    Button,
    Tooltip,
    CardBodyLayout
  },
  props: {
    trDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    created () {
      return this.trDetails.parsed_created_at || this.trDetails.created_at || '---'
    },
    completed () {
      return this.trDetails.parsed_completed_at || this.trDetails.completed_at || '---'
    },
    deleted () {
      return this.trDetails.parsed_deleted_at || this.trDetails.deleted_at
    },
    timeTaken () {
      return this.trDetails.parsed_duration || this.trDetails.duration || '---'
    },
    editingTime () {
      return this.trDetails.editing_time ? `${this.trDetails.editing_time}s` : '---'
    },
    paidTime () {
      return this.trDetails.paid_time ? `${this.trDetails.paid_time}s` : '---'
    },
    editingCost () {
      // units are cents
      return this.trDetails.editing_cost ? `$${Math.round((parseFloat(this.trDetails.editing_cost / 100) + Number.EPSILON) * 100) / 100}` : '---'
    }
  },
  methods: {
    trackAction () {
      trackEvent(MIXPANEL_EVENTS.FULLSTORY_LINK, {
        area: this.$route.name,
        type: 'fullstory'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-TranslationRecordDetailsSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;

    .deleted p {
      color: $un-red-dark;
    }

    .completed p {
      color: $un-green-dark;
    }
  }
}
</style>
