import { FILTERS_DICT } from '@/data/enum'
import { formatDate } from '@/utils/dateUtils'
import subDays from 'date-fns/subDays'

const defaultFromCreatedDate = () => { return formatDate(new Date(subDays(new Date(), 1)), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") }
const defaultToCreatedDate = () => { return formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") }
const defaultDateGreaterOrEqualThan = () => { return formatDate(new Date(subDays(new Date(), 1)), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", true) }
const defaultDateLessThan = () => { return formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", true) }

/**
 * CONFIG OBJECT FOR THE FILTERS
 * @param {String, Number} value - The initial value for the filter *** (check "GLOBAL_ACTIONS.INIT_SEARCH_VIEW" on src/store/global.js)
 * @param {String} typeOf - JS `typeof` of the admissable value for the filter on the interface
 * @param {Array} oldXrayUrlParamValues - array of old Xray query param values for this particular field to be supported when parsing url params
 * @param {String} feedsFilter - another filter (key) that this param should feed
 * @param {String} xhrQueryParam - XHR query param name for the filter
 * @param {String} mixpanelParam - Mixpanel query param name for mixpanel events
 * @param {Function} xhrParamParsing - If the given filter needs some special parsing to generate it's XHR request param value for the Search list items ***(check "generateRequestUrlParams" on src/utils/urlUtils.js)
 * @param {Boolean} hiddenFromXrayUrl - If the filter is visible as a url query param on Xray
 * @param {Boolean} mandatoryInitURLParam - If the filter needs to be included on the URL after parsing the URL for the first time *** (check "GLOBAL_ACTIONS.INIT_SEARCH_VIEW" on src/store/global.js)
 * @param {Boolean} onInterface - If the filter is displayed on the interface (check "Search.view")
 * @param {Boolean} preventFilterClearing - If the filter value will be cleared ("undefined" or "false") while clearing a Search
 * @param {Boolean} toBeSentOnXHRRequest - If the filter is to be included as a query param on the XHR request for the Search list items *** (check "generateRequestUrlParams" on src/utils/urlUtils.js)
 */

export const SEARCH_FILTERS_CONFIG = {
  [FILTERS_DICT.CONTENT_TYPE]: {
    value: undefined,
    xhrQueryParam: 'content_type',
    mixpanelParam: 'content_type',
    oldXrayUrlParamValues: ['content_type'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.TRANSLATION_DIRECTION]: {
    value: undefined,
    xhrQueryParam: 'translation_direction',
    mixpanelParam: 'translation_direction',
    oldXrayUrlParamValues: ['translation_direction'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.OLDEST_FIRST]: {
    value: false,
    xhrQueryParam: 'oldest_first',
    mixpanelParam: 'oldest_first',
    oldXrayUrlParamValues: ['oldest_first'],
    typeOf: 'boolean',
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.BY_COMPLETION_DATE]: {
    value: false,
    xhrQueryParam: 'by_completion_date',
    mixpanelParam: 'by_completion_date',
    oldXrayUrlParamValues: ['by_completion_date'],
    typeOf: 'boolean',
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.LAST_PAGE]: {
    value: false,
    xhrQueryParam: 'last_page',
    mixpanelParam: 'last_page',
    oldXrayUrlParamValues: ['last_page'],
    typeOf: 'boolean',
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true
  },
  [FILTERS_DICT.LIMIT]: {
    value: 50,
    xhrQueryParam: 'limit',
    mixpanelParam: 'limit',
    oldXrayUrlParamValues: ['limit'],
    typeOf: 'number',
    onInterface: false,
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true,
    preventFilterClearing: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.TASK_ID]: {
    value: undefined,
    xhrQueryParam: 'task_id',
    mixpanelParam: 'task_id',
    oldXrayUrlParamValues: ['task_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.TRANSLATION_RECORD_ID]: {
    value: undefined,
    xhrQueryParam: 'translation_record_id',
    mixpanelParam: 'translation_record_id',
    oldXrayUrlParamValues: ['translation_record_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.UID]: {
    value: undefined,
    xhrQueryParam: 'uid',
    mixpanelParam: 'uid',
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.THREAD_ID]: {
    value: undefined,
    xhrQueryParam: 'thread_id',
    mixpanelParam: 'thread_id',
    oldXrayUrlParamValues: ['thread_id', 'chat_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.EXTERNAL_ID]: {
    value: undefined,
    xhrQueryParam: 'external_id',
    mixpanelParam: 'external_id',
    oldXrayUrlParamValues: ['external_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.THREAD_EXTERNAL_ID]: {
    value: undefined,
    xhrQueryParam: 'thread_external_id',
    mixpanelParam: 'thread_external_id',
    oldXrayUrlParamValues: ['thread_external_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.TEMPLATE]: {
    value: undefined,
    xhrQueryParam: 'template_types',
    mixpanelParam: 'template_types',
    oldXrayUrlParamValues: ['template_types'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.PROJECT_ID]: {
    value: undefined,
    xhrQueryParam: 'project_id',
    mixpanelParam: 'project_id',
    oldXrayUrlParamValues: ['project_id'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    groupedIDField: true
  },
  [FILTERS_DICT.FROM_CREATED_DATE]: {
    value: defaultFromCreatedDate(),
    xhrQueryParam: 'fromCreatedDate',
    mixpanelParam: 'fromCreatedDate',
    oldXrayUrlParamValues: ['fromCreatedDate'],
    typeOf: 'string',
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true,
    preventFilterClearing: true,
    onInterface: true
  },
  [FILTERS_DICT.TO_CREATED_DATE]: {
    value: defaultToCreatedDate(),
    xhrQueryParam: 'toCreatedDate',
    mixpanelParam: 'toCreatedDate',
    oldXrayUrlParamValues: ['toCreatedDate'],
    typeOf: 'string',
    hiddenFromXrayUrl: true,
    mandatoryInitURLParam: true,
    preventFilterClearing: true,
    onInterface: true
  },
  [FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN]: {
    value: defaultDateGreaterOrEqualThan(),
    feedsFilter: FILTERS_DICT.FROM_CREATED_DATE,
    xhrQueryParam: 'date_greater_or_equal_than',
    mixpanelParam: 'date_greater_or_equal_than',
    oldXrayUrlParamValues: ['date_greater_or_equal_than'],
    typeOf: 'string',
    mandatoryInitURLParam: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.DATE_LESS_THAN]: {
    value: defaultDateLessThan(),
    feedsFilter: FILTERS_DICT.TO_CREATED_DATE,
    xhrQueryParam: 'date_less_than',
    mixpanelParam: 'date_less_than',
    oldXrayUrlParamValues: ['date_less_than'],
    typeOf: 'string',
    mandatoryInitURLParam: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.STATUS]: {
    value: undefined,
    xhrQueryParam: 'status',
    mixpanelParam: 'status',
    oldXrayUrlParamValues: ['status'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.USECASE]: {
    value: undefined,
    xhrQueryParam: 'usecase',
    mixpanelParam: 'usecase',
    oldXrayUrlParamValues: ['usecase'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    XHRParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.USERNAME]: {
    value: undefined,
    xhrQueryParam: 'username',
    mixpanelParam: 'username',
    oldXrayUrlParamValues: ['username'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.SOURCE_LANGUAGE]: {
    value: undefined,
    xhrQueryParam: 'source_language',
    mixpanelParam: 'source_language',
    oldXrayUrlParamValues: ['source_language'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.TARGET_LANGUAGE]: {
    value: undefined,
    xhrQueryParam: 'target_language',
    mixpanelParam: 'target_language',
    oldXrayUrlParamValues: ['target_language'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.TEXT_FORMATS]: {
    value: undefined,
    typeOf: 'string',
    xhrQueryParam: 'text_formats',
    mixpanelParam: 'text_formats',
    oldXrayUrlParamValues: ['text_formats'],
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.ORIGINS]: {
    value: undefined,
    xhrQueryParam: 'origins',
    mixpanelParam: 'origins',
    oldXrayUrlParamValues: ['origins'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with item values separated by a comma: ex: val1,val2,val3
      const dataArray = value.split(',')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.IS_PIVOTED]: {
    value: undefined,
    xhrQueryParam: 'is_pivoted',
    mixpanelParam: 'is_pivoted',
    oldXrayUrlParamValues: ['is_pivoted'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.ARCHITECTURES]: {
    value: undefined,
    xhrQueryParam: 'architectures',
    mixpanelParam: 'architectures',
    oldXrayUrlParamValues: ['architectures'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true
  },
  [FILTERS_DICT.CANONICAL_NAME]: {
    value: undefined,
    // this filter transform canonical names into usernames
    xhrQueryParam: 'customer_ids',
    mixpanelParam: 'canonical_name',
    oldXrayUrlParamValues: ['canonical_name'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with label  + separator _***_ + items value separated by a comma: ex: label1_***_val1,val2,val3
      // this is for UI elements for which the options are only available via XHR request
      const dataArray = value.split(',,')
      dataArray.forEach(item => {
        const paramsString = item.split('_***_')[1]
        if (paramsString.length) {
          const paramsArray = paramsString.split(',')

          if (paramsArray.length) {
            paramsArray.forEach(paramVal => {
              requestParamsArray.push(`${key}=${paramVal}`)
            })
          }
        }
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.TRANSLATION_PROFILE_IDS]: {
    value: undefined,
    xhrQueryParam: 'translation_profile_ids',
    mixpanelParam: 'translation_profile_ids',
    oldXrayUrlParamValues: ['translation_profile_ids'],
    typeOf: 'string',
    onInterface: true,
    toBeSentOnXHRRequest: true,
    xhrParamParsing: (key, value, requestParamsArray) => {
      // expect string with label  + separator _***_ + item value, all separated by a comma: ex: label1_***_val1,label2_***_val2
      // this is for UI elements for which the options are only available via XHR request
      const dataArray = value.split(',,')
      dataArray.forEach(item => {
        requestParamsArray.push(`${key}=${item.split('_***_')[1]}`)
      })
      return requestParamsArray
    }
  },
  [FILTERS_DICT.NP_DATE_LESS_THAN]: {
    value: undefined,
    xhrQueryParam: 'np_date_less_than',
    mixpanelParam: 'np_date_less_than',
    oldXrayUrlParamValues: ['np_date_less_than'],
    typeOf: 'string',
    hiddenFromXrayUrl: true
  },
  [FILTERS_DICT.NP_DATE_GREATER_OR_EQUAL_THAN]: {
    value: undefined,
    xhrQueryParam: 'np_date_greater_or_equal_than',
    mixpanelParam: 'np_date_greater_or_equal_than',
    oldXrayUrlParamValues: ['np_date_greater_or_equal_than'],
    typeOf: 'string',
    hiddenFromXrayUrl: true
  }
}

// ***** Filters dependency handlers according to UI actions ****
// check Search.vue view
export const SEARCH_ACTIVE_FILTER_FIELDS = (searchFilters) => {
  const activeFields = {}
  Object.keys(searchFilters).forEach(key => {
    if (SEARCH_FILTERS_CONFIG[`${key}`] && SEARCH_FILTERS_CONFIG[`${key}`].onInterface) {
      switch (SEARCH_FILTERS_CONFIG[`${key}`].typeOf) {
        case 'string':
          activeFields[`${key}`] = (searchFilters[`${key}`] && searchFilters[`${key}`].length > 0) || false
          break
        case 'boolean':
          activeFields[`${key}`] = searchFilters[`${key}`]
          break
        default:
          activeFields[`${key}`] = searchFilters[`${key}`] !== undefined
          break
      }
    } else if (!SEARCH_FILTERS_CONFIG[`${key}`]) {
      console.error(`Unrecognized filter key ::: ${key} when setting the active filted fields. Please check if filter is correctly configured in filters.js`)
    }
  })

  return activeFields
}

// check Search.vue view
export const SEARCH_BUTTON_UI_DISABLED_STATUS = (searchFilters, filterFieldsActive) => {
  let isDisabled = true
  Object.keys(searchFilters).forEach(key => {
    if (filterFieldsActive[`${key}`]) {
      isDisabled = false
    }
  })

  return isDisabled
}

// check Search.vue view
export const PAGINATION_HANDLE_REFRESH_PAGE_FILTER_INTERDEPENDENCIES = (searchFilters) => {
  searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = defaultDateLessThan()
  searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = defaultDateGreaterOrEqualThan()
  searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`] = searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`]
  searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`] = searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`]

  return searchFilters
}

// check Search.vue view
export const UPDATE_FILTERS_ACTION_INTERDEPENDENCIES = (searchFilters) => {
  let minDate, maxDate

  if (searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`] && searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]) {
    const greater = formatDate(new Date(searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`]), "yyyy-MM-dd'T'HH:mm:ss'Z'", true)
    const less = formatDate(new Date(searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]), "yyyy-MM-dd'T'HH:mm:ss'Z'", true)
    minDate = searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`]
    maxDate = searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]

    searchFilters[`${FILTERS_DICT.OLDEST_FIRST}`] = false
    searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = greater
    searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = less
  } else if (searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`] && !searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]) {
    minDate = searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`]

    searchFilters[`${FILTERS_DICT.OLDEST_FIRST}`] = true
    searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = formatDate(new Date(searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`]), "yyyy-MM-dd'T'HH:mm:ss'Z'", true)
    searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = undefined
  } else if (!searchFilters[`${FILTERS_DICT.FROM_CREATED_DATE}`] && searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]) {
    maxDate = searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]

    searchFilters[`${FILTERS_DICT.OLDEST_FIRST}`] = false
    searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = undefined
    searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = formatDate(new Date(searchFilters[`${FILTERS_DICT.TO_CREATED_DATE}`]), "yyyy-MM-dd'T'HH:mm:ss'Z'", true)
  } else {
    searchFilters[`${FILTERS_DICT.OLDEST_FIRST}`] = false
    searchFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = undefined
    searchFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = undefined
  }

  return {
    searchFilters,
    minDate,
    maxDate
  }
}

// check Search.vue view
export const SEARCH_FILTERS_UI_DISABLING = (updateKey, searchFilters) => {
  let groupedInputTextDisabled, datesDisabled, otherFieldsDisabled

  // force cleanup of other groupedIdFilters if one of them has a value (useful for the enabling the correct flags and also clearing the interface)
  if (GROUPED_ID_FIELDS.indexOf(updateKey) > -1) {
    GROUPED_ID_FIELDS.forEach(groupKey => {
      if (groupKey !== updateKey) {
        searchFilters[`${groupKey}`] = undefined
      }
    })
  }

  // set correct flags for disabled states
  if (searchFilters[`${FILTERS_DICT.UID}`] ||
  searchFilters[`${FILTERS_DICT.EXTERNAL_ID}`] ||
  searchFilters[`${FILTERS_DICT.TRANSLATION_RECORD_ID}`] ||
  searchFilters[`${FILTERS_DICT.TASK_ID}`]) {
    groupedInputTextDisabled = false
    datesDisabled = true
    otherFieldsDisabled = true

    //  Clear unnecessary filters just in case (when filter is solely UID)
    Object.keys(searchFilters).forEach(key => {
      if (
        SEARCH_FILTERS_CONFIG[`${key}`] &&
        SEARCH_FILTERS_CONFIG[`${key}`].onInterface &&
        !SEARCH_FILTERS_CONFIG[`${key}`].preventFilterClearing &&
        key !== FILTERS_DICT.UID &&
        key !== FILTERS_DICT.EXTERNAL_ID &&
        key !== FILTERS_DICT.TRANSLATION_RECORD_ID &&
        key !== FILTERS_DICT.TASK_ID) {
        if (SEARCH_FILTERS_CONFIG[`${key}`].value === null) {
          searchFilters[`${key}`] = null
        } else {
          searchFilters[`${key}`] = undefined
        }
      } else if (!SEARCH_FILTERS_CONFIG[`${key}`]) {
        console.error(`Unrecognized filter key ::: ${key} when clearing filters NOT UID OR THREAD ID. Please check if filter is correctly configured in filters.js`)
      }
    })
  } else if (searchFilters[`${FILTERS_DICT.THREAD_ID}`] || searchFilters[`${FILTERS_DICT.THREAD_EXTERNAL_ID}`]) {
    groupedInputTextDisabled = false
    datesDisabled = true
    otherFieldsDisabled = false
  } else {
    groupedInputTextDisabled = false
    datesDisabled = false
    otherFieldsDisabled = false
  }

  return {
    searchFilters,
    groupedInputTextDisabled,
    datesDisabled,
    otherFieldsDisabled
  }
}

// check Search.vue view
export const CLEAR_SEARCH_FILTER_INTERDEPENDENCIES = (searchFilters) => {
  Object.keys(searchFilters).forEach(key => {
    if (key === FILTERS_DICT.FROM_CREATED_DATE ||
      key === FILTERS_DICT.TO_CREATED_DATE ||
      key === FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN ||
      key === FILTERS_DICT.DATE_LESS_THAN) {
      switch (key) {
        case FILTERS_DICT.FROM_CREATED_DATE:
          searchFilters[`${key}`] = defaultFromCreatedDate()
          break
        case FILTERS_DICT.TO_CREATED_DATE:
          searchFilters[`${key}`] = defaultToCreatedDate()
          break
        case FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN:
          searchFilters[`${key}`] = defaultDateGreaterOrEqualThan()
          break
        case FILTERS_DICT.DATE_LESS_THAN:
          searchFilters[`${key}`] = defaultDateLessThan()
          break
      }
    } else if (SEARCH_FILTERS_CONFIG[`${key}`] &&
    SEARCH_FILTERS_CONFIG[`${key}`].typeOf === 'boolean' &&
    !SEARCH_FILTERS_CONFIG[`${key}`].preventFilterClearing) {
      if (SEARCH_FILTERS_CONFIG[`${key}`].value === undefined) {
        searchFilters[`${key}`] = undefined
      } else {
        searchFilters[`${key}`] = false
      }
    } else if (SEARCH_FILTERS_CONFIG[`${key}`] && !SEARCH_FILTERS_CONFIG[`${key}`].preventFilterClearing) {
      if (SEARCH_FILTERS_CONFIG[`${key}`].value === null) {
        searchFilters[`${key}`] = null
      } else {
        searchFilters[`${key}`] = undefined
      }
    } else if (!SEARCH_FILTERS_CONFIG[`${key}`]) {
      console.error(`Unrecognized filter key ::: ${key} when clearing search filter interdependencies. Please check if filter is correctly configured in filters.js`)
    }
  })

  return searchFilters
}

// Other useful exports of data
export const MANDATORY_URL_PARAM_FILTERS = Object.keys(SEARCH_FILTERS_CONFIG).reduce((filtered, key) => {
  if (SEARCH_FILTERS_CONFIG[`${key}`].mandatoryInitURLParam) {
    filtered.push({
      key,
      ...SEARCH_FILTERS_CONFIG[`${key}`]
    })
  }
  return filtered
}, [])

export const GROUPED_ID_FIELDS = Object.keys(SEARCH_FILTERS_CONFIG).reduce((filtered, key) => {
  if (SEARCH_FILTERS_CONFIG[`${key}`].groupedIDField) {
    filtered.push(key)
  }
  return filtered
}, [])
