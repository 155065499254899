<template>
  <Card
      class="c-TaskTranslationProfileSection"
      title="Pipeline Group">
      <div
        slot="header-actions"
        class="c-TaskTranslationProfileSection__cardActions">
        <a
          v-if="messageDetails.customer && messageDetails.customer.canonical_name && messageDetails.customer.canonical_name.length"
          :href="customerPortalTranslationProfileLink(messageDetails)"
          target="_blank"
          rel="noopener noreferrer">
          <Button
            label="Portal"
            button-type="is-outlined"
            icon="external-hyperlink"
            data-test-id="msg-task-portal-link"
            @click="trackAction" />
        </a>
      </div>
      <div
        slot="body"
        class="c-TaskTranslationProfileSection__cardBody">
        <CardBodyLayout>
          <tr colspan="3">
            <td>
              <p>Client</p>
              <p data-test-id="msg-task-client">{{ client }}</p>
            </td>
            <td>
              <p>Pipeline Group</p>
              <p data-test-id="msg-task-tp-name">{{ translationProfile }}</p>
            </td>
            <td>
              <p>Username</p>
              <p data-test-id="msg-task-username">{{ username }}</p>
            </td>
          </tr>
          <tr colspan="2">
            <td>
              <p>Register</p>
              <p data-test-id="msg-task-register">{{ register }}</p>
            </td>
            <td>
              <p>HT Flow</p>
              <p data-test-id="msg-task-ht-flow">{{ htFlow }}</p>
            </td>
          </tr>
        </CardBodyLayout>
      </div>
    </Card>
</template>

<script>
import {
  generateCPLinks,
  trackEvent
} from '@/utils/generalUtils'
import { MIXPANEL_EVENTS } from '@/data/enum'
import { Card, Button } from '@unbabel/ui'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'TaskTranslationProfileSection',
  components: {
    Card,
    Button,
    CardBodyLayout
  },
  props: {
    messageDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
    client () {
      return this.messageDetails?.customer?.canonical_name || '---'
    },
    translationProfile () {
      return this.messageDetails?.translation_profile?.name || '---'
    },
    username () {
      return this.messageDetails?.customer?.username || '---'
    },
    htFlow () {
      return this.messageDetails?.human_translation_details?.human_workflow_id || '---'
    },
    register () {
      return this.messageDetails?.translation_profile?.register || '---'
    }
  },
  methods: {
    trackAction () {
      trackEvent(MIXPANEL_EVENTS.CP_LINK, {
        area: this.$route.name,
        type: 'translationProfile'
      })
    },
    customerPortalTranslationProfileLink (item) {
      return generateCPLinks(item, 'translation_profile')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-TaskTranslationProfileSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }
}
</style>
