import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import { GLOBAL_ACTIONS, MIXPANEL_EVENTS } from '@/data/enum'
import Keycloak from 'keycloak-js'
import axios from 'axios'
import Toasted from 'vue-toasted'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueGtag from 'vue-gtag'
import mixpanel from 'mixpanel-browser'
let reqToken

// setting up env vars
const sentryEnabled = process.env.VUE_APP_SENTRY_ENABLED === 'True'
const sentryDebug = process.env.VUE_APP_SENTRY_DEBUG === 'True'
const sentryDsn = process.env.VUE_APP_SENTRY_DSN
const gaEnabled = process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === 'True'
const gaTrackingId = process.env.VUE_APP_GOOGLE_ANALYTICS_ID
const mixpanelEnabled = process.env.VUE_APP_MIXPANEL_ENABLED === 'True'
const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN

Vue.config.productionTip = false
Vue.use(Toasted)

// setting up axios interceptors
axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${reqToken}`
  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  store.dispatch(GLOBAL_ACTIONS.SET_LOADING, false)
  if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403)) {
    setTimeout(() => {
      keycloak.logout()
    }, 4000)
    return Promise.reject(new Error('Access Unauthorized. User will be logged out.'))
  } else {
    return Promise.reject(error)
  }
})

// setting up keycloak and app init
const keycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required',
  promiseType: 'native',
  checkLoginIframe: true
}

const keycloak = new Keycloak(keycloakInitOptions)

keycloak.onTokenExpired = () => {
  keycloak.updateToken(30).then(() => {
    reqToken = keycloak.token
  })
}

keycloak.init({ onLoad: keycloakInitOptions.onLoad }).then(async (auth) => {
  if (!auth) {
    window.location.reload()
  }
  const tokenInfo = keycloak.tokenParsed

  // logout user if he doesn't belong to the /rdall group
  if (!tokenInfo.groups || !tokenInfo.groups.find(item => item === '/rdall')) {
    return keycloak.logout()
  }

  const user = {
    email: tokenInfo.email || 'john.doe@unbabel.com',
    name: tokenInfo.name || 'John Doe',
    family_name: tokenInfo.family_name || 'Doe',
    given_name: tokenInfo.given_name || 'John',
    groups: tokenInfo.groups
  }

  store.dispatch(GLOBAL_ACTIONS.SET_USER_INFO, user)

  reqToken = keycloak.token
  Vue.prototype.$keycloak = keycloak

  // Init Google Tag Manager
  Vue.use(VueGtag, {
    config: { id: gaTrackingId },
    appName: 'Xray',
    enabled: gaEnabled,
    pageTrackerScreenviewEnabled: true
  }, router)

  // Init Mixpanel
  if (mixpanelEnabled) {
    mixpanel.init(mixpanelToken)

    // some specific configs for localhost
    mixpanel.set_config({
      cross_site_cookie: process.env.VUE_APP_ENVIRONMENT === 'localhost',
      debug: process.env.VUE_APP_ENVIRONMENT === 'localhost'
    })

    // identify user
    mixpanel.identify(user.email)

    // registering superproperties
    mixpanel.register({
      user_email: user.email,
      user_groups: user.groups
    })

    // set some user properties
    mixpanel.people.set({
      $name: user.name,
      $email: user.email
    })

    // track app initialization
    mixpanel.track(MIXPANEL_EVENTS.APP_INIT, {})
  }

  // Init VueJS
  new Vue({
    router,
    store,
    keycloak,
    render: h => h(App)
  }).$mount('#app')

  // Init Sentry
  Sentry.init({
    sentryDsn,
    sentryDebug,
    sentryEnabled,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  })
}).catch((err) => {
  console.error('Authentication Failed', err)
})
