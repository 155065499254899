import Vue from 'vue'
import { GLOBAL_MUTATIONS } from '@/data/enum'

const mutations = {
  [GLOBAL_MUTATIONS.UPDATE_USER] (state, payload) {
    Vue.set(state, 'user', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_LOADING] (state, payload) {
    Vue.set(state, 'loading', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_HT_TASKS_LOADING] (state, payload) {
    Vue.set(state, 'htTasksLoading', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_NUGGETS_LOADING] (state, payload) {
    Vue.set(state, 'nuggetsLoading', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_SEARCH_FILTERS] (state, payload) {
    Vue.set(state, 'searchFilters', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_REFRESH_TIME] (state, payload) {
    Vue.set(state, 'refreshTime', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_LIST] (state, payload) {
    const list = payload.map(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'isIdCopied') {
          Object.defineProperty(item, key, { configurable: false, writable: false })
        }
      })
      return item
    })
    Vue.set(state, 'list', list)
  },
  [GLOBAL_MUTATIONS.APPEND_LIST] (state, payload) {
    const currList = state.list
    const payloadList = payload.map(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'isIdCopied') {
          Object.defineProperty(item, key, { configurable: false, writable: false })
        }
      })
      return item
    })
    currList.push(...payloadList)
    Vue.set(state, 'list', currList)
  },
  [GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS] (state, payload) {
    Vue.set(state, 'messageDetails', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_TASK_DETAILS] (state, payload) {
    Vue.set(state, 'taskDetails', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_TRANSLATION_RECORD_DETAILS] (state, payload) {
    Vue.set(state, 'translationRecordDetails', payload)
  },
  [GLOBAL_MUTATIONS.UPDATE_LIST_ITEM_INDEX] (state, payload) {
    Vue.set(state, 'listItemSelectedIndex', payload)
  }
}

export default mutations
