<template>
  <div
    class="c-ConfirmationModal"
    :class="{ visible: visibility }"
    @click.self.stop="closeModal">
    <div class="c-ConfirmationModal__content">
      <p class="c-ConfirmationModal__contentTitle">{{ data.title }}</p>
      <p class="c-ConfirmationModal__contentText">
        <span v-for="(item, i) in data.text" :key="i" v-html="item" />
      </p>
      <div class="c-ConfirmationModal__contentActions">
        <Button
          v-if="data.actionLeft"
          class="button"
          :label="data.actionLeft.label"
          :button-type="data.actionLeft.buttonType"
          :button-style="data.actionLeft.buttonStyle"
          @click="data.actionLeft.handler">
        </Button>
        <Button
          v-if="data.actionRight"
          class="button"
          :label="data.actionRight.label"
          :button-type="data.actionRight.buttonType"
          :button-style="data.actionRight.buttonStyle"
          @click="data.actionRight.handler">
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@unbabel/ui'

export default {
  name: 'ConfirmationModal',
  components: {
    Button
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (data) => {
        const conditions = []
        conditions.push(Object.keys(data).indexOf('title'))
        conditions.push(Object.keys(data).indexOf('text'))
        conditions.push(Object.keys(data).indexOf('actionLeft'))

        if (Object.keys(data).indexOf('actionLeft') > -1) {
          conditions.push(Object.keys(data.actionLeft).indexOf('handler'))
          conditions.push(Object.keys(data.actionLeft).indexOf('label'))
          conditions.push(Object.keys(data.actionLeft).indexOf('buttonType'))
          conditions.push(Object.keys(data.actionLeft).indexOf('buttonStyle'))
        }

        conditions.push(Object.keys(data).indexOf('actionRight'))

        if (Object.keys(data).indexOf('actionRight') > -1) {
          conditions.push(Object.keys(data.actionRight).indexOf('handler'))
          conditions.push(Object.keys(data.actionRight).indexOf('label'))
          conditions.push(Object.keys(data.actionRight).indexOf('buttonType'))
          conditions.push(Object.keys(data.actionRight).indexOf('buttonStyle'))
        }

        return Object.keys(data).length === 0 || conditions.every(cond => cond > -1)
      }
    },
    visibility: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('toggleModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-ConfirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all $default-time $default-easing;
  z-index: 1000000;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    background-color: $un-white;
    border-radius: $border-radius;
    padding: $base-padding calc(#{$base-padding} * 1.5);
    box-shadow: 0px 10px 10px 0px rgba(174, 185, 203, 0.26), 0px 14px 28px 0px rgba(174, 185, 203, 0.25);
    min-width: 400px;
    max-width: 600px;

    &Title {
      color: $un-n900;
      font-family: $secondary-font;
      font-size: 21px;
      font-weight: 600;
      line-height: 1.52;
      margin-bottom: $base-margin;
    }

    &Text {
      color: $un-n900;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin-bottom: calc(#{$base-margin} * 2);

      span {
        display: block;
      }
    }

    &Actions {
      display: flex;
      justify-content: flex-end;

      .button:first-child {
        margin-right: calc(#{$base-margin} / 2);
      }
    }
  }
}
</style>
