<template>
  <div class="c-SearchTable">
    <table class="c-SearchTable__table">
      <thead>
        <tr>
          <th></th>
          <th class="c-SearchTable__translationTypeHeader">Translation</th>
          <th>Client</th>
          <th>Language Pair</th>
          <th class="c-SearchTable__statusHeader">Status</th>
          <th class="c-SearchTable__datesHeader">Dates</th>
          <th class="c-SearchTable__timeSpentHeader">Time spent</th>
          <th>Original text</th>
          <th>Translation</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          v-show="data.length"
          :key="index"
          :data-index="index"
          class="c-SearchTable__tableRow"
          :class="tableRowClassObj(index, item)"
          :data-test-id="`list-row${index}`"
          @click="openSidePanel($event, item.thread_id || 'THREAD_ID', item.uid, index)">
          <td class="c-SearchTable__translationDirection">
            <div v-if="item.content_type !== CONTENT_TYPE.FAQ && item.translation_direction !== 'unknown'">
              <i
                v-if="item.translation_direction === 'inbound'"
                :id="`inbound${index}`"
                class="c-SearchTable__translationDirection--inbound">
                <Tooltip
                  message="Inbound"
                  :position="'bottom'"
                  :anchorName="`inbound${index}`" />
              </i>
              <i
                v-if="item.translation_direction === 'outbound'"
                :id="`outbound${index}`"
                class="c-SearchTable__translationDirection--outbound">
                <Tooltip
                  message="Outbound"
                  :position="'bottom'"
                  :anchorName="`outbound${index}`" />
              </i>
            </div>
          </td>
          <td class="c-SearchTable__translationType">
            <p :data-test-id="`list-content-type${index}`">{{ item.content_type || '---' }}</p>
            <p>
              <router-link
                event=""
                :to="{
                  name: 'MessageDetails',
                  params: {
                    conversationId: item.thread_id || 'THREAD_ID',
                    messageId: item.uid
                  }
                }">
                <span
                  :id="`translation${index}`"
                  :data-test-id="`list-id${index}`">{{ truncateId(item.uid) }}</span>
                <Tooltip
                  class="c-SearchTable__translationTypeTooltip msgIdClassIdentifier"
                  :message="'Cmd + click to open in a new tab'"
                  :position="'bottom'"
                  :anchorName="`translation${index}`"></Tooltip>
              </router-link>
              <span class="c-SearchTable__translationTypeCopyIconWrapper">
                <i
                  :id="`copy${index}`"
                  class="c-SearchTable__translationTypeCopyIcon"
                  @click="copyText(item.uid, index)">
                  <Tooltip
                    class="c-SearchTable__translationTypeTooltip copyIdClassIdentifier"
                    :message="item.isIdCopied ? 'Copied!' : item.uid"
                    :position="'bottom'"
                    :anchorName="`copy${index}`"></Tooltip>
                </i>
              </span>
            </p>
            <p :data-test-id="`list-origin${index}`">{{ item.origin || '---' }}</p>
          </td>
          <td class="c-SearchTable__client">
            <p>
              <a
                v-if="item.customer && item.customer.canonical_name && item.customer.canonical_name.length"
                :id="`canonical${index}`"
                :href="customerPortalCustomerLink(item)"
                target="_blank"
                rel="noopener noreferrer"
                :data-test-id="`list-canonical-name${index}`">
                {{ item.customer && item.customer.canonical_name || '---' }}
                <Tooltip
                  class="c-SearchTable__clientTooltip customerLinkClassIdentifier"
                  :message="'Go to Customer'"
                  :position="'bottom'"
                  :anchorName="`canonical${index}`"></Tooltip>
              </a>
              <span v-else>{{ '---' }}</span>
            </p>
            <p>
              <a
                :id="`tp${index}`"
                :href="customerPortalTranslationProfileLink(item)"
                target="_blank"
                rel="noopener noreferrer"
                :data-test-id="`list-tp-name${index}`">
                {{ item.translation_profile && item.translation_profile.name || '---' }}
                <Tooltip
                  class="c-SearchTable__clientTooltip tpLinkClassIdentifier"
                  :message="'Go to Pipeline Group'"
                  :position="'bottom'"
                  :anchorName="`tp${index}`"></Tooltip>
              </a>
            </p>
            <p :data-test-id="`list-username${index}`">{{ item.customer && item.customer.username || '---' }}</p>
          </td>
          <td class="c-SearchTable__lp">
            <LanguagePair
              :source="item.source_language"
              :target="item.target_language">
            </LanguagePair>
          </td>
          <td class="c-SearchTable__status">
            <p>
              <StatusChip
                :status="item.status"
                :data-test-id="`list-status${index}`"></StatusChip>
            </p>
            <p>
              <StatusChip
                v-if="item.did_upgrade_ht"
                class="c-SearchTable__statusChipArch"
                status="UPGRADE HT"
                :status-config="{
                  labelType: 'is-subtle',
                  labelStyle: 'is-inverted'
                }"
                :data-test-id="`list-upgrade-ht${index}`"></StatusChip>
              <StatusChip
                v-if="item.sent_to_markup_aligner"
                class="c-SearchTable__statusChipArch"
                status="MARKUP ALIGNER"
                :status-config="{
                  labelType: 'is-subtle',
                  labelStyle: 'is-inverted'
                }"
                :data-test-id="`list-markup-aligner${index}`"></StatusChip>
              <StatusChip
                v-if="item.architecture !== ARCH_STATUS.MAESTRO"
                class="c-SearchTable__statusChipArch"
                :status="item.architecture"
                :data-test-id="`list-arch${index}`"></StatusChip>
              <StatusChip
                v-if="item.skip_reason"
                class="c-SearchTable__statusChipArch"
                status="HT SKIP"
                :status-config="{
                  labelType: 'is-subtle',
                  labelStyle: 'is-inverted'
                }"
                :tooltip-message="item.skip_reason"
                :data-test-id="`list-skip${index}`"></StatusChip>
            </p>
            <p
              v-if="item.architecture === ARCH_STATUS.CORE_FALLBACK || item.architecture === ARCH_STATUS.CORE"
              class="c-SearchTable__status--linkToCore">
              <a
                :href="coreLink(item)"
                target="_blank"
                class="linkToCoreClassIdentifier"
                rel="noopener noreferrer">
                <span>view in core</span>
                <i></i>
              </a>
            </p>
          </td>
          <td class="c-SearchTable__dates">
            <div
              :id="`dates${index}`"
              @mouseover="hoverDatesTrackEvent">
              <p class="text--caption"> Creation Date </p>
              <p class="text--caption--demi">{{ item.parsed_creation_date || '---' }}</p>
              <div v-if="item.parsed_completion_date" class="mt-1">
                <p class="text--caption"> Completion Date </p>
                <p class="text--caption--demi">{{ item.parsed_completion_date }}</p>
              </div>
              <div v-if="item.parsed_deadline_date" class="mt-1">
                <p class="text--caption"> Deadline Date </p>
                <p class="text--caption--demi">{{ item.parsed_deadline_date }} </p>
              </div>
              <Tooltip
                class="c-SearchTable__datesTooltip"
                :message="generateDatesTooltip(item)"
                :position="'bottom'"
                :anchorName="`dates${index}`"></Tooltip>
            </div>
          </td>
          <td class="c-SearchTable__timeSpent">
            <p :id="`timespent${index}`">
              <span>{{ item.time_spent || '---' }}</span>
              <Tooltip
                v-if="item.full_time_spent"
                class="c-SearchTable__timeSpentTooltip"
                :message="item.full_time_spent"
                :position="'bottom'"
                :anchorName="`timespent${index}`"></Tooltip>
            </p>
          </td>
          <td
            class="c-SearchTable__originalText"
            :data-test-id="`list-original-text${index}`">{{ item.original_text || '---' }}</td>
          <td
            class="c-SearchTable__translation"
            :data-test-id="`list-translated-text${index}`">
            {{ item.translation || '---' }}
          </td>
        </tr>
        <tr v-show="!isLoading && !data.length">
          <td
            colspan="9"
            class="c-SearchTable__empty">
            No results were found for the specified search parameters. You should try clearing or changing the search parameters.
          </td>
        </tr>
        <tr
          v-for="(item, index) in skeletonLoaderItems"
          v-show="isLoading"
          :key="`skeletonLoader${index}`"
          class="c-SearchTable__table--isLoading">
          <td>
            <span></span>
          </td>
          <td>
            <span></span>
            <span></span>
          </td>
          <td>
            <span></span>
            <span></span>
          </td>
          <td>
            <span></span>
          </td>
          <td>
            <span></span>
          </td>
          <td>
            <span></span>
          </td>
          <td>
            <span></span>
          </td>
          <td>
            <span></span>
            <span></span>
            <span></span>
          </td>
          <td>
            <span></span>
            <span></span>
            <span></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusChip from '@/components/StatusChip'
import LanguagePair from '@/components/LanguagePair'
import {
  generateCPLinks,
  generateLinkToCore,
  truncateString,
  copyToClipboard,
  trackEvent
} from '@/utils/generalUtils'
import { Tooltip } from '@unbabel/ui'
import {
  GLOBAL_ACTIONS,
  GLOBAL_GETTERS,
  ARCH_STATUS,
  MIXPANEL_EVENTS,
  CONTENT_TYPE
} from '@/data/enum'

export default {
  name: 'Table',
  components: {
    StatusChip,
    LanguagePair,
    Tooltip
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      skeletonLoaderItems: new Array(5),
      sidePanelOpen: false,
      ARCH_STATUS,
      keysPressed: {
        Meta: false
      },
      CONTENT_TYPE
    }
  },
  computed: {
    ...mapGetters({
      isLoading: GLOBAL_GETTERS.LOADING,
      listItemSelectedIndex: GLOBAL_GETTERS.LIST_ITEM_INDEX
    })
  },
  methods: {
    hoverDatesTrackEvent () {
      trackEvent(MIXPANEL_EVENTS.HOVER_LIST_DETAILS, {
        type: 'created&completed'
      })
    },
    truncateId (id) {
      return truncateString(id, 6, true)
    },
    copyText (id, index) {
      if (!this.data[index].isIdCopied) {
        this.data[index].isIdCopied = true
        copyToClipboard(id)

        setTimeout(() => {
          this.data[index].isIdCopied = false
        }, 1000)
      }
    },
    generateDatesTooltip (item) {
      let out = `Created at: ${item.utc_creation_date}`

      if (item.completion_date && item.completion_date !== '0001-01-01T00:00:00Z') {
        out += `<br />Completed at: ${item.utc_completion_date}`
      }

      if (item.deadline_date) {
        out += `<br />Deadline: ${item.utc_parsed_deadline_date}`
      }

      return out
    },
    openSidePanel (ev, conversationId, messageId, index) {
      const parentClassName = (ev.target && ev.target.parentNode && ev.target.parentNode.className) || undefined

      if (!parentClassName ||
        (((parentClassName && parentClassName.indexOf('msgIdClassIdentifier') === -1) || !this.keysPressed.Meta) &&
          (parentClassName && parentClassName.indexOf('copyIdClassIdentifier') === -1) &&
          (parentClassName && parentClassName.indexOf('customerLinkClassIdentifier') === -1) &&
          (parentClassName && parentClassName.indexOf('tpLinkClassIdentifier') === -1) &&
          (parentClassName && parentClassName.indexOf('linkToCoreClassIdentifier') === -1) &&
          (parentClassName && parentClassName.indexOf('linkClassIdentifier') === -1))) {
        if (parentClassName && parentClassName.indexOf('msgIdClassIdentifier') > -1) {
          trackEvent(MIXPANEL_EVENTS.LINK_SIDEPANEL, {
            type: 'link'
          })
        } else {
          trackEvent(MIXPANEL_EVENTS.LINK_SIDEPANEL, {
            type: 'row'
          })
        }

        // update store with index of the selected row + update url (directly on action)
        this.$store.dispatch(GLOBAL_ACTIONS.SELECT_LIST_ITEM, {
          ctx: this,
          index,
          pThrId: conversationId,
          pMsgId: messageId
        })
      } else if (parentClassName && parentClassName.indexOf('msgIdClassIdentifier') > -1 && this.keysPressed.Meta) {
        trackEvent(MIXPANEL_EVENTS.KEYBOARD_SHORTCUTS, {
          area: this.$route.name,
          type: 'cmd + click'
        })
      } else if (parentClassName && parentClassName.indexOf('customerLinkClassIdentifier') > -1) {
        trackEvent(MIXPANEL_EVENTS.CP_LINK, {
          area: this.$route.name,
          type: 'customer'
        })
      } else if (parentClassName && parentClassName.indexOf('tpLinkClassIdentifier') > -1) {
        trackEvent(MIXPANEL_EVENTS.CP_LINK, {
          area: this.$route.name,
          type: 'translationProfile'
        })
      }
    },
    handleKeyDown (ev) {
      if (ev.key === 'Meta') {
        this.keysPressed[`${ev.key}`] = true
      }
    },
    handleKeyUp (ev) {
      if (ev.key === 'Meta') {
        this.keysPressed[`${ev.key}`] = false
      }
    },
    tableRowClassObj (index, item) {
      return {
        isSidepanelActive: index === this.listItemSelectedIndex,
        isArch1: this.isArch1(item)
      }
    },
    coreLink (item) {
      return generateLinkToCore(item.uid, item.translation_profile?.is_human_flow)
    },
    customerPortalCustomerLink (item) {
      return generateCPLinks(item, 'customer')
    },
    customerPortalTranslationProfileLink (item) {
      return generateCPLinks(item, 'translation_profile')
    },
    isArch1 (item) {
      return item.architecture === ARCH_STATUS.CORE || item.architecture === ARCH_STATUS.CORE_FALLBACK
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown, true)
    document.addEventListener('keyup', this.handleKeyUp, true)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown, true)
    document.removeEventListener('keyup', this.handleKeyUp, true)
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-SearchTable {
  width: 100%;
  border-top: 1px solid $un-n200;
  border-left: 1px solid $un-n200;
  border-right: 1px solid $un-n200;
  border-radius: $border-radius;
  display: flex;
  position: relative;
  overflow-x: auto;
  will-change: auto;

  &__table {
    min-width: 100%;
    background-color: $un-white;
    border-spacing: 0;
    table-layout: fixed;

    th,
    td {
      padding: $base-padding;
      text-align: left;
      vertical-align: top;
    }

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      vertical-align: middle;
    }

    tbody tr {
      cursor: pointer;
    }

    tbody tr:last-child {
      box-shadow: none;
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;

      &.c-SearchTable__tableRow:hover {
        .c-SearchTable__translationType {

          p:nth-child(1) {
            display: none;
          }

          p:nth-child(2) {
            visibility: visible;
            position: relative;
          }
        }
      }

      &.isSidepanelActive {
        background-color: $un-blue-light !important;
      }
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.21px;
      text-align: left;
    }

    &:not(.c-SearchTable__tableMenu) tr.c-SearchTable__tableRow:hover {
      background-color: $un-n50;
    }

    &--isLoading {
      td {
        height: 96px
      }
      td span {
        height: 16px;
        background: #E9ECF2;
        border-radius: 61px;
        display: block;
        width: 100%;
      }
      td span:first-child {
        margin-bottom: calc(#{$base-margin} / 2);
      }

      td:nth-child(1) span {
        max-width: 30px;
      }

      td:nth-child(2) span:first-child {
        max-width: 61px;
      }
      td:nth-child(2) span:last-child {
        max-width: 124px;
      }
      td:nth-child(3) span:first-child {
        max-width: 61px;
      }
      td:nth-child(3) span:last-child {
        max-width: 157px;
      }
      td:nth-child(4) span {
        max-width: 171px;
        height: 41px;
      }
      td:nth-child(5) span:first-child {
        max-width: 166px;
      }
      td:nth-child(6) span:first-child {
        max-width: 102px;
      }
      td:nth-child(7) span:first-child {
        max-width: 65px;
      }
      td:nth-child(8) span, td:nth-child(9) span {
        max-width: 379px;

        &:nth-child(2) {
          margin-bottom: calc(#{$base-margin} / 2);
        }
      }
    }
  }

  &__empty {
    font-size: 14px !important;
    padding-top: calc(#{$base-padding} * 4) !important;
    padding-bottom: calc(#{$base-padding} * 4) !important;
    text-align: center !important;
  }

  &__translationDirection {
    padding-right: 0 !important;

    i {
      display: block;
      width: 18px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 calc(#{$base-padding} / 2);
    }

    &--inbound {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9179 7.49972L9.87148 0.520171C9.1248 -0.193926 7.94125 -0.169798 7.22429 0.574137C6.48903 1.29848 6.4673 2.47758 7.17536 3.22852L9.96071 5.9009C10.0135 5.95176 10.0301 6.02956 10.0028 6.09756C9.9754 6.16556 9.90952 6.21016 9.83623 6.2103H1.473C1.03992 6.25477 0.64227 6.46955 0.367629 6.80735C0.0929876 7.14516 -0.0361209 7.57827 0.00873699 8.01131C0.00873699 8.59774 0.331091 9.81736 1.44782 9.81736H9.85134C9.92419 9.81723 9.98993 9.86106 10.0178 9.92836C10.0457 9.99567 10.0303 10.0732 9.9787 10.1246L7.29409 12.8107C6.61725 13.568 6.64994 14.7223 7.36857 15.4401C8.08721 16.158 9.24145 16.1895 9.99812 15.5118L16.9237 8.51787C17.0581 8.38214 17.1329 8.19858 17.1319 8.0076C17.1308 7.81663 17.0538 7.63392 16.9179 7.49972Z' fill='%23DEE3ED'/%3E%3C/svg%3E");
    }
    &--outbound {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.214764 7.49972L7.26046 0.520171C8.00706 -0.193926 9.19047 -0.169798 9.90736 0.574137C10.6425 1.29848 10.6643 2.47758 9.95628 3.22852L7.17124 5.9009C7.11847 5.95176 7.10184 6.02956 7.1292 6.09756C7.15655 6.16556 7.22242 6.21016 7.29571 6.2103H15.658C16.0911 6.25477 16.4887 6.46955 16.7633 6.80735C17.0379 7.14516 17.167 7.57827 17.1221 8.01131C17.1221 8.59774 16.7998 9.81736 15.6832 9.81736H7.2806C7.20775 9.81723 7.14202 9.86106 7.11412 9.92836C7.08623 9.99567 7.10168 10.0732 7.15326 10.1246L9.83757 12.8107C10.5143 13.568 10.4816 14.7223 9.76309 15.4401C9.04454 16.158 7.89042 16.1895 7.13383 15.5118L0.209007 8.51787C0.0746689 8.38214 -0.000207901 8.19858 0.000871658 8.0076C0.00195122 7.81663 0.0788994 7.63392 0.214764 7.49972Z' fill='%23343941'/%3E%3C/svg%3E%0A");
    }
  }

  &__translationTypeHeader, &__translationType {
    min-width: 135px;

    p:nth-child(1) {
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: calc(#{$base-margin / 2});
      color: $un-n900;
    }

    p:nth-child(2) {
      visibility: hidden;
      display: flex;
      align-items: center;
      font-weight: 600;
      position: absolute;
      margin-bottom: calc(#{$base-margin / 2});

      a {
        color: $un-n900;
      }

      &:hover a {
        color: $un-blue;
        text-decoration: underline;
      }
    }
  }

  &__translationTypeCopyIconWrapper {
    position: absolute;
    top: -4px;
    right: 0;
  }

  &__translationTypeCopyIcon {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    }
  }

  &__client {
    min-width: 160px;

    p {
      color: $un-n900;

      &:first-child {
        font-weight: 600;
        margin-bottom: calc(#{$base-margin / 2});
      }

      &:nth-child(2) {
        margin-bottom: calc(#{$base-margin / 2});
      }

      &:nth-child(3) {
        line-height: 21px;
        color: $un-n500;
      }

      a {
        color: $un-n900;
      }

      a:hover {
        color: $un-blue;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  &__lp {
    margin-left: calc(#{$base-margin} / -2);
  }

  &__status, &__statusHeader {
    white-space: nowrap;

    > p {
      margin-bottom: calc(#{$base-margin} / 2);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--linkToCore {
      margin: $base-margin 0 0 8px;

      a {
        font-family: $primary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        white-space: nowrap;
        display: flex;
        align-items: center;
        color: $un-n900;
        outline: none;

        i {
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.914 4.8a.713.713 0 010 1.426H4.817c-.767 0-1.391.623-1.391 1.39v11.568c0 .767.624 1.391 1.391 1.391h11.568c.767 0 1.39-.624 1.39-1.391V13.06a.713.713 0 011.425 0v6.124A2.818 2.818 0 0116.385 22H4.817a2.82 2.82 0 01-2.816-2.816V7.616a2.819 2.819 0 012.816-2.815h6.097zM21.286 2a.712.712 0 01.658.44c.036.087.055.18.055.272v7.124a.713.713 0 01-1.425 0V4.432l-9.47 9.47a.708.708 0 01-.503.207.712.712 0 01-.504-1.216l9.47-9.468h-5.404a.713.713 0 010-1.425z' fill='%23343941' fill-rule='evenodd'/%3E%3C/svg%3E");
          margin-left: calc(#{$base-margin} / 2);
        }

        &:hover {
          color: $un-blue;
          text-decoration: underline;

          i {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.914 4.8a.713.713 0 010 1.426H4.817c-.767 0-1.391.623-1.391 1.39v11.568c0 .767.624 1.391 1.391 1.391h11.568c.767 0 1.39-.624 1.39-1.391V13.06a.713.713 0 011.425 0v6.124A2.818 2.818 0 0116.385 22H4.817a2.82 2.82 0 01-2.816-2.816V7.616a2.819 2.819 0 012.816-2.815h6.097zM21.286 2a.712.712 0 01.658.44c.036.087.055.18.055.272v7.124a.713.713 0 01-1.425 0V4.432l-9.47 9.47a.708.708 0 01-.503.207.712.712 0 01-.504-1.216l9.47-9.468h-5.404a.713.713 0 010-1.425z' fill='%233843D0' fill-rule='evenodd'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &__dates {
    white-space: nowrap;

  }

  &__timeSpent {
    white-space: nowrap;
    text-align: right !important;
  }

  &__originalText, &__translation {
    min-width: 400px;
  }

  &__translation {
    padding-right: calc(#{$base-padding} * 2) !important;
  }
}
</style>
