const customerApiCache = {}

export function retrieveFromCustomerApiCache (cacheType, customerId, dataType) {
  let result = null
  let localData = null
  let sessionData = null
  switch (cacheType) {
    case 'variable':
      if (customerApiCache[`${customerId}-${dataType}`]) {
        result = customerApiCache[`${customerId}-${dataType}`]
      }
      break
    case 'localStorage':
      localData = localStorage.getItem(`${customerId}-${dataType}`)
      if (localData && JSON.parse(localData)) {
        result = JSON.parse(localData)
      }
      break
    case 'sessionStorage':
      sessionData = sessionStorage.getItem(`${customerId}-${dataType}`)
      if (sessionData && JSON.parse(sessionData)) {
        result = JSON.parse(sessionData)
      }
      break
    default:
      console.error(`Unknown cache type while retrieving from cache ::: ${cacheType}`)
      break
  }
  return result
}

export function storeToCustomerApiCache (cacheType, customerId, dataType, data) {
  const result = {
    type: cacheType,
    success: false
  }
  switch (cacheType) {
    case 'variable':
      customerApiCache[`${customerId}-${dataType}`] = data
      result.success = true
      break
    case 'localStorage':
      try {
        localStorage.setItem(`${customerId}-${dataType}`, JSON.stringify(data))
        result.success = true
      } catch (error) {
        if (error && (error.code === 22 || error.code === 1014)) {
          console.error('localStorage is full (new data) ::: ', error)
        }
      }
      break
    case 'sessionStorage':
      try {
        sessionStorage.setItem(`${customerId}-${dataType}`, JSON.stringify(data))
        result.success = true
      } catch (error) {
        if (error && (error.code === 22 || error.code === 1014)) {
          console.error('sessionStorage is full (new data) ::: ', error)
        }
      }
      break
    default:
      console.error(`Unknown cache type while saving to cache ::: ${cacheType}`)
      break
  }
  return result
}
