import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from) {
    if (to.name === 'Changelog' && to.hash) {
      return {
        selector: to.hash,
        // offset to make sure version number is not hidden by header
        offset: { x: 0, y: 88 }
      }
    } else if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
  }
})

export default router
