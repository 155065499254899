<template>
  <div class="l-TranslationRecordDetails">
    <TitleSection
      class="l-TranslationRecordDetails__titleSection"
      :message-details="messageDetails"
      :task-details="taskDetails"
      :tr-details="translationRecordDetails"
      area="translationRecordDetails" />
    <div class="l-TranslationRecordDetails__topBlock">
      <TranslationRecordDetailsSection
        class="l-TranslationRecordDetails__details"
        :tr-details="translationRecordDetails" />
      <EditorSection
        class="l-TranslationRecordDetails__queue"
        :tr-details="translationRecordDetails" />
    </div>
    <TarkinNuggetsSection
      v-if="translationRecordDetails && translationRecordDetails.source_nuggets && translationRecordDetails.source_nuggets.length"
      class="l-TranslationRecordDetails__translationText"
      :source-nuggets="translationRecordDetails.source_nuggets"
      :target-nuggets="translationRecordDetails.target_nuggets"
      :language="taskDetails.target_language"
      :task-type="taskDetails.type"
      :display-target="targetNuggetsVisibility"
      area="translationRecordDetails" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GLOBAL_GETTERS, GLOBAL_ACTIONS } from '@/data/enum'
import { statusOptions } from '@/data/status'
import TitleSection from '@/components/TitleSection'
import TranslationRecordDetailsSection from '@/components/TranslationRecordDetailsSection'
import EditorSection from '@/components/EditorSection'
import TarkinNuggetsSection from '@/components/TarkinNuggetsSection'
import { showNotification, parseRequestError } from '@/utils/generalUtils'

export default {
  name: 'Details',
  components: {
    TitleSection,
    TranslationRecordDetailsSection,
    EditorSection,
    TarkinNuggetsSection
  },
  computed: {
    ...mapGetters({
      messageDetails: GLOBAL_GETTERS.MESSAGE_DETAILS,
      taskDetails: GLOBAL_GETTERS.TASK_DETAILS,
      translationRecordDetails: GLOBAL_GETTERS.TRANSLATION_RECORD_DETAILS
    }),
    targetNuggetsVisibility () {
      let reason
      const visibility = this.translationRecordDetails?.action === 'submit'

      if (!visibility && (this.translationRecordDetails?.action === 'get' || this.translationRecordDetails?.action === 'accept')) {
        reason = 'This Translation Record is still pending editor action...'
      } else if (!visibility) {
        reason = `This Translation Record has been ${statusOptions[`${this.translationRecordDetails?.action}`].label} by the editor`
      }

      return {
        visibility,
        reason
      }
    }
  },
  beforeMount () {
    const payload = {
      uid: this.$route.params.messageId,
      threadId: this.$route.params.threadId,
      taskId: this.$route.params.taskId,
      translationRecordId: this.$route.params.translationRecordId
    }

    this.$store.dispatch(GLOBAL_ACTIONS.INIT_TRANSLATION_RECORD_VIEW, payload).catch(err => {
      const msg = parseRequestError(err)
      showNotification(this, 'is-alert', `Error initializing Translation Record Details :: ${msg}`)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.l-TranslationRecordDetails {
  &__titleSection {
    margin-bottom: calc(#{$base-margin} * 1.5);
  }
  &__topBlock {
    display: flex;
    margin-bottom: $base-margin;
  }
  &__details {
    width: calc(66.6% - calc(#{$base-margin} / 2));
    margin-right: $base-margin;
  }
  &__queue {
    width: calc(33.3% - calc(#{$base-margin} / 2));
  }
}
</style>
