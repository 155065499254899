<template>
  <div class="c-NuggetsLayout">
    <div class="c-NuggetsLayout__controls">
      <div class="c-NuggetsLayout__options">
        <span
          :class="{ active: layout === 'alignment' }"
          @click="toggleView('alignment')">alignment</span>
        <span
          :class="{ active: layout === 'code' }"
          @click="toggleView('code')">code</span>
      </div>
      <div v-if="hasRevision" class="c-NuggetsLayout__actions">
        Show markups tags
        <SwitchToggle :data="inMarkupView" @change="inMarkupView = $event" />
      </div>
    </div>
    <div class="c-NuggetsLayout__contentContainer">
      <div
        v-show="layout === 'code'"
        ref="NuggetsLayoutCodeContainer"
        class="c-NuggetsLayout__codeContainer">
      </div>
      <div
        v-show="layout === 'alignment'"
        class="c-NuggetsLayout__alignmentContainer">
        <div class="c-NuggetsLayout__tableContainer">
          <table class="c-NuggetsLayout__table" :class="{ 'hide-markup': !inMarkupView }">
            <thead>
              <tr>
                <th>No.</th>
                <th>
                  <p>Source</p>
                  <p>{{ data.source_language || '---' }}</p>
                </th>
                <th v-if="data.mt && data.mt[0] && data.mt[0].length">
                  <p>Machine Translation</p>
                  <p>{{ data.mt_languages[0] }}</p>
                </th>
                <th v-if="data.mt && data.mt[1] && data.mt[1].length">
                  <p>Machine Translation</p>
                  <p>{{ data.mt_languages[1] }}</p>
                </th>
                <th v-for="(item, index) in data.ht" :key="index">
                  <p>{{'HT (' + data.ht[index][0].taskType + ')'}}</p>
                  <p>{{ data.ht_language }}</p>
                </th>
              </tr>
            </thead>
            <tbody v-if="directingArray.length">
              <tr
                v-for="(item, index) in directingArray"
                :key="index">
                <td>{{ index }}</td>
                <!-- SOURCE NUGGETS -->
                <td v-if="data.source && data.source.length">
                  <div class="nugget_isText" v-html="data.source[index].parsedText" />
                </td>
                <!-- MT NUGGETS -->
                <td v-if="data.mt && data.mt[0]">
                  <a
                    v-if="isNuggetATm(index, data.mt[0])"
                    :class="generateTmClass(data.mt[0][index])"
                    :href="generateTmServerLink(data.mt[0][index])"
                    rel="noopener noreferrer"
                    target="_blank"
                    :title="generateTmTitle(data.mt[0][index], 'mt')"
                    v-html="data.mt[0][index].parsedText">
                  </a>
                  <div v-else class="nugget_isText" v-html="data.mt[0][index].parsedText" />

                  <div class="nugget_mtFooter">
                    <span
                      v-if="data.mt[0][index] && data.mt[0][index].qe_score >= 0"
                      class="qeScore">
                        QE: {{ parseQEScore(data.mt[0][index].qe_score) }}
                    </span>
                    <StatusChip
                      v-if="isFallbackEngine(data.mt[0][index])"
                      :status="`Engine: ${data.mt[0][index].mt_engine}`"
                      :status-config="{
                        labelType: 'is-subtle',
                        labelStyle: 'is-attention'
                      }"
                      :data-test-id="`fallback-engine-${index}`"
                      tooltipMessage="Fallback engine"
                    />
                  </div>
                </td>
                <td v-if="data.mt && data.mt[1]">
                  <a
                    v-if="isNuggetATm(index, data.mt[1])"
                    :class="generateTmClass(data.mt[1][index])"
                    :href="generateTmServerLink(data.mt[1][index])"
                    rel="noopener noreferrer"
                    target="_blank"
                    :title="generateTmTitle(data.mt[1][index], 'mt')"
                    v-html="data.mt[1][index].parsedText">
                  </a>
                  <div v-else class="nugget_isText" v-html="data.mt[1][index].parsedText" />

                  <div class="nugget_mtFooter">
                    <span
                      v-if="data.mt[1][index] && data.mt[1][index].qe_score >= 0"
                      class="qeScore">
                        QE: {{ parseQEScore(data.mt[1][index].qe_score) }}
                    </span>
                    <StatusChip
                      v-if="isFallbackEngine(data.mt[1][index])"
                      :status="`Engine: ${data.mt[1][index].mt_engine}`"
                      :status-config="{
                        labelType: 'is-subtle',
                        labelStyle: 'is-attention'
                      }"
                      :data-test-id="`fallback-engine-${index}`"
                      tooltipMessage="Fallback engine"
                    />
                  </div>
                </td>
                <!-- HT NUGGETS -->
                <td
                    v-for="(item, idx) in data.ht" :key="idx"
                    :class="{
                    inProgress: data.ht[idx][index].inProgress,
                    hasFailed: data.ht[idx][index].hasFailed
                  }">
                  <a
                      v-if="isNuggetATm(index, data.ht[idx])"
                      :class="generateTmClass(data.ht[idx][index])"
                      :href="generateTmServerLink(data.ht[idx][index])"
                      rel="noopener noreferrer"
                      target="_blank"
                      :title="generateTmTitle(data.ht[idx][index], 'ht')"
                      v-html="data.ht[idx][index].parsedText">
                  </a>
                  <div v-else class="nugget_isText" v-html="data.ht[idx][index].parsedText" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  trackEvent,
  checkIfUsedFallbackEngine,
  checkIfNuggetIsTm,
  generateLinkToTmServer,
  generateTitleToTm,
  getMatchType,
  isNuggetBlocked
} from '@/utils/generalUtils'
import StatusChip from '@/components/StatusChip'

import { MIXPANEL_EVENTS } from '@/data/enum'
import JSONFormatter from 'json-formatter-js'
import { SwitchToggle } from '@unbabel/ui'

export default {
  name: 'NuggetsLayout',
  components: {
    StatusChip,
    SwitchToggle
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    engines: {
      type: Array,
      required: true,
      default: () => []
    },
    messageUid: {
      type: String,
      required: true,
      default: () => ''
    }
  },
  data () {
    return {
      layout: 'alignment',
      jsonFormatter: undefined,
      defaultLevel: 2,
      inMarkupView: true
    }
  },
  computed: {
    // this is selecting the nuggets Array that should direct the whole layout
    directingArray () {
      if (this.data.source && this.data.source.length) {
        return this.data.source
      }
      return []
    },
    hasRevision () {
      return this.data.ht?.some(step => step[0]?.taskType === 'Revision')
    }
  },
  methods: {
    toggleView (type) {
      this.layout = type
      trackEvent(MIXPANEL_EVENTS.NUGGETS_LAYOUT_TOGGLE, {
        type
      })
    },
    isFallbackEngine (nugget) {
      if (!nugget.mt_engine) return false
      return checkIfUsedFallbackEngine(nugget, this.engines)
    },
    isNuggetATm (i, nuggets) {
      return checkIfNuggetIsTm(i, nuggets)
    },
    generateTmClass (nugget) {
      const matchType = getMatchType(nugget, this.data)
      let out = 'nugget_isTm'

      if (matchType) {
        out += ` ${matchType}`
      }

      if (isNuggetBlocked(nugget)) {
        out += ' blocked'
      }

      return out
    },
    generateTmServerLink (nugget) {
      return generateLinkToTmServer(nugget)
    },
    parseQEScore (qeScore) {
      return Math.round(qeScore * 100) / 100
    },
    generateTmTitle (nugget, nuggetType) {
      return generateTitleToTm(nugget, nuggetType)
    }
  },
  watch: {
    layout: {
      handler: function (newVal) {
        if (newVal) {
          Vue.nextTick().then(() => {
            // if there is nuggets info already displayed, clean it for the CODE tab
            if (this.$refs.NuggetsLayoutCodeContainer.firstChild) {
              this.$refs.NuggetsLayoutCodeContainer.removeChild(this.$refs.NuggetsLayoutCodeContainer.firstChild)
            }

            this.jsonFormatter = new JSONFormatter(this.data, this.defaultLevel, {})
            this.$refs.NuggetsLayoutCodeContainer.appendChild(this.jsonFormatter.render())
          })
        }
      }
    },
    immediate: true
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Label__text {
  max-width: 100%;
  text-transform: none;
}

.c-NuggetsLayout {
  background-color: $un-white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.withScroll .c-NuggetsLayout__fixed .c-NuggetsLayout__table {
    z-index: 2;
    box-shadow: 3px 0 6px 0 rgba(174, 185, 203, 0.23), 3px 0 6px 0 rgba(174, 185, 203, 0.23);
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  &__actions {
    font-size: 14px;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 1rem;
  }

  &__options {
    display: flex;

    span {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: $un-n900;
      cursor: pointer;

      &:first-child {
        margin-right: calc(#{$base-margin} * 2);
      }

      &:hover {
        text-decoration: underline;
      }

      &.active {
        font-weight: 600;
        color: $un-blue;
        text-decoration: underline;
      }
    }
  }

  &__toggleContainer {
    display: flex;
    align-items: center;

    p {
      font-family: $primary-font;
      font-size: 14px;
      color: $un-n900;
      font-weight: 600;
      margin-right: calc(#{$base-margin} * 0.5);
      white-space: nowrap;
    }
  }

  &__contentContainer {
    height: calc(100% - 50px);
  }

  &__codeContainer {
    background-color: $un-n50;
    padding: calc(#{$base-padding} * 1.5);
    height: 100%;
    overflow: auto;
  }

  &__alignmentContainer {
    display: flex;
    position: relative;
    flex: 1;
    height: 100%;
  }
  &__tableContainer {
    min-height: 100%;
    overflow: auto;
    min-width: 100%;
  }

  &__table {
    background-color: $un-white;
    border-spacing: 0;
    width: 100%;

    .nugget_isText {
      ::v-deep span {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
      }

      ::v-deep .markup_tag {
        margin: 0 1px;
        background-color: #F2EBF5;
        border: 1px solid #A36FBB;
        color: $un-n900 !important;
        font-weight: 600 !important;
        border-radius: 6px;
        padding: 2px 8px;

        &.self_closing {
          box-shadow: inset 0 -2px 0 0 #A36FBB;
        }

        &.outstanding {
          display: inline-block;
          vertical-align: middle;
          background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.4238 12.8329C13.4238 13.159 13.1596 13.4233 12.8338 13.4233H10.3377C10.1317 12.506 9.73782 11.6472 9.1869 10.8987C9.92868 10.6238 10.7145 10.4834 11.5073 10.4851H11.5081C12.1576 10.4847 12.8032 10.5809 13.4238 10.7701V12.8329ZM13.4238 10.1695V3.16704C13.4238 2.84098 13.1596 2.57666 12.8338 2.57666H3.17502C2.8492 2.57666 2.58507 2.84098 2.58507 3.16704V8.22099C2.92196 8.17368 3.26192 8.15027 3.60241 8.15101C5.20124 8.11569 6.76073 8.64955 8.003 9.65753C8.29316 9.89297 8.56161 10.1507 8.80644 10.4275C9.66491 10.0829 10.5821 9.90648 11.5082 9.90844C12.1562 9.90811 12.8006 9.9961 13.4238 10.1695ZM10.1088 13.9999C10.1047 14 10.1006 14 10.0965 13.9999H3.17502C2.53096 13.9999 2.00884 13.4774 2.00884 12.8329V8.63305C2.00746 8.62759 2.00624 8.62207 2.00517 8.61648C1.99699 8.5736 1.99875 8.53113 2.00884 8.49144V3.16704C2.00884 2.5225 2.53096 2 3.17502 2H12.8338C13.4779 2 14 2.5225 14 3.16704V12.8329C14 13.4774 13.4779 13.9999 12.8338 13.9999H10.1088ZM3.17502 13.4233H9.74571C9.4242 12.1206 8.68652 10.9545 7.64009 10.1054C6.50299 9.18281 5.07532 8.69448 3.61179 8.7276L3.60451 8.72767C3.26312 8.72676 2.9223 8.75223 2.58507 8.8038V12.8329C2.58507 13.159 2.8492 13.4233 3.17502 13.4233ZM8.7228 6.02287C8.7228 5.33283 9.28177 4.77344 9.9713 4.77344C10.6608 4.77344 11.2198 5.33283 11.2198 6.02287C11.2198 6.7129 10.6608 7.27229 9.9713 7.27229C9.28177 7.27229 8.7228 6.7129 8.7228 6.02287ZM9.9713 4.19679C8.96353 4.19679 8.14656 5.01435 8.14656 6.02287C8.14656 7.03138 8.96353 7.84895 9.9713 7.84895C10.9791 7.84895 11.796 7.03138 11.796 6.02287C11.796 5.01435 10.9791 4.19679 9.9713 4.19679Z" fill="black"/></svg>');
          background-position: center;
          background-repeat: no-repeat;
          min-height: 1.5rem;
          min-width: 1.5rem;
        }
      }
    }

    &.hide-markup {
      .nugget_isText {
        ::v-deep .markup_tag {
          display: none;
        }
      }
    }

    th,
    td {
      padding: $base-padding;
      text-align: left;
      vertical-align: middle;

      &:first-child {
        padding-left: calc(#{$base-padding} * 2);
        width: 30px;
      }

      &:last-child {
        padding-right: calc(#{$base-padding} * 2);
      }
    }

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      white-space: nowrap;

      p:first-child {
        margin-bottom: calc(#{$base-margin} / 2);
        font-weight: 600;
      }
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.21px;
      text-align: left;
      position: relative;
      vertical-align: top;

      .nugget_mtFooter{
        display: inline-flex;
        width: 100%;
        align-items: center;
        margin-top: .7rem;
        gap: .5rem;

        .qeScore {
          font-family: $secondary-font;
          font-size: 10px;
          letter-spacing: 1.5px;
          line-height: 15px;
          color: $un-n500;
        }
      }

      &.inProgress, &.hasFailed {
        color: $un-n500;
        font-style: italic;
      }
    }
  }
}
</style>
