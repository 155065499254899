<template>
  <Card
    class="c-TarkinNuggetsSection"
    title="Translation Text">
    <div
      slot="body"
      class="c-TarkinNuggetsSection__cardBody">
      <!-- SOURCE -->
      <div class="c-TarkinNuggetsSection__translation">
        <div class="c-TarkinNuggetsSection__languageHeader">
          <div class="c-TarkinNuggetsSection__language">
            <img
              :src="`/flags-light/${parsedLanguage.key}.svg`"
              :data-test-id="`${area}-source-lang-flag`">
            <p>
              <span :data-test-id="`${area}-source-lang-label`">{{ parsedLanguage.label || parsedLanguage.key }}</span>
              <span
                v-if="author"
                :data-test-id="`${area}-source-author`">{{ author }}</span>
            </p>
          </div>
          <span
            class="c-TarkinNuggetsSection__numWords"
            :data-test-id="`${area}-source-num-words`">{{ sourceNumWords || '0' }} words</span>
        </div>
        <div
          v-for="(nugget, index) in sourceNuggets"
          :key="index"
          class="c-TarkinNuggetsSection__text"
          :data-test-id="`${area}-source-text${index}`">
          <a
            v-if="isNuggetATm(index, sourceNuggets)"
            :class="generateTmClass(nugget)"
            :href="generateTmServerLink(nugget)"
            rel="noopener noreferrer"
            target="_blank"
            :title="generateTmTitle(nugget, 'ht')"
            v-html="nugget.parsedText ? nugget.parsedText : nugget.text ? nugget.text : '---'" />
          <p
            v-else
            v-html="nugget.parsedText ? nugget.parsedText : nugget.text ? nugget.text : '---'" />
        </div>
      </div>
      <!-- TARGET -->
      <div class="c-TarkinNuggetsSection__translation">
        <div class="c-TarkinNuggetsSection__languageHeader">
          <div class="c-TarkinNuggetsSection__language">
            <img
              :src="`/flags-light/${parsedLanguage.key}.svg`"
              :data-test-id="`${area}-target-lang-flag`">
            <p>
              <span :data-test-id="`${area}-target-lang-label`">{{ parsedLanguage.label || parsedLanguage.key }}</span>
              <span
                v-if="recipient"
                :data-test-id="`${area}-target-recipient`">{{ recipient }}</span>
            </p>
          </div>
          <span
            class="c-TarkinNuggetsSection__numWords"
            :data-test-id="`${area}-target-num-words`">{{ targetNumWords || '0' }} words</span>
        </div>
        <p
          v-if="!displayTarget.visibility"
          class="special">{{ displayTarget.reason }}</p>
        <div v-else-if="targetNuggets.length && displayTarget.visibility">
          <div
            v-for="(nugget, index) in targetNuggets"
            :key="index"
            class="c-TarkinNuggetsSection__text"
            :data-test-id="`${area}-target-text${index}`">
            <a
            v-if="isNuggetATm(index, targetNuggets)"
            :class="generateTmClass(nugget)"
            :href="generateTmServerLink(nugget)"
            rel="noopener noreferrer"
            target="_blank"
            :title="generateTmTitle(nugget, 'ht')"
            v-html="nugget.parsedText ? nugget.parsedText : nugget.text ? nugget.text : '---'" />
            <p
              v-else
              v-html="nugget.parsedText ? nugget.parsedText : nugget.text ? nugget.text : '---'" />
            </div>
        </div>
        <p
          v-else-if="displayTarget.visibility"
          class="special"
          :data-test-id="`${area}-target-special-copy`">Translation in progress…</p>
      </div>
    </div>
    <div
      slot="footer"
      class="c-TarkinNuggetsSection__cardFooter">
      <NuggetsLegend />
    </div>
  </Card>
</template>

<script>
import {
  checkIfNuggetIsTm,
  generateLinkToTmServer,
  generateTitleToTm,
  getMatchType,
  isNuggetBlocked
} from '@/utils/generalUtils'
import { languageOptions } from '@/data/languages'
import { TASK_TYPE } from '@/data/enum'
import NuggetsLegend from '@/components/NuggetsLegend'
import { Card } from '@unbabel/ui'

export default {
  name: 'TarkinNuggetsSection',
  components: {
    Card,
    NuggetsLegend
  },
  props: {
    sourceNuggets: {
      type: Array,
      required: true
    },
    targetNuggets: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    taskType: {
      type: String,
      required: true
    },
    displayTarget: {
      type: Object,
      required: true
    },
    // used only for the e2e tests
    area: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      nuggetsVisibility: false
    }
  },
  computed: {
    parsedLanguage () {
      return {
        key: this.language,
        label: (languageOptions[`${this.language}`] && languageOptions[`${this.language}`].label) || this.language
      }
    },
    sourceNumWords () {
      return this.sourceNuggets.reduce((acc, item) => {
        return acc + item.num_words
      }, 0)
    },
    targetNumWords () {
      return this.targetNuggets.reduce((acc, item) => {
        return acc + item.num_words
      }, 0)
    },
    author () {
      return this.parseNuggetsSource('author')
    },
    recipient () {
      return this.parseNuggetsSource('recipient')
    }
  },
  methods: {
    isNuggetATm (i, nuggets) {
      return checkIfNuggetIsTm(i, nuggets)
    },
    generateTmClass (nugget) {
      const matchType = getMatchType(nugget, this.data)
      let out = 'nugget_isTm'

      if (matchType) {
        out += ` ${matchType}`
      }

      if (isNuggetBlocked(nugget)) {
        out += ' blocked'
      }

      return out
    },
    generateTmServerLink (nugget) {
      return generateLinkToTmServer(nugget)
    },
    generateTmTitle (nugget, nuggetType) {
      return generateTitleToTm(nugget, nuggetType)
    },
    parseNuggetsSource (userType) {
      if (userType === 'author') {
        if (this.taskType === TASK_TYPE.REVIEW) {
          return 'Paid Editor'
        } else if (this.sourceNuggets[0].source === 'MACHINE_TRANSLATION') {
          return 'Machine Translation'
        } else if (this.sourceNuggets[0].source === 'HUMAN_TRANSLATION') {
          return 'Human Translation'
        }

        return 'Author'
      } else if (userType === 'recipient') {
        if (this.taskType === TASK_TYPE.REVIEW) {
          return 'Senior Editor'
        }

        return 'Paid Editor'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-TarkinNuggetsSection {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__translation {
    width: 50%;
    padding: calc(#{$base-padding} / 2) 0 $base-padding 0;
    position: relative;

    &:first-child {
      padding-right: calc(#{$base-padding} * 1.5);
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -16px;
        right: 0;
        width: 1px;
        height: calc(100% + 16px + 16px);
        background-color: $un-n200;
      }

      button {
        right: calc(#{$base-padding} * 1.5);
      }
    }

    &:last-child {
      padding-left: calc(#{$base-padding} * 1.5);
    }
  }

  &__languageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }

  &__language {
    display: flex;

    img {
      width: 40px;
      height: 40px;
      margin-right: $base-margin;
    }

    p span {
      &:first-child {
        font-family: $secondary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $un-n900;
      }
      &:last-child {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $un-n700;
        display: block;
      }
    }
  }

  &__numWords {
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n700;
  }

  &__text {
    font-family: $primary-font;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: $un-n900;
    margin-bottom: $base-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .special {
    font-family: $primary-font;
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $un-n700;
  }
}
</style>
