<template>
  <div class="c-SidePanel">
    <transition name="fade-in">
      <div
        class="c-SidePanel__backdrop"
        data-test-id="sidepanel-close-backdrop"
        @click="closeSidePanel"
      />
    </transition>
    <transition name="slide">
      <div class="c-SidePanel__panel">
        <div class="c-SidePanel__panelOverflow">
          <div class="c-SidePanel__actions">
            <div>
              <Breadcrumbs
                v-if="messageDetails.thread_id && messageDetails.thread_id.length"
                :items="breadcrumbs"
                @click="breadcrumbClick"></Breadcrumbs>
            </div>
            <div>
              <button
                id="sidepanel-prev-translation"
                type="button"
                :disabled="prevTranslationDisabled"
                data-test-id="sidepanel-prev-translation"
                @click="loadTranslation('prev', true)"
                @mouseenter="handleMouseHover('prev', true)"
                @mouseleave="handleMouseHover('prev', false)">
                <Icon
                  icon="chevron-left"
                  :icon-style="controls.prev ? 'is-un-n900' : 'is-un-n700'" />
                <Tooltip
                  message="Previous from Search"
                  anchorName="sidepanel-prev-translation"></Tooltip>
              </button>
              <button
                id="sidepanel-next-translation"
                type="button"
                :disabled="nextTranslationDisabled"
                data-test-id="sidepanel-next-translation"
                @click="loadTranslation('next', true)"
                @mouseenter="handleMouseHover('next', true)"
                @mouseleave="handleMouseHover('next', false)">
                <Icon
                  icon="chevron-right"
                  :icon-style="controls.next ? 'is-un-n900' : 'is-un-n700'" />
                <Tooltip
                  message="Next from Search"
                  anchorName="sidepanel-next-translation"></Tooltip>
              </button>
              <button
                id="sidepanel-msg-details"
                type="button"
                data-test-id="sidepanel-msg-details"
                @mouseenter="handleMouseHover('details', true)"
                @mouseleave="handleMouseHover('details', false)">
                <a
                  :href="messsageDetailsUrl"
                  rel="noopener noreferrer"
                  target="_blank"
                  @click="handleTopLinkTrackEvent">
                  <Icon
                    icon="external-hyperlink"
                    :icon-style="controls.details ? 'is-un-n900' : 'is-un-n700'" />
                  <Tooltip
                    message="Open message details"
                    anchorName="sidepanel-msg-details"></Tooltip>
                </a>
              </button>
              <button
                id="sidepanel-close-btn"
                type="button"
                data-test-id="sidepanel-close-btn"
                @click="closeSidePanel(true)"
                @mouseenter="handleMouseHover('close', true)"
                @mouseleave="handleMouseHover('close', false)">
                <Icon
                  icon="close"
                  :icon-style="controls.close ? 'is-un-n900' : 'is-un-n700'" />
                <Tooltip
                  message="Close sidepanel"
                  anchorName="sidepanel-close-btn"></Tooltip>
              </button>
            </div>
          </div>
          <MessageDetailsLayout
            class="c-SidePanel__messageDetails"
            :messageDetails="messageDetails"
            @closeSidePanel="closeSidePanel"></MessageDetailsLayout>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GLOBAL_GETTERS, GLOBAL_ACTIONS, GLOBAL_MUTATIONS, MIXPANEL_EVENTS } from '@/data/enum'
import { Icon, Breadcrumbs, Tooltip } from '@unbabel/ui'
import MessageDetailsLayout from '@/components/MessageDetailsLayout'
import {
  showNotification,
  parseRequestError,
  trackEvent,
  parseBreadcrumbRoutes,
  handleBreadrumbsClick
} from '@/utils/generalUtils'

export default {
  name: 'SidePanel',
  components: {
    Icon,
    Breadcrumbs,
    Tooltip,
    MessageDetailsLayout
  },
  data () {
    return {
      keysPressed: {},
      controls: {
        prev: false,
        next: false,
        details: false,
        close: false
      }
    }
  },
  computed: {
    ...mapGetters({
      messageDetails: GLOBAL_GETTERS.MESSAGE_DETAILS,
      searchList: GLOBAL_GETTERS.LIST,
      listItemSelectedIndex: GLOBAL_GETTERS.LIST_ITEM_INDEX,
      isLoading: GLOBAL_GETTERS.LOADING
    }),
    messsageDetailsUrl () {
      return `${process.env.VUE_APP_E2E_APPLICATION_BASE_URL}/conversation/${this.$route.query.pThrId}/message/${this.$route.query.pMsgId}`
    },
    breadcrumbs () {
      return parseBreadcrumbRoutes(this.$route, this.messageDetails)
    },
    prevTranslationDisabled () {
      return this.searchList?.length === 0 || this.listItemSelectedIndex === 0
    },
    nextTranslationDisabled () {
      return this.searchList?.length === 0 ||
      this.listItemSelectedIndex === this.searchList?.length - 1
    }
  },
  methods: {
    breadcrumbClick (item) {
      handleBreadrumbsClick(this.$router, this.$route, item)
    },
    handleTopLinkTrackEvent () {
      trackEvent(MIXPANEL_EVENTS.SIDEPANEL_TOP_MSG_DTLS, {})
    },
    handleMouseHover (area, val) {
      this.controls[`${area}`] = val
    },
    loadTranslation (area, shouldTrackClick) {
      const index = area === 'prev' ? this.listItemSelectedIndex - 1 : this.listItemSelectedIndex + 1
      const messageId = this.searchList[index].uid
      const conversationId = this.searchList[index].thread_id || 'THREAD_ID'

      // update store with index of the selected row
      this.$store.dispatch(GLOBAL_ACTIONS.SELECT_LIST_ITEM, {
        ctx: this,
        index: index,
        pThrId: conversationId,
        pMsgId: messageId
      })

      if (shouldTrackClick) {
        trackEvent(MIXPANEL_EVENTS.SIDEPANEL_ACTIONS_CLICK, {
          type: area === 'prev' ? 'prev-translation' : 'next-translation'
        })
      }
    },
    closeSidePanel (shouldTrackClick) {
      // update store with index as null
      this.$store.dispatch(GLOBAL_ACTIONS.SELECT_LIST_ITEM, {
        ctx: this,
        index: undefined
      })

      this.$store.commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, {})

      if (shouldTrackClick) {
        trackEvent(MIXPANEL_EVENTS.SIDEPANEL_ACTIONS_CLICK, {
          type: 'close-sidepanel'
        })
      }

      this.$emit('closeSidePanel')
    },
    handleKeyDown (ev) {
      this.keysPressed[`${ev.key}`] = true

      if (!this.isLoading && ev.key === 'ArrowLeft' &&
      Object.keys(this.keysPressed).length === 1 &&
      this.listItemSelectedIndex > 0) {
        trackEvent(MIXPANEL_EVENTS.KEYBOARD_SHORTCUTS, {
          area: this.$route.name,
          type: ev.key
        })

        this.loadTranslation('prev', false)
      } else if (!this.isLoading && ev.key === 'ArrowRight' &&
      Object.keys(this.keysPressed).length === 1 &&
      this.listItemSelectedIndex < this.searchList.length - 1) {
        trackEvent(MIXPANEL_EVENTS.KEYBOARD_SHORTCUTS, {
          area: this.$route.name,
          type: ev.key
        })

        this.loadTranslation('next', false)
      } else if (ev.key === 'Escape') {
        trackEvent(MIXPANEL_EVENTS.KEYBOARD_SHORTCUTS, {
          area: this.$route.name,
          type: 'esc'
        })
        this.closeSidePanel(false)
      }
    },
    handleKeyUp (ev) {
      if (this.keysPressed[`${ev.key}`]) {
        delete this.keysPressed[`${ev.key}`]
      }
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        const q = this.$route.query

        if (q &&
          (q.pThrId && q.pThrId.length > 0) &&
          (q.pMsgId && q.pMsgId.length > 0)) {
          const payload = {
            uid: q.pMsgId
          }
          this.$store.dispatch(GLOBAL_ACTIONS.LOAD_MESSAGE_DETAILS_PREVIEW, payload).catch(err => {
            const msg = parseRequestError(err)
            showNotification(this, 'is-alert', `Error loading data for Message Details Preview :: ${msg}`)
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyDown, true)
    document.addEventListener('keyup', this.handleKeyUp, true)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown, true)
    document.removeEventListener('keyup', this.handleKeyUp, true)
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.2s $default-easing;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
  transition: all $default-time $default-easing 0s
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s $default-easing;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all $default-time $default-easing 0s
}

.c-SidePanel {
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 101;
  }

  &__panel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 102;
    width: 100%;
    max-width: 1100px;
  }

  &__panelOverflow {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $un-n50;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 calc(#{$base-padding} * 2);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $base-margin 0 calc(#{$base-margin} * 1.5) 0;

    > div {
      display: flex;

      button {
        outline: none;
        margin: 0 calc(#{$base-margin} * 0.5) 0 0;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $un-blue-light;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
          background-color: none !important;
        }

        &:last-child {
          margin-right: -1px;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;

        &:hover {
          background-color: $un-blue-light;
        }
      }
    }
  }

  &__messageDetails {
    padding-bottom: calc(#{$base-padding} * 2);
  }
}

</style>
