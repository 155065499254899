
import { GLOBAL_GETTERS } from '@/data/enum'

const getters = {
  [GLOBAL_GETTERS.USER] (state) {
    return state.user
  },
  [GLOBAL_GETTERS.LOADING] (state) {
    return state.loading
  },
  [GLOBAL_GETTERS.HT_TASKS_LOADING] (state) {
    return state.htTasksLoading
  },
  [GLOBAL_GETTERS.NUGGETS_LOADING] (state) {
    return state.nuggetsLoading
  },
  [GLOBAL_GETTERS.REFRESH_TIME] (state) {
    return state.refreshTime
  },
  [GLOBAL_GETTERS.STATUS_SELECT_ITEMS] (state) {
    return state.statusSelectItems
  },
  [GLOBAL_GETTERS.TEMPLATE_SELECT_ITEMS] (state) {
    return state.templateSelectItems
  },
  [GLOBAL_GETTERS.TEXT_FORMAT_SELECT_ITEMS] (state) {
    return state.textFormatSelectItems
  },
  [GLOBAL_GETTERS.ORIGIN_SELECT_ITEMS] (state) {
    return state.originSelectItems
  },
  [GLOBAL_GETTERS.ARCH_SELECT_ITEMS] (state) {
    return state.architectureSelectItems
  },
  [GLOBAL_GETTERS.PIVOTED_SELECT_ITEMS] (state) {
    return state.pivotedSelectItems
  },
  [GLOBAL_GETTERS.GROUPED_INPUT_TEXT_SELECT_ITEMS] (state) {
    return state.groupedInputTextSelectItems
  },
  [GLOBAL_GETTERS.CONTENT_TYPE_SELECT_ITEMS] (state) {
    return state.contentTypeSelectItems
  },
  [GLOBAL_GETTERS.USECASE_SELECT_ITEMS] (state) {
    return state.usecaseSelectItems
  },
  [GLOBAL_GETTERS.SOURCE_LANG_OPTIONS] (state) {
    return state.sourceLanguageOptions
  },
  [GLOBAL_GETTERS.TARGET_LANG_OPTIONS] (state) {
    return state.targetLanguageOptions
  },
  [GLOBAL_GETTERS.SEARCH_FILTERS] (state) {
    return state.searchFilters
  },
  [GLOBAL_GETTERS.LIST_ITEM_INDEX] (state) {
    return state.listItemSelectedIndex
  },
  [GLOBAL_GETTERS.LIST] (state) {
    return state.list
  },
  [GLOBAL_GETTERS.MESSAGE_DETAILS] (state) {
    return state.messageDetails
  },
  [GLOBAL_GETTERS.TASK_DETAILS] (state) {
    return state.taskDetails
  },
  [GLOBAL_GETTERS.TRANSLATION_RECORD_DETAILS] (state) {
    return state.translationRecordDetails
  }
}

export default getters
